import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { apiGateway } from "../../utils/config";
import CustomModal from "../Common/CustomModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useZustandStore from "../../zustandStore/useZustandStore";
import LoadingSpinner from "../Common/LoadingSpinner";
import DisplayShowAndTopic from "./DisplayShowAndTopic";

const AssignDesignerModal = ({
  openAssignToDesigner,
  handleCloseModal,
  handleGetUpdatedData,
}) => {
  const [isLoading, setIsLoading] = useState({
    getDesigner: false,
    assignDesigner: false,
  });
  const [designers, setDesigners] = useState({ id: "", name: "" });
  const [designerList, setDesignerList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const setToastMessage = useZustandStore((val) => val?.setToastMessage);

  // API call to get the list designers
  const handleGetDesigners = async () => {
    try {
      setIsLoading({ ...isLoading, getDesigner: true });
      const url = `${apiGateway}/api/v1/team/members/?page=1&roles=designer&page_size=200`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        setDesignerList(response?.data?.team_members);
        setIsLoading({ ...isLoading, getDesigner: false });
      }
    } catch (error) {
      setDesignerList([]);
      setIsLoading({ ...isLoading, getDesigner: false });
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // API call to assign a topic to designer
  const handleAssignToDesigner = async () => {
    try {
      setIsLoading({ ...isLoading, assignDesigner: true });
      const url = `${apiGateway}/api/v1/cms/series-attribute-plan/${openAssignToDesigner?.topicId}/assign-to-designer/`;
      const payload = {
        designer_id: designers?.id,
      };
      const response = await axiosPrivate.post(url, payload);
      if (response?.data) {
        setIsLoading({ ...isLoading, assignDesigner: false });
        handleGetUpdatedData();
        handleCloseModal();
        setToastMessage({
          type: "success",
          message: "Designer Assigned Successfully",
        });
      }
    } catch (error) {
      setIsLoading({ ...isLoading, assignDesigner: false });
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Fetching the designers when modal opens
  useEffect(() => {
    if (openAssignToDesigner?.modal) {
      handleGetDesigners();
    }
  }, [openAssignToDesigner?.modal]);

  return (
    <CustomModal
      show={openAssignToDesigner?.modal}
      title="Assign Designer"
      onHide={handleCloseModal}
      isLoading={isLoading?.assignDesigner}
      handleConfirmationBtnText={"Save"}
      handleConfirmation={() => {
        handleAssignToDesigner();
      }}
    >
      {isLoading?.getDesigner ? (
        <div className="w-fit mx-auto">
          <LoadingSpinner className="!text-baseBlueColor" />
        </div>
      ) : (
        <div className="mt-0">
          <DisplayShowAndTopic
            showName={openAssignToDesigner?.showName}
            title={openAssignToDesigner?.title}
          />

          <Autocomplete
            value={designers}
            options={designerList}
            className="mt-3"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option?.name || ""}
            onChange={(event, newValue) => {
              setDesigners(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search or Select Designer"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#534f8f",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#534f8f",
                    },
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #534f8f",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "15px",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#534f8f", // Changing label color when focused
                  },
                }}
              />
            )}
          />
        </div>
      )}
    </CustomModal>
  );
};

export default AssignDesignerModal;
