import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiGateway } from "../config";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { handleSortingIconClick } from "../utils";

const topicDetailsState = { title: "", date: "", reference: "" };
const topicsMissingFields = { title: false, date: false };

const handleTopicsTableHeadline = ({
  history,
  hasAccessToActions,
  isOnTopicsPage,
  isOnDesignApproval,
  showThumbAndTitleIcon,
  hasAccessToAssignDesignerCol,
  hasAccessToUploadThumbAndTitleCol,
}) => {
  const search = history?.location?.search;
  const searchParams = new URLSearchParams(search);
  const currentSortingKey = searchParams.get("s");
  const currentSortBy = searchParams.get("order");

  const heading = [
    {
      title: "Show",
      display: isOnTopicsPage,
      sortIcon:
        currentSortingKey === "title" && currentSortBy === "asc" ? (
          <FontAwesomeIcon icon={faArrowUp} className="!text-[11px]" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="!text-[11px]" />
        ),
      onClick: () => {
        handleSortingIconClick({
          history,
          key: "title",
          currentSortBy,
          currentSortingKey,
        });
      },
    },
    {
      title: "Topic",
      display: true,
    },

    {
      title: "Created On",
      display: true,
    },
    {
      title: "Publish Date",
      display: true,
      sortIcon:
        currentSortingKey === "published_on" && currentSortBy === "asc" ? (
          <FontAwesomeIcon icon={faArrowUp} className="!text-[11px]" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="!text-[11px]" />
        ),
      onClick: () => {
        handleSortingIconClick({
          history,
          key: "published_on",
          currentSortBy,
          currentSortingKey,
        });
      },
    },
    {
      title: "Reference",
      display: true,
    },
    {
      title: "Assigned To",
      display: hasAccessToAssignDesignerCol,
    },
    {
      title: "Status",
      display: !isOnDesignApproval,
    },
    {
      title: "Thumbnail/Title Icon",
      display: hasAccessToUploadThumbAndTitleCol && showThumbAndTitleIcon,
    },
    {
      title: "Actions",
      display: hasAccessToActions,
    },
  ];
  return heading;
};

const handleTopicsStatusColor = ({ status }) => {
  switch (true) {
    case status === "approved":
      return `!text-green-500 !bg-green-100`;
    case status === "waiting_for_approval":
      return `!text-blue-500 !bg-blue-100`;
    case status === "declined":
      return `!text-red-500 !bg-red-200`;
    case status === "deleted":
      return `!text-red-500 !bg-red-100 !line-through`;
    default:
      return "!text-white !bg-gray-400";
  }
};

const handleTopicsListingTab = ({ isDesigner }) => {
  const topicsListingTab = [
    {
      id: "ALL",
      key: "ALL",
      value: "all",
      index: 0,
      show: true,
    },
    {
      id: "WAITING FOR APPROVAL",
      key: "WAITING FOR APPROVAL",
      value: "waiting_for_approval",
      index: 1,
      show: true,
    },
    {
      id: "DESIGN APPROVAL",
      key: "DESIGN APPROVAL",
      value: "design_approval",
      index: 2,
      show: true,
    },
  ];
  const tabOnlyForDesigner = [
    {
      id: "DESIGN APPROVAL",
      key: "DESIGN APPROVAL",
      value: "design_approval",
      index: 0,
      show: true,
    },
  ];
  return isDesigner ? tabOnlyForDesigner : topicsListingTab;
};

// constant common function to approve or decline a topic
const handleApproveOrDeclineTopic = async ({
  topicId,
  status,
  axiosPrivate,
  setMessage,
}) => {
  try {
    const url = `${apiGateway}/api/v1/cms/series-title/${topicId}/${status}/`;
    const response = await axiosPrivate.post(url);
    const { data } = response;
    if (data) {
      setMessage({
        type: "success",
        error: `Marked as ${status} Successfully`,
      });
      return "success";
    }
  } catch (error) {
    setMessage({
      type: "error",
      error: error?.response?.data?.error_message || error?.message,
    });
  }
};

// constant common function to delete a topic
const handleDeleteTopic = async ({ topicId, axiosPrivate, setMessage }) => {
  try {
    const url = `${apiGateway}/api/v1/cms/series-title/${topicId}/delete/`;
    const response = await axiosPrivate.delete(url);
    const { data } = response;
    if (data) {
      setMessage({ type: "success", error: "Topic Deleted Successfully" });
      return "success";
    }
  } catch (error) {
    setMessage({
      type: "error",
      error: error?.response?.data?.error_message || error?.message,
    });
  }
};

const modalStates = {
  modal: false,
  topicId: "",
  title: "",
  showName: "",
};

const topicsListDefaultState = {
  list: [],
  nPages: 1,
  metaData: {},
};

export {
  topicDetailsState,
  topicsMissingFields,
  modalStates,
  topicsListDefaultState,
  handleTopicsListingTab,
  handleTopicsTableHeadline,
  handleTopicsStatusColor,
  handleApproveOrDeclineTopic,
  handleDeleteTopic,
};
