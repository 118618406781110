import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { apiGateway } from "../../utils/config";
import { handleDesignerAnalyticsEvent } from "../../utils/eventFunction";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomModal from "../Common/CustomModal";
import FileUploader from "../Common/FileUploader";
import InputTitleWithRequired from "../Common/InputTitleWithRequired";
import useZustandStore from "../../zustandStore/useZustandStore";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DisplayShowAndTopic from "./DisplayShowAndTopic";

const AddThumbAndTitleIconModal = ({
  openUploadThumbAndTitle,
  handleGetUpdatedData,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState({ thumbnail: null, titleIcon: null });
  const axiosPrivate = useAxiosPrivate();
  const setToastMessage = useZustandStore((store) => store.setToastMessage);
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const MAX_FILE_SIZE_MB = 3; // Define max file size in MB
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes

  // common function for file change
  const handleFileChange = (e, field) => {
    const file = e.target?.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        setToastMessage({
          type: "error",
          message: `File size exceeds ${MAX_FILE_SIZE_MB}MB limit`,
        });
        return;
      }
      setFiles((prevFiles) => ({ ...prevFiles, [field]: file }));
    }
  };

  // input mapping
  const fileInputs = [
    {
      title: "Thumbnail",
      value: "thumbnail",
      displayImage: files?.thumbnail,
      displayText: `Upload Thumbnail (File size should be less than ${MAX_FILE_SIZE_MB}MB)`,
      onChange: (e) => {
        handleFileChange(e, "thumbnail");
      },
    },
    {
      title: "Title Icon",
      value: "titleIcon",
      displayImage: files?.titleIcon,
      displayText: `Upload Title Icon (File size should be less than ${MAX_FILE_SIZE_MB}MB)`,
      onChange: (e) => {
        handleFileChange(e, "titleIcon");
      },
    },
  ];

  // API call to upload thumbnail and title icon
  const handleAddThumbAndTitleIcon = async () => {
    if (!files?.thumbnail || !files?.titleIcon) {
      setToastMessage({
        type: "error",
        message: "Please upload both thumbnail and title icon",
      });
      return;
    }
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/cms/series-attribute-plan/${openUploadThumbAndTitle?.topicId}/create/`;
      const payload = new FormData();
      payload.append("thumbnail_image", files?.thumbnail);
      payload.append("title_icon", files?.titleIcon);
      const response = await axiosPrivate.post(url, payload);
      if (response?.data) {
        setIsLoading(false);
        handleGetUpdatedData();
        handleClose();
        handleDesignerAnalyticsEvent({
          userData: currentUser,
          videoTitle: openUploadThumbAndTitle?.title,
        }); // triggers an event for designer analytics
      }
    } catch (error) {
      setIsLoading(false);
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  return (
    <CustomModal
      title="Upload Thumbnail and Title Icon"
      show={openUploadThumbAndTitle?.modal}
      isLoading={isLoading}
      onHide={handleClose}
      handleConfirmation={() => {
        handleAddThumbAndTitleIcon();
      }}
    >
      <div>
        <DisplayShowAndTopic
          showName={openUploadThumbAndTitle?.showName}
          title={openUploadThumbAndTitle?.title}
        />
        {fileInputs?.map((item) => {
          return (
            <div
              key={item?.title}
              className={`first:!mt-0 ${
                item?.displayImage ? "!mt-0" : "!mt-3"
              }`}
            >
              <InputTitleWithRequired title={item?.title} isRequired />
              <div className="mt-1">
                <FileUploader
                  key={item?.title}
                  accept=".png, .jpeg, .jpg"
                  displayIcon={<FontAwesomeIcon icon={faImage} />}
                  displayText={
                    files[item?.value]
                      ? files[item?.value]?.name
                      : item?.displayText
                  }
                  onChange={item?.onChange}
                />
                {item?.displayImage && (
                  <div className="relative w-fit mx-auto">
                    <img
                      src={URL.createObjectURL(item?.displayImage)}
                      alt={item?.title}
                      className={`relative w-[110px] mx-auto ${
                        item?.value === "titleIcon" ? "bg-black p-2" : ""
                      }`}
                    />
                    <button
                      onClick={() => {
                        setFiles({ ...files, [item?.value]: null });
                      }}
                    >
                      <CancelIcon className="absolute top-1 text-white right-1 cursor-pointer !text-[17px]" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

export default AddThumbAndTitleIconModal;
