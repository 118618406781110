import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ApproveOrRejectVideoModal from "../Common/ApproveOrRejectVideoModal";
import CustomModal from "../Common/CustomModal";
import ModeratorStatusChip from "../Common/ModeratorStatusChip";

const VideoChecks = ({
  series,
  seriesVideos,
  setShowEdit,
  setMessage,
  setReload,
}) => {
  const [viewThumbnailAndIcon, setViewThumbnailAndIcon] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    approve: false,
    reject: false,
  });
  const isDeleted = series?.status === "deleted";
  const disableApprove =
    seriesVideos?.length === 0 ||
    series?.review_status === "approved" ||
    isDeleted;
  const disableReject =
    seriesVideos?.length === 0 ||
    series?.review_status === "rejected" ||
    series?.status === "live" ||
    series?.status === "scheduled" ||
    isDeleted;

  return (
    <div>
      <div className="mt-4 border-t">
        <div className="flex justify-between items-center mt-2">
          <div className="flex gap-x-4 items-center">
            <p className="text-[15px] font-semibold">
              Video Checks{" "}
              {series?.image && series?.title_icon ? (
                <FontAwesomeIcon icon={faCheck} className="text-green-500" />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-red-500"
                />
              )}
            </p>
            {series?.review_status && (
              <ModeratorStatusChip
                className="py-[3px]"
                reviewStatus={series?.review_status}
              />
            )}
          </div>

          <div className="flex gap-x-2">
            <button
              className={` bg-baseBlueColor text-white px-3 py-2 text-[14px] rounded-md ${
                disableApprove ? "opacity-50" : "opacity-100"
              } `}
              onClick={() =>
                setConfirmationModal({ approve: true, reject: false })
              }
              disabled={disableApprove}
            >
              Approve
            </button>
            <button
              disabled={disableReject}
              className={` bg-red-600 text-white px-3 py-2 text-[14px] rounded-md ${
                disableReject ? "opacity-50" : "opacity-100"
              }`}
              onClick={() =>
                setConfirmationModal({ approve: false, reject: true })
              }
            >
              Reject
            </button>
          </div>
        </div>
        <div className="flex gap-x-4 mt-2.5 items-start">
          <div className="flex gap-x-2 items-center">
            <button
              onClick={() => {
                if (series?.image) {
                  setViewThumbnailAndIcon(true);
                } else {
                  setShowEdit(true);
                }
              }}
              className="text-[13px] cursor-pointer border px-2 py-1 rounded-md !border-baseBlueColor"
            >
              {series?.image ? (
                "View Thumbnail"
              ) : (
                <div>
                  Add Thumbnail <FontAwesomeIcon icon={faPlus} />
                </div>
              )}
            </button>
            {series?.image && (
              <FontAwesomeIcon
                icon={faPen}
                className="text-[11px] text-gray-400 cursor-pointer"
                onClick={() => setShowEdit(true)}
              />
            )}
          </div>

          <div className="flex gap-x-3 items-center">
            <button
              onClick={() => {
                if (series?.title_icon) {
                  setViewThumbnailAndIcon(true);
                } else {
                  setShowEdit(true);
                }
              }}
              className="text-[13px] cursor-pointer border px-2 py-1 rounded-md !border-baseBlueColor"
            >
              {series?.title_icon ? (
                "View Title Icon"
              ) : (
                <div>
                  Add Title Icon <FontAwesomeIcon icon={faPlus} />
                </div>
              )}
            </button>
            {series?.title_icon && (
              <FontAwesomeIcon
                icon={faPen}
                className="text-[11px] text-gray-400 cursor-pointer"
                onClick={() => setShowEdit(true)}
              />
            )}
          </div>
        </div>
      </div>

      {/* Approve / Reject confirmation */}
      <ApproveOrRejectVideoModal
        videoTitle={series?.display_title}
        seriesSlug={series?.slug}
        openIsApprove={confirmationModal?.approve}
        openIsRejected={confirmationModal?.reject}
        setMessage={setMessage}
        setReload={setReload}
        handleClose={() =>
          setConfirmationModal({ approve: false, reject: false })
        }
      />
      {/* View thumbnail and title icon modal */}
      {viewThumbnailAndIcon && (
        <CustomModal
          show={viewThumbnailAndIcon}
          title={series?.display_title}
          isButtonsRequired={false}
          onHide={() => setViewThumbnailAndIcon(false)}
        >
          <div className="flex justify-between items-start w-[85%] mx-auto">
            <div>
              <h6 className="text-[14px] text-center">Thumbnail</h6>
              {series?.image ? (
                <img
                  src={series?.image}
                  alt={series?.display_title}
                  className="w-[140px] h-[190px] rounded-md mt-2"
                />
              ) : (
                "No Thumbnail"
              )}
            </div>

            <div>
              <h6 className="text-[14px] text-center">Title Icon</h6>
              {series?.title_icon ? (
                <img
                  src={series?.title_icon}
                  alt={series?.title_icon}
                  className="w-[150px] bg-black rounded-sm mt-2"
                />
              ) : (
                "No Title Icon"
              )}
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default VideoChecks;
