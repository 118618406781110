import React from "react";
import VideoListing from "../../components/VideoListing";
import useCmsOpenEvent from "../../hooks/useCmsOpenEvent";

const VideosPage = () => {
  useCmsOpenEvent(); // event triggers every 5sec

  return (
    <div className="mt-2.5">
      <VideoListing />
    </div>
  );
};

export default VideosPage;
