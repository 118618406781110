import { Tooltip } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";

const CustomButton = ({
  children,
  disabled,
  isLoading,
  tooltipMessage,
  className,
  spinnerClassName,
  onClick,
}) => {
  return (
    <Tooltip title={tooltipMessage && tooltipMessage}>
      <button
        disabled={disabled || isLoading}
        className={`relative px-2 py-1 rounded-lg text-white transition-opacity duration-300 flex items-center justify-center gap-2 bg-baseBlueColor ${
          disabled || isLoading
            ? "opacity-50 !cursor-not-allowed"
            : "hover:opacity-95"
        } ${className || ""}`}
        onClick={onClick}
      >
        {isLoading ? (
          <div className="h-[21px]">
            <LoadingSpinner
              className={`size-5 ${spinnerClassName} !text-white`}
            />
            <div className="opacity-0">{children}</div>
          </div>
        ) : (
          children
        )}
      </button>
    </Tooltip>
  );
};

export default CustomButton;
