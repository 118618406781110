const selectMonthOptions = ["current", "previous"];

const handleFormatMetrics = ({
  num,
  isGreaterThen10L = false,
  isGreaterThen10K = false,
}) => {
  // Show in crores if the number is greater than 1 crore
  if (num >= 10000000) {
    return (num / 10000000).toFixed() + "Cr";
  }

  // Show in lakhs only if the number is greater than 10 lakhs and `isGreaterThen10L` is true
  if (isGreaterThen10L && num >= 1000000) {
    return num / 100000 + "L";
  }

  // Show in thousands only if the number is greater than 10 thousand and `isGreaterThen10K` is true
  if (isGreaterThen10K && num >= 10000) {
    return num / 1000 + "K";
  }

  // Return the number as-is, including decimal points if applicable
  return num.toString();
};

const handleFormatViews = ({ views }) => {
  if (views <= 10000) {
    return views.toString(); // Show exact views for 10,000 or less
  } else if (views < 100000) {
    return (views / 1000).toFixed(1).replace(/\.0$/, "") + "K"; // For thousands, starting from above 10,000
  } else if (views < 10000000) {
    return (views / 100000).toFixed(1).replace(/\.0$/, "") + "L"; // For lakhs
  } else {
    return (views / 10000000).toFixed(1).replace(/\.0$/, "") + "Cr"; // For crores
  }
};

const handleFormatPayout = ({ totalPayout }) => {
  let formattedPayout;

  if (totalPayout >= 10000000) {
    // 1 Crore or more
    formattedPayout =
      (totalPayout / 10000000).toFixed(2).replace(/\.00$/, "") + " Cr";
  } else if (totalPayout >= 1000000) {
    // 10 Lakhs to less than 1 Crore
    formattedPayout =
      (totalPayout / 100000).toFixed(2).replace(/\.00$/, "") + " Lakh";
  } else {
    // Less than 10 Lakhs
    formattedPayout = totalPayout.toFixed(2).replace(/\.00$/, "");
  }

  return formattedPayout;
};

export {
  selectMonthOptions,
  handleFormatMetrics,
  handleFormatViews,
  handleFormatPayout,
};
