import superAdminBadge from "../../assests/images/super-admin-badge.svg";
import adminBadge from "../../assests/images/admin-badge.svg";
import defaultBadge from "../../assests/images/defaultBadge.svg";
const userDetailsState = {
  name: "",
  phoneNumber: "",
  roles: [],
};

const teamIsLoadingState = {
  createUser: false,
  updateUser: false,
  deleteUser: false,
};

const TEAM_ROLES = {
  superAdmin: "super_admin",
  admin: "admin",
  creator: "creator",
  blogger: "blogger",
  editor: "editor",
  viewer: "viewer",
  content_manager: "content_manager", // must be deactivated
  community_manager: "community_manager", // must be deactivated
  moderator: "moderator",
  designer: "designer",
};

const handleBGColorForRoles = ({ role }) => {
  switch (true) {
    case role?.includes(TEAM_ROLES.superAdmin):
      return `!bg-[#D84040]`;
    case role?.includes(TEAM_ROLES.admin):
      return `!bg-[#365E32]`;
    case role?.includes(TEAM_ROLES.creator):
      return `!bg-[#de800b]`;
    case role?.includes(TEAM_ROLES.blogger):
      return `!bg-[#de800b]`;
    case role?.includes(TEAM_ROLES.editor):
      return `!bg-[#5E1675]`;
    case role?.includes(TEAM_ROLES.viewer):
      return `!bg-[#292C6D]`;
    case role?.includes(TEAM_ROLES.designer):
      return `!bg-[#DB6B97]`;
    case role?.includes(TEAM_ROLES.moderator):
      return `!bg-[#810000]`;
    default:
      return "!text-white !bg-black";
  }
};

const handleGetBadge = ({ role }) => {
  switch (true) {
    case role?.includes(TEAM_ROLES.superAdmin):
      return superAdminBadge;
    case role?.includes(TEAM_ROLES.admin):
      return adminBadge;
    default:
      return defaultBadge;
  }
};

const handleDisplayRole = ({ role }) => {
  switch (true) {
    case role?.includes(TEAM_ROLES.superAdmin):
      return "Super Admin";
    case role?.includes(TEAM_ROLES.admin):
      return "Admin";
    case role?.includes(TEAM_ROLES.creator):
      return "Creator";
    case role?.includes(TEAM_ROLES.blogger):
      return "Blogger";
    case role?.includes(TEAM_ROLES.editor):
      return "Editor";
    case role?.includes(TEAM_ROLES.viewer):
      return "Viewer";
    case role?.includes(TEAM_ROLES.content_manager): // must be removed
      return "Content Manager";
    case role?.includes(TEAM_ROLES.community_manager): // must be removed
      return "Community Manager";
    case role?.includes(TEAM_ROLES.moderator):
      return "Moderator";
    case role?.includes(TEAM_ROLES.designer):
      return "Designer";
    default:
      return "";
  }
};

export {
  userDetailsState,
  teamIsLoadingState,
  TEAM_ROLES,
  handleBGColorForRoles,
  handleDisplayRole,
  handleGetBadge,
};
