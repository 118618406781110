import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { TrendingUp, WhatsApp } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { handleSeriesStatusColor } from "../../utils/constants/VideoListing";
import { axiosPrivate } from "../../api/axiosCustom";
import { apiGateway } from "../../utils/config";
import { selectConfigFilters } from "../../redux/config/config.selector";
import { handleDeleteQueryFromUrl } from "../../utils/utils";
import { Tooltip } from "@mui/material";
import placeholder from "../../assests/images/placeholder1.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CustomModal from "../Common/CustomModal";
import useZustandStore from "../../zustandStore/useZustandStore";
import CustomButton from "../Common/CustomButton";
import CreateWhatsAppGroup from "./CreateWhatsAppGroup";

const ShowRequiredDetails = ({
  data,
  isAdmin,
  isSuperAdmin,
  hasAccessToActions,
  isEditor,
  showSlug,
  toggleCreate,
  setDialogStatus,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listOfNumbers, setListOfNumbers] = useState([]);
  const configFilters = useSelector(selectConfigFilters);
  const appNames = configFilters?.app_names;
  const [selectApp, setSelectApp] = useState(appNames?.[0]?.value);
  const setToastMessage = useZustandStore((val) => val.setToastMessage);
  const history = useHistory();

  // Adds list of Phone Number To the Group
  const handleAddPhoneNumber = () => {
    const numberWith91 = "+91" + (phoneNumber?.trim() ?? "");
    // check if the number is valid
    if (
      phoneNumber?.length === 10 &&
      phoneNumber?.trim() &&
      !listOfNumbers?.includes(numberWith91?.trim())
    ) {
      setListOfNumbers([...listOfNumbers, numberWith91.trim()]);
      setPhoneNumber("");
    } else {
      setToastMessage({
        type: "error",
        message:
          phoneNumber?.length < 10
            ? "Phone No. is invalid"
            : listOfNumbers?.includes(numberWith91?.trim()) // Notify if number is already added
            ? "Phone No. already added"
            : "",
      });
    }
  };

  const handleRemovePhoneNumber = (index) => {
    setListOfNumbers(listOfNumbers.filter((_, i) => i !== index));
  };

  const handleRouteToCreator = ({ creatorId }) => {
    history.push(`/creators/${creatorId}`);
  };

  const handleCreateGroup = async () => {
    try {
      if (selectApp && listOfNumbers?.length > 0) {
        setIsLoading(true);
        const url = `${apiGateway}/api/v1/cms/show/${showSlug}/create-whatsapp/`;
        const payload = {
          app_name: selectApp,
          phone_numbers: listOfNumbers,
        };
        const response = await axiosPrivate.post(url, payload);
        if (response?.data) {
          setIsLoading(false);
          setShowWhatsAppModal(false);
          setListOfNumbers([]);
          setToastMessage({
            type: "success",
            message: "Whatsapp Group Initiated Successfully",
          });
        }
      } else {
        setToastMessage({
          type: "error",
          message: !selectApp
            ? "Please Select the APP"
            : listOfNumbers?.length === 0
            ? "Please add Phone number"
            : "",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error.message,
      });
    }
  };
  return (
    <div>
      <Accordion className="mt-3 shadow-none rounded-lg">
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="w-full mx-auto flex justify-between items-center px-4">
            <div className="flex gap-x-4 lg:w-[80%] lg:!items-center">
              {/* image */}
              <div className="relative">
                <img
                  src={data?.image || placeholder}
                  alt={data?.title}
                  className="w-[60px] h-[60px] rounded"
                />
                <span className="text-[10px] absolute tex-gray-600 px-1 py-[2px] rounded top-0 right-0 bg-black text-white opacity-[70%]">
                  {data?.id}
                </span>
              </div>

              {/* Details */}
              <div>
                <div className="flex gap-x-4 text-[14px] text-gray-500 flex-col flex-wrap lg:!flex-row ">
                  <h6 className="!text-[16px] font-semibold text-black max-w-[230px]">
                    {data?.title}
                  </h6>
                  <div
                    className={`capitalize w-fit !px-4 rounded-2xl text-[12px] py-[1px] h-fit ${handleSeriesStatusColor(
                      { status: data?.status }
                    )}`}
                  >
                    {data?.status}
                  </div>
                </div>
                <div className="flex gap-x-4 text-[13px] text-gray-500 flex-col flex-wrap lg:!flex-row mt-2">
                  <button
                    className="!p-0 !h-fit hover:underline"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRouteToCreator({ creatorId: data?.creator?.id });
                    }}
                  >
                    {data?.creator?.name}
                  </button>
                  <div>{data?.content_manager?.name}</div>
                  <div>{data?.category?.title}</div>
                  {/* <div>{`#${data?.id}`}</div> */}
                  <div>{`${data?.n_series} videos`}</div>
                  <div>Frequency-{data?.frequency || "0"}</div>
                  <div>{data?.show_time || "00:00"}</div>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-x-2 flex-col lg:!flex-row">
              <a
                href={`/#/shows-topics/${showSlug}`}
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()} // Prevent accordion toggle
              >
                <div className="flex items-center space-x-4 text-sm text-gray-600">
                  <button className="bg-baseBlueColor text-white px-3 py-2 rounded-full text-[14px] flex items-center">
                    <TrendingUp className="mr-2 h-4 w-4" />
                    Topics
                  </button>
                </div>
              </a>

              {hasAccessToActions && (
                <Tooltip
                  title={
                    data?.chat_id
                      ? "Whatsapp Group Has Been Created"
                      : "Create WhatsApp Group"
                  }
                >
                  <div className="flex items-center space-x-4 text-sm text-gray-600">
                    <CustomButton
                      className="!px-[12px] py-2 !rounded-full flex items-center gap-[2px] bg-green-500"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent accordion toggle
                        setShowWhatsAppModal(true);
                      }}
                      disabled={data?.chat_id ? true : false}
                    >
                      <WhatsApp />
                    </CustomButton>
                  </div>
                </Tooltip>
              )}

              {hasAccessToActions && (
                <div onClick={(e) => e.stopPropagation()}>
                  {/* Prevent accordion toggle */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      className="dropdown-action !shadow-none !outline-none focus:!shadow-none focus:!outline-none"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisH}
                        className="rejection-logs mr-3 ml-0"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-2">
                      <Dropdown.Item
                        className="action-menu-item"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent accordion toggle
                          toggleCreate();
                        }}
                      >
                        Edit Show
                      </Dropdown.Item>
                      {(isAdmin ||
                        isSuperAdmin ||
                        (isEditor && data?.status === "live")) && (
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion toggle
                            setDialogStatus({
                              open: true,
                              type: data?.status == "draft" ? "live" : "draft",
                              msg:
                                data?.status == "draft"
                                  ? "Make this show live"
                                  : "Move this show to draft",
                            });
                          }}
                        >
                          {data?.status == "draft"
                            ? "Make Live"
                            : "Move to Draft"}
                        </Dropdown.Item>
                      )}
                      {data?.status === "draft" && (
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion toggle
                            setDialogStatus({
                              open: true,
                              type: "deleted",
                              msg: "Delete this show",
                            });
                          }}
                        >
                          Delete Show
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="-mt-5">
          <div className="flex px-4 w-[85%]">
            <img
              src={data?.image || placeholder}
              alt={data?.title}
              className="w-[50px] h-[50px] rounded opacity-0"
            />
            <h6 className="px-3 text-[14px] text-gray-500 mt-1 leading-[23px] pb-2">
              {data?.description}
            </h6>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Modal To Invite group members */}
      {showWhatsAppModal && (
        <CustomModal
          title="Create WhatsApp Group"
          show={showWhatsAppModal}
          handleConfirmationBtnText="Create"
          isLoading={isLoading}
          onHide={() => {
            setShowWhatsAppModal(false);
            setListOfNumbers([]);
            setPhoneNumber("");
            setSelectApp("");
            handleDeleteQueryFromUrl({ history, queryNames: ["app"] });
          }}
          handleConfirmation={handleCreateGroup}
        >
          <CreateWhatsAppGroup
            selectApp={selectApp}
            phoneNumber={phoneNumber}
            listOfNumbers={listOfNumbers}
            setSelectApp={setSelectApp}
            handleAddPhoneNumber={handleAddPhoneNumber}
            handleRemovePhoneNumber={handleRemovePhoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default ShowRequiredDetails;
