import { Dropdown } from "react-bootstrap";
import {
  handleBGColorForRoles,
  handleGetBadge,
  TEAM_ROLES,
} from "../../utils/constants/Team";
import { handleDefaultProfile } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Verified from "../Common/Icons/Verified";

const TeamCard = ({ item, handleClickedEdit, handleClickedDelete }) => {
  return (
    <div className="relative border !rounded-md text-black w-[100%] break-words pb-4 shadow-md shadow-baseFillColor">
      <Dropdown className="w-fit absolute top-0 right-2">
        <Dropdown.Toggle className="dropdown-action-common">
          <FontAwesomeIcon
            className="rejection-logs ml-1 !cursor-pointer text-white"
            icon={faEllipsisH}
            size="1x"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className="action-menu !rounded-[10px] !shadow-lg">
          <Dropdown.Item
            className="action-menu-item flex items-center gap-x-2"
            onClick={() => {
              handleClickedEdit({ item });
            }}
          >
            <FontAwesomeIcon icon={faPen} className="text-[12px]" /> Edit
          </Dropdown.Item>

          <Dropdown.Item
            className="action-menu-item flex items-center gap-x-2"
            onClick={() => {
              handleClickedDelete({
                id: item?.id,
              });
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-[12px]" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <div
        className={`bg-[#082751] py-10 text-white text-center rounded-t-md ${handleBGColorForRoles(
          { role: item?.roles }
        )}`}
      >
        <h6
          className="text-[17px] capitalize"
          style={{ fontFamily: "fantasy" }}
        >
          {item?.roles?.includes(TEAM_ROLES.superAdmin)
            ? "Super Admin"
            : item?.roles}
        </h6>

        <img
          src={
            item?.avatar ||
            handleDefaultProfile({
              userName: item?.name || "Seekho User",
            })
          }
          alt={item?.name || "Seekho User"}
          className="absolute top-15 left-2 !w-[65px] h-[65px] rounded-full object-contain"
        />
      </div>

      <div className="flex justify-end absolute -left-5 -top-1 -rotate-45">
        <img
          src={handleGetBadge({ role: item?.roles })}
          alt="badge"
          className="w-[55px]"
        />
      </div>
      <div className="mt-[34px] flex !items-center gap-x-1 px-2">
        <h6 className=" font-semibold text-[14px] !line-clamp-1 !break-words break-all capitalize">
          {item?.name || "Seekho User"}
        </h6>
        <Verified className="w-[12px]" />
      </div>

      <h6 className="mt-1 text-[12px] px-2 text-gray-400">
        {item?.phone || "Seekho User"}
      </h6>
    </div>
  );
};

export default TeamCard;
