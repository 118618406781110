import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useHistory } from "react-router-dom";
import { handleTopicsListingTab } from "../../utils/constants/Topics";
import {
  handleAllyProps,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
} from "../../utils/utils";
import useUserRoles from "../../hooks/useUserRoles";

const TabsSection = ({ searchParamTabState }) => {
  const history = useHistory();
  const { isDesigner } = useUserRoles();

  // Tab Change
  const handleChangeTabs = (event, newValue) => {
    let state = handleTopicsListingTab({
      isDesigner,
    })?.filter((d) => d.index === newValue)?.[0]?.value;
    ["state", "page"]?.forEach((item, index) =>
      handleAppendQueryToUrl({
        history,
        queryName: item,
        queryValue: index ? 1 : `${state}-${newValue}`,
      })
    );
    handleDeleteQueryFromUrl({ history, queryNames: ["s", "order"] });
  };

  return (
    <div>
      <Box>
        <Tabs
          value={searchParamTabState}
          onChange={handleChangeTabs}
          aria-label="basic tabs example"
          sx={{
            minHeight: 35,
            height: 35,
          }}
        >
          {handleTopicsListingTab({ isDesigner })?.map((data, index) => (
            <Tab
              label={data?.id}
              {...handleAllyProps({ index })}
              key={index}
              sx={{
                minHeight: 35,
                height: 35,
              }}
            />
          ))}
        </Tabs>
      </Box>
    </div>
  );
};

export default TabsSection;
