import React from "react";

const DisplayShowAndTopic = ({ showName = "", title = "" }) => {
  return (
    <div>
      <h6 className="text-[14px] text-gray-600 mt-1">
        <span className="text-black">Show :</span>{" "}
        <span className="text-gray-600">{showName}</span>
      </h6>
      <h6 className="text-[14px] mt-2">
        <span className="text-black">Topic :</span>{" "}
        <span className="text-gray-600">{title}</span>
      </h6>
    </div>
  );
};

export default DisplayShowAndTopic;
