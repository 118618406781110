import React, { useState } from "react";
import { apiGateway } from "../../utils/config";
import { handleVideoApprovedOrRejectedEvent } from "../../utils/eventFunction";
import { useSelector } from "react-redux";
import CustomModal from "./CustomModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ApproveOrRejectVideoModal = ({
  videoTitle,
  seriesSlug,
  openIsApprove,
  openIsRejected,
  setMessage,
  setReload,
  handleGetSeries,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const currentUser = useSelector((state) => state?.user?.currentUser);

  const handleApproveOrRejectAPICall = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/cms/${seriesSlug}/series-review/`;
      const payload = { action: openIsApprove ? "approved" : "rejected" };
      const response = await axiosPrivate.post(url, payload);
      if (response?.data) {
        const eventStatus = openIsApprove ? "approved" : "rejected";
        handleVideoApprovedOrRejectedEvent({
          userId: currentUser?.id,
          userName: currentUser?.name,
          videoTitle,
          status: eventStatus,
        });
        setIsLoading(false);
        setMessage({
          type: "success",
          error: `Video has been successfully ${
            openIsApprove ? "Approved" : "Rejected"
          }`,
        });
        handleClose();
        if (setReload) setReload(true);
        if (handleGetSeries) handleGetSeries(); // fetched updated series
      }
    } catch (error) {
      setIsLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error.message,
      });
    }
  };

  return (
    <div>
      {(openIsApprove || openIsRejected) && (
        <CustomModal
          show={openIsApprove || openIsRejected}
          title="Approve Video"
          isLoading={isLoading}
          onHide={handleClose}
          handleConfirmation={handleApproveOrRejectAPICall}
        >
          <div>
            Are you sure you want to{" "}
            {openIsApprove ? (
              <span className="text-green-500 font-semibold">Approve</span>
            ) : (
              <span className="text-red-500 font-semibold">Reject</span>
            )}{" "}
            this video?
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default ApproveOrRejectVideoModal;
