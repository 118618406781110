import userReducer from "./user/user.reducer";
import VideoReducer from "./video/video.reducer";
import CreatorReducer from "./creator/creator.reducer";
import SeriesReducer from "./series/series.reducer";
import ConfigReducer from "./config/config.reducer";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import seriesNotificationReducer from "./seriesDetails/seriesDetails.reducer";
import globalLanguageReducer from "./language/reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "notification", "config", "common", "globalLanguage"],
};

const rootReducer = combineReducers({
  user: userReducer,
  video: VideoReducer,
  series: SeriesReducer,
  creator: CreatorReducer,
  config: ConfigReducer,
  notification: seriesNotificationReducer,
  globalLanguage: globalLanguageReducer,
});

export default persistReducer(persistConfig, rootReducer);
