import React from "react";

const NoFeedbackSvj = ({ className, displayText, displayTextClass }) => {
  return (
    <div>
      <svg
        viewBox="0 0 117 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M90.9114 51.4857L90.5286 47.4978C90.5286 47.4978 93.0833 47.7239 95.8173 50.0807C97.5225 51.5506 98.1823 52.4196 99.203 54.4262C100.54 57.0554 100.271 56.9115 100.954 61.6616C101.101 62.6861 102.208 63.8276 103.521 63.8198C104.835 63.812 105.565 62.3454 106.16 61.0187C107.565 57.8855 106.078 54.6667 107.04 51.0736C107.504 49.3386 109.049 47.4789 111.152 46.7781C112.554 46.3111 114.794 46.331 115.334 48.6466C115.824 50.7487 112.97 51.5983 112.97 51.5983C112.97 51.5983 115.825 52.1504 116.057 54.7272C116.204 56.3548 115.208 56.7716 114.422 56.8218C113.512 56.8799 112.374 56.7079 111.853 55.1869C111.008 52.7214 110.163 53.189 110.163 53.189C110.163 53.189 111.156 59.9599 108.905 64.2961C107.353 67.2852 105.07 68.3559 102.552 67.5042C99.6054 66.5078 98.3169 64.5483 97.5536 61.6367C96.5911 57.9653 97.1541 56.9836 95.4299 54.4602C93.5574 51.7198 90.9114 51.4857 90.9114 51.4857Z"
          fill="#E8E8E8"
          stroke="#E8E8E8"
          strokeWidth="0.183193"
        />
        <path
          d="M26.8336 52.9259L27.7684 49.0845C27.7684 49.0845 24.2694 48.4784 21.294 50.5219C19.4382 51.7964 18.6873 52.588 17.4531 54.4709C15.8362 56.9379 16.1201 56.8244 14.9213 61.4713C14.6627 62.4735 13.4374 63.4871 12.1329 63.3356C10.8283 63.1841 10.2629 61.6464 9.81658 60.2626C8.76259 56.9944 10.5928 53.9576 10.0302 50.2809C9.75849 48.5055 8.42692 46.488 6.41266 45.5612C5.07046 44.9436 2.84113 44.7183 2.05143 46.9609C1.33452 48.9968 4.07777 50.1535 4.07777 50.1535C4.07777 50.1535 1.18042 50.39 0.667018 52.9259C0.342745 54.5276 1.28741 55.051 2.06337 55.1869C2.96168 55.3442 4.11176 55.2977 4.79602 53.8429C5.90526 51.4846 6.69425 52.0419 6.69425 52.0419C6.69425 52.0419 4.96677 58.6635 6.7299 63.22C7.94531 66.361 10.0968 67.6749 12.6936 67.1039C15.7312 66.4359 17.2264 64.6292 18.3037 61.8185C19.6622 58.2745 19.2099 57.2371 21.1999 54.9175C23.361 52.3985 26.8336 52.9259 26.8336 52.9259Z"
          fill="#E8E8E8"
          stroke="#E8E8E8"
          strokeWidth="0.183193"
        />
        <path
          d="M58.3044 59.2899L31.827 29.8776L95.1208 29.8779L58.3044 59.2899Z"
          fill="white"
        />
        <path
          d="M68.6546 0.370225L95.132 29.7825L31.8382 29.7822L68.6546 0.370225Z"
          fill="#E8E8E8"
        />
        <path
          d="M57.9314 62.2805L67.458 54.4946L87.0699 76.3135L23.776 76.3132L50.879 54.4946L57.9314 62.2805Z"
          fill="#E8E8E8"
        />
        <path
          d="M49.4138 53.486L23.2173 75.3777L30.4288 30.9573L49.4138 53.486Z"
          fill="#E8E8E8"
        />
        <path
          d="M68.5589 53.5767L94.9681 31.2695L88.1606 75.7431L68.5589 53.5767Z"
          fill="#E8E8E8"
        />
        <path
          d="M50.3289 42.8591C50.0332 44.1407 48.8772 44.7826 47.7643 44.7826C46.665 44.691 46.207 43.775 46.207 42.3092C46.207 40.9939 47.9505 39.46 49.0465 39.6533C50.6037 39.928 50.6037 41.6682 50.3289 42.8591Z"
          fill="#9F9F9F"
        />
        <path
          d="M74.4197 42.7848C74.124 44.0664 72.968 44.7084 71.8551 44.7084C70.7558 44.6168 70.2979 43.7008 70.2979 42.235C70.2979 40.9197 72.0413 39.3858 73.1373 39.5791C74.6945 39.8537 74.6945 41.594 74.4197 42.7848Z"
          fill="#9F9F9F"
        />
        <path
          d="M63.0677 43.5906C63.0677 43.5906 65.7699 44.4125 65.1739 45.6059C64.9135 46.1273 64.2523 46.4843 63.5259 46.6859C61.7855 46.7965 60.8495 46.6859 60.32 46.6859C58.8537 46.6859 58.5797 46.9382 57.5719 47.3461C56.8151 47.6525 54.915 49.2695 54.0906 48.3537C53.2662 47.4378 54.0234 46.1232 54.5487 45.3311C55.6011 43.744 56.9551 43.1873 58.8537 43.0411C61.2351 42.8579 63.0677 43.5906 63.0677 43.5906Z"
          fill="#9F9F9F"
        />
      </svg>

      <div className={displayTextClass}>{displayText}</div>
    </div>
  );
};

export default NoFeedbackSvj;
