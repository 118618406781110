import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import videojs from "video.js";
import useFullscreenStatus from "./useFullScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import '@videojs/http-streaming';
import "./styles.css"
import "video.js/dist/video-js.css";
import useZustandStore from "../../zustandStore/useZustandStore";

const usePlayer = ({ src, controls, autoplay, loop, muted, videoRef, onEnded, controlProps, setTimeStamp }) => {
  const options = {
    fill: true,
    fluid: true,
    preload: "meta",
    html5: {
      vhs: {
        enableLowInitialPlaylist: true,
        overrideNative: true
      }
    }
  };
  const [player, setPlayer] = useState(null);
  const videoStartTime = useZustandStore((state) => state.videoStartTime);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      ...options,
      controls,
      autoplay,
      loop,
      muted,
      sources: [src],
      playbackRates: [0.5, 1, 1.5, 2]
    });
    setPlayer(vjsPlayer);

      // Seek to startTime after player is ready when clicked on time from remark
      vjsPlayer.ready(() => {
        if (videoStartTime && !isNaN(videoStartTime)) {
          vjsPlayer.currentTime(videoStartTime); // Seek to the given timestamp
          setTimeout(() => {
            vjsPlayer.pause();
          }, 10); // timeout because video.js is not pausing the video immediately as it is async
        }
      });

     // Listening to time stamps
     vjsPlayer.on("timeupdate", () => {
      if (setTimeStamp) {
        setTimeStamp(vjsPlayer.currentTime()); // Pass timestamp to parent
      }
    });

    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.src({ src });
    }
    if(videoRef !== null){
      let video = videoRef?.current
      video.onended = function(e) {
        /*Do things here!*/
        if(onEnded){
          onEnded(controlProps?.seriesVideos);
        }
      };
    }
  }, [src]);

  return videoRef;
};

const VideoPlayerCustom = ({ src, controls, autoplay, loop, muted,videoRef, controlProps, showNextPrev, onEnded, setTimeStamp }) => {
  const [isFullscreen, setIsFullscreen] = useFullscreenStatus(videoRef)
  const playerRef = usePlayer({ src, controls, autoplay, loop, muted, videoRef, onEnded, controlProps, setTimeStamp });

  return (
    <div data-vjs-player>
      <video controls ref={playerRef} className="video-js vjs-big-play-centered vjs-9-16" crossorigin="anonymous"/>
      {isFullscreen && showNextPrev && <div className="icon-fullscreen">
        {controlProps?.playIndex > 0 && (
          <div className="prev-icon-fs" onClick={controlProps?.playVideoPrev}>
            <FontAwesomeIcon
              size="2x"
              className="icons-fs"
              icon={faChevronCircleLeft}
            />
          </div>
        )}
        {(controlProps?.playIndex < (controlProps?.seriesVideos?.length - 1) || controlProps?.hasMoreVideos) && (
          <div className="next-icon-fs" onClick={() => controlProps?.playVideoNext(controlProps?.seriesVideos)}>
            <FontAwesomeIcon
              size="2x"
              className="icons-fs"
              icon={faChevronCircleRight}
            />
          </div>
        )}
      </div>}
    </div>
  );
};

VideoPlayerCustom.propTypes = {
  src: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool
};

VideoPlayerCustom.defaultProps = {
  controls: false,
  loop: false,
  muted: true,
  autoplay: true
};

export default VideoPlayerCustom;
