import React from "react";
import {
  handleAppendQueryToUrl,
  handleDefaultProfile,
  handleDeleteQueryFromUrl,
  handleSecureInvoice,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPen } from "@fortawesome/free-solid-svg-icons";
import { MenuItem, Select, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { selectMonthOptions } from "../../utils/constants/Dashboard";
import Verified from "../Common/Icons/Verified";
import useUserRoles from "../../hooks/useUserRoles";

const ProfileDetails = ({
  selectedMonth,
  setOpenUpdateProfile,
  setSelectedMonth,
}) => {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const { isCreator } = useUserRoles();
  const isDisableInvoiceCTA = handleSecureInvoice();
  const history = useHistory();

  const handleMonthChange = (e) => {
    const { value } = e.target;
    setSelectedMonth(value);
    if (value === "previous") {
      handleAppendQueryToUrl({
        history,
        queryName: "month",
        queryValue: value,
      });
    } else {
      handleDeleteQueryFromUrl({
        history,
        queryNames: ["month"],
      });
    }
  };
  return (
    <div>
      <div className="flex gap-x-3 mt-4 justify-between items-start">
        <div className="flex gap-x-3">
          <img
            src={
              currentUser?.avatar ||
              handleDefaultProfile({ userName: currentUser?.name })
            }
            alt={currentUser?.name || "Seekho User"}
            className="w-14 h-14 rounded-full !object-contain shadow-sm"
          />

          <div>
            <div className="flex items-center gap-x-1">
              <h6 className="text-[18px] font-medium">{currentUser?.name}</h6>
              <Verified />
            </div>
            <h6 className="!text-[14px] font-normal text-gray-500 tracking-wide mt-1">
              {currentUser?.status}
            </h6>
          </div>
        </div>

        <div className="flex gap-x-3 items-center">
          <FontAwesomeIcon
            icon={faPen}
            className="p-2 text-[32px] bg-baseBlueColor text-white rounded-full cursor-pointer"
            onClick={() => setOpenUpdateProfile(true)}
          />
          {/* Month selecting Tag */}
          <div>
            <Select
              displayEmpty
              value={selectedMonth}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& fieldset": { border: "none" },
                  "&:hover fieldset": { border: "1px solid #534f8f" }, // baseBlueColor
                  "&.Mui-focused fieldset": { border: "1px solid #534f8f" }, // baseBlueColor
                },
                "& .MuiSelect-select": {
                  marginTop: "3px",
                },
                "& .MuiSelect-icon": {
                  color: "white", // To Change arrow color
                },
              }}
              className="w-[105px] !text-[14px] h-[33px] !font-medium bg-baseBlueColor text-white capitalize"
              onChange={handleMonthChange}
            >
              <MenuItem disabled className="!text-[14px]" value={selectedMonth}>
                Select Month
              </MenuItem>
              {selectMonthOptions?.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  className="!text-[14px] capitalize"
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
          {isCreator && (
            <Tooltip
              title={
                isDisableInvoiceCTA
                  ? "You can generate an invoice only from the 4th to the 10th of every month."
                  : ""
              }
            >
              <button
                className={` flex gap-x-2 items-center justify-center px-3 py-1.5 rounded-sm font-medium text-[14px] bg-baseBlueColor text-white ${
                  isDisableInvoiceCTA ? "!cursor-not-allowed opacity-40" : ""
                }`}
                disabled={isDisableInvoiceCTA}
                onClick={() => history.push("/generate-invoice")}
              >
                <FontAwesomeIcon icon={faFile} /> INVOICE
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
