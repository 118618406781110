import { useState } from "react";
import { handleConvertTimeToSeconds } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import CustomReadMore from "../Common/CustomReadMore";
import useZustandStore from "../../zustandStore/useZustandStore";

const Remarks = ({ allRemarks, cbForVideoPlay }) => {
  const [readMore, setReadMore] = useState(false);
  const setVideoStartTime = useZustandStore((state) => state.setVideoStartTime);

  const handleToggleReadMore = (index) => {
    setReadMore((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleClickedOnTime = ({ time }) => {
    // convert time to seconds
    const startTimeInSeconds = time
      ? handleConvertTimeToSeconds({ timeString: time })
      : 0;
    setVideoStartTime(startTimeInSeconds); // update the video start time in zustand store
    cbForVideoPlay && cbForVideoPlay(); // Invoking href to play video from the given timestamp
  };

  return (
    <div className="mt-4">
      <h6 className="text-[15px] font-semibold">Video Review Remarks</h6>
      <div className="p-1 mt-2 border-t">
        {allRemarks && Object.keys(allRemarks).length > 0 ? (
          Object?.entries(allRemarks)?.map(([item, remarks]) => {
            return (
              <div
                key={item}
                className="flex gap-x-2 first:!mt-2 mt-3 items-center"
              >
                <span
                  className="px-1.5 py-1.5 text-[11px] rounded-md text-white font-semibold bg-baseBlueColor cursor-pointer flex gap-x-1 items-center"
                  onClick={() => handleClickedOnTime({ time: item })}
                >
                  {item} <FontAwesomeIcon icon={faPlay} />
                </span>
                <div className="border !border-[#E5E5E5] rounded-md px-2 py-1 w-full flex flex-col">
                  {remarks?.map((item, index) => {
                    return (
                      <div className="flex gap-x-1">
                        {remarks?.length > 1 && <span> • </span>}
                        <CustomReadMore
                          index={index}
                          displayText={item}
                          readMore={readMore}
                          numberOfCharactersToShow={150}
                          className="!text-[13px]"
                          handleToggleReadMore={() =>
                            handleToggleReadMore(index)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <h6 className="text-[13px] mt-1 font-medium text-gray-500">
            No Remarks To Display!
          </h6>
        )}
      </div>
    </div>
  );
};
export default Remarks;
