/* eslint-disable no-unused-vars */
import React from "react";
import { allLogos } from "../../utils/constants";
import LoginPage from "../../assests/images/LoginPage.svg";
import "./login.styles.css";
import LoginForm from "../../components/Login-Form/login-form.component";
import SeekhoWhiteLogo from "../../components/SideNavigation/Icons/seekho-white.png";

const Login = () => {
  return (
    <div className="SW-wrapper-block bg-white">
      <div className="hidden lg:flex w-[55%] justify-center items-center m-auto h-full px-4 bg-baseBlueColor">
        <div className="!w-[65%] mx-auto !relative">
          <img src={LoginPage} alt="LoginPage" />
          <div className="absolute -top-10 w-full mx-auto">
            <img
              src={SeekhoWhiteLogo}
              className="w-[100px] mx-auto flex justify-center items-center"
              alt="seekho"
            />
          </div>
        </div>
      </div>
      <div className="SW-splash-screen-form lg:w-[50%]">
        {/* <div className="flex justify-end gap-x-3">
          <a href="https://play.google.com/store/apps/details?id=com.seekho.android">
            <PlayStoreLogo className="w-[130px]" />
          </a>
          <a href="https://apps.apple.com/in/app/seekho-short-learning-videos/id6670752585">
            <AppStoreLogo className="w-[130px]" />
          </a>
        </div> */}
        <div>
          <h1 className="text-center text-[#353536] mb-4 text-[35px] font-bold">
            Login to Your Account
          </h1>
          <div className="flex justify-center items-center gap-x-4">
            {allLogos?.map((item) => {
              return (
                <a
                  key={item?.id}
                  href={item?.playStoreLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={item?.logo}
                    className={` ${
                      item?.id === 0 ? "w-[45px]" : "w-[50px]"
                    } h-[50px] p-2 !rounded-lg shadow-2xl bg-baseBlueColor/10`}
                    alt={item?.alt}
                  />
                </a>
              );
            })}
          </div>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
