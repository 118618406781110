import React from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomReadMore = ({
  readMore,
  index,
  displayText,
  className,
  numberOfCharactersToShow = 300,
  handleCopyDisplayText,
  handleToggleReadMore,
}) => {
  return (
    <div>
      <p
        className={`text-[14px] mt-1 break-words text-[#333333] leading-[20px] tracking-wide break-all overflow-hidden max-h-full ${
          className || ""
        }`}
      >
        {readMore?.[index]
          ? displayText
          : displayText?.slice(0, numberOfCharactersToShow)}{" "}
        {displayText?.length > numberOfCharactersToShow && (
          <button
            onClick={handleToggleReadMore}
            className="text-baseBlueColor !cursor-pointer font-semibold !text-[11px]"
          >
            {readMore?.[index] ? (
              <div>
                {handleCopyDisplayText && (
                  <span>
                    <FontAwesomeIcon
                      icon={faCopy}
                      className="text-gray-500 text-[14px]"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopyDisplayText({ displayText });
                      }}
                    />
                  </span>
                )}{" "}
                ...Read Less
              </div>
            ) : (
              <div>...Read More</div>
            )}
          </button>
        )}
      </p>
    </div>
  );
};

export default CustomReadMore;
