import React, { useRef, useState } from "react";
import {useHistory} from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import "./login-form.styles.css";
import { connect } from "react-redux";
import { fetchUserStartAsync } from "../../redux/user/user.action";
import AlertComponent from "../Alert-Messages/alert-component.component";
import { axiosPublic } from "../../api/axiosCustom";
import { apiGateway } from "../../utils/config";
import { initializeVideos } from "../../redux/video/video.action";
import useAuth from "../../hooks/useAuth";
import { OTP_SEND, OTP_VERIFY, OTP_VERIFY_LOCAL } from "../../utils/constants";
import Cookies from 'js-cookie';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useZustandStore from "../../zustandStore/useZustandStore";

function LoginForm() {
  const history = useHistory();
  const { setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [mobile, setMobile] = useState(null);
  const [otp, setOtp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otpSend, setOtpSend] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorType, setErrorType] = useState('info');
  const [inputFocus,setInputFocus] = useState(false);
  // const [otpRetry, setOtpRetry] = useState(60);
  const inputOtp = useRef();
  const isUserInvited = history?.location?.search?.includes("?q=invite");
  const setToastMessage = useZustandStore((val) => val.setToastMessage);

  const onSignInSubmit = async (e) => {
    e.preventDefault();
    if(mobile?.length > 10 || mobile?.length < 10 || mobile == null || mobile == undefined){
      setErrorMessage(`Phone number is invalid`);
      setErrorType('failed');
    }else{
      setIsLoading(true);
      const url = apiGateway + OTP_SEND;
      const requestBody = {
        phone_number: mobile,
        country_code: "+91"
      };
      try {
        await axiosPublic.post(url,
          requestBody
        ).then((response) => {
          if(response?.status === 200){
            setErrorMessage(`Success!, OTP send to phone +91 xxxxxxxx${mobile?.substr(8, 10)}.`);
            setErrorType('success');
            setIsLoading(false);
            setOtpSend(true);
            setVerificationId(response.data.verification_id);
          }
        }).catch((err) => {
          setErrorMessage(err.message ?? 'Oops!, OTP send failed.');
          setErrorType('failed');
          setIsLoading(false);
        })
      } catch (err) {
        setErrorMessage('Oops!, OTP send failed.');
        setErrorType('failed');
        setIsLoading(false);
      }
    }
  };

  const onSubmitOtp = async(e) => {
    e.preventDefault();
    setIsLoading(true);
    let hostName_endPoint = window.location.hostname !== 'localhost' ? OTP_VERIFY : OTP_VERIFY_LOCAL;
    const url = apiGateway + hostName_endPoint;
    let otpRequestBody = {
      phone_number: mobile,
      country_code: "+91",
      verification_id: verificationId,
      otp: otp
    }

    axiosPublic.post(url, otpRequestBody)
    .then((result) => {
        if(result?.status === 200){
          initializeVideos();
          if(process.env.NODE_ENV === 'development'){
            Cookies.set('access_token', result?.data?.access_token, { expires: 7 });
            Cookies.set('refresh_token', result?.data?.refresh_token, { expires: 7 });
          }
          Cookies.set('refresh_token_expiry_seekho', result?.data?.refresh_token_expiry, { expires: 7 });
          Cookies.set('access_token_expiry_seekho', result?.data?.access_token_expiry, { expires: 7 });
          Cookies.set('renew_access_token_before_seekho', result?.data?.renew_access_token_before, { expires: 7 });
          Cookies.set('renew_refresh_token_before_seekho', result?.data?.renew_refresh_token_before, { expires: 7 });
          fetchUserStartAsync(axiosPrivate, setErrorMessage, setErrorType, history, Cookies, setAuth, isUserInvited);
        }else{
          setErrorMessage(`Oops!, Something went wrong.`);
          setErrorType('failed');
        }
        setIsLoading(false);
    })
    .catch((error) => {
      setToastMessage({ type: "error", message:error?.response.data?.error_message || error?.message })
      if(error && error?.response && error?.response.data){
        setErrorMessage(error?.response.data?.error_message)
      }else{
        setErrorMessage(`Oops!, ${error?.error_message ??"Not able to verify otp"}.`);
      }
      setErrorType('failed');
      setIsLoading(false);
    });
  };

  const onChangePhone = (e) => {
    if(e.target.value?.length > 10){
      setError('Phone no is invalid')
    }else{
      setMobile(e.target.value);
      setError('')
    }
  }

  return (
    <div className="SW-login-form">
        <AlertComponent message={errorMessage} type={errorType} setAlertNotification={() => {
          setErrorMessage('');
          setErrorType('info');
        }} />
      <div className="SW-login-form-wrapper lg:w-[90%] mx-auto">
        <Form className="SW-login-inputcontrol">
            <div>
              <Form.Label className="SW-form-label">
                Enter phone number : 
              </Form.Label>
              <div className="phonecode" style={{border:`${inputFocus ? '1px solid #8277d4': '1px solid transparent'}`}}>
                <div>
                  +91
                </div>
                <input 
                  type="text" 
                  autoFocus
                  onFocus={() => setInputFocus(true)}
                  onKeyUpCapture={(e) => e.target.value = e.target.value.replace(/[^\d]/,'')}
                  className="mobile-input"
                  name="mobile"
                  minLength="10"
                  maxLength="10"
                  // disabled={otpSend}
                  placeholder="Enter mobile number"
                  onChange={(e) => onChangePhone(e)}
                  required/>
              </div>
              <Form.Text className="SW-text-muted mt-2" style={{float: "left"}}>
                We'll never share your number with anyone else*.
              </Form.Text>
            </div>
            {otpSend && <div>
              <Form.Label className="SW-form-label mt-3">
                Enter OTP sent to number :
              </Form.Label>
              <input 
                id="otp"
                className="otp-input"
                type="text"
                autoFocus={true}
                ref={inputOtp}
                onKeyUpCapture={(e) => e.target.value = e.target.value.replace(/[^\d]/,'')}
                minLength="6"
                maxLength="6"
                name="otp"
                placeholder="Enter 6 digit OTP"
                onChange={(e) => setOtp(e.target.value)}/>
            </div>}
            {error?.length > 0 && <p className="login-error-state">{error}</p>}
            {!otpSend ? <Button className="SW-CTA-button" variant="primary" type="submit" disabled={isLoading || !mobile} 
              onClick={(e)=> onSignInSubmit(e)}>
                Get OTP
            </Button> : 
              <Button className="SW-CTA-button" variant="primary" type="submit" disabled={isLoading || otp?.length !== 6} 
               onClick={(e)=> onSubmitOtp(e)}>
                Verify OTP
            </Button>
          }
        </Form>
      </div>
    </div>
  );
}


export default connect(null)(LoginForm);
