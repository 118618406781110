import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleSortingIconClick } from "../utils";

const handleShowTableHeadline = ({
  history,
  hasAccessToActions,
  hasAccessToCreatorTableField,
  isOnTopicsPage,
}) => {
  const search = history?.location?.search;
  const searchParams = new URLSearchParams(search);
  const currentSortingKey = searchParams.get("s");
  const currentSortBy = searchParams.get("order");

  const showTableHeading = [
    { id: 1, title: "Thumbnail/Banner", display: !isOnTopicsPage },
    { id: 2, title: "Title", display: true },
    { id: 3, title: "Creator", display: hasAccessToCreatorTableField },
    { id: 4, title: "Content Manager", display: true },
    { id: 5, title: "Total Videos", display: true },
    { id: 6, title: "Category", display: true },
    {
      id: 6.1,
      title: "Status",
      display: !isOnTopicsPage,
    },
    {
      id: 7,
      title: "Approved Topics",
      display: isOnTopicsPage,
      sortIcon:
        currentSortingKey === "approved" && currentSortBy === "asc" ? (
          <FontAwesomeIcon icon={faArrowUp} className="!text-[11px]" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="!text-[11px]" />
        ),
      onClick: () => {
        handleSortingIconClick({
          history,
          key: "approved",
          currentSortBy,
          currentSortingKey,
        });
      },
    },
    {
      id: 8,
      title: "Waiting for Approval",
      display: isOnTopicsPage,
      sortIcon:
        currentSortingKey === "wfa" && currentSortBy === "asc" ? (
          <FontAwesomeIcon icon={faArrowUp} className="!text-[11px]" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="!text-[11px]" />
        ),
      onClick: () => {
        handleSortingIconClick({
          history,
          key: "wfa",
          currentSortBy,
          currentSortingKey,
          defaultOrder: "asc",
        });
      },
    },
    { id: 9, title: "Actions", display: hasAccessToActions },
  ];

  return showTableHeading;
};

const showListingTabs = [
  {
    id: "ALL",
    key: "SHOWS",
    value: "all",
    index: 0,
    show: true,
  },
  {
    id: "DRAFT",
    key: "DRAFT",
    value: "draft",
    index: 1,
    show: true,
  },
  {
    id: "LIVE",
    key: "LIVE",
    value: "live",
    index: 2,
    show: true,
  },
  {
    id: "DELETED",
    key: "DELETED",
    value: "deleted",
    index: 3,
    show: true,
  },
];

const optionalInputFields = {
  frequency: "",
  time: "",
};

export { showListingTabs, optionalInputFields, handleShowTableHeadline };
