import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { errorState } from "../../utils/constants";
import { apiGateway } from "../../utils/config";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import { handleAppendQueryToUrl } from "../../utils/utils";
import { topicsListDefaultState } from "../../utils/constants/Topics";
import AddTopicsModal from "./AddTopicsModal";
import AlertComponent from "../Alert-Messages/alert-component.component";
import TopicsListingTable from "./TopicsListingTable";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TopicsListSkeleton from "../Skeletons/TopicsListSkeleton";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import useUserRoles from "../../hooks/useUserRoles";

const ShowsTopicsComponent = ({ match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openAddTopic, setOpenAddTopic] = useState(false);
  const [message, setMessage] = useState(errorState);
  const [topicsList, setTopicsList] = useState(topicsListDefaultState);
  const { internalTeamAccess, isCreator } = useUserRoles();
  const history = useHistory();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const showSlug = match?.params?.show_slug;
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const searchParamSortingKey = searchParams?.get("s") || "";
  const searchParamSortBy = searchParams?.get("order") || "";
  const hasAccessToCreateTopics = internalTeamAccess || isCreator;

  // function to get the list of topics
  const handleGetShowTopics = async ({ pageArg }) => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/cms/series-titles/?page=${pageArg}&show_slug=${showSlug}&page_size=20${
        searchParamSortingKey
          ? `&s=${searchParamSortingKey}&order=${searchParamSortBy}`
          : ""
      }`;
      const response = await axiosPrivate.get(url);
      const { data } = response;
      if (data) {
        const topicsList = data?.series_title_list;
        const nPages = data?.n_pages;
        const metaData = data?.show_meta_data;
        setIsLoading(false);
        setTopicsList({ list: topicsList, nPages, metaData });
      }
    } catch (error) {
      setIsLoading(false);
      setTopicsList(topicsListDefaultState);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Page change
  const handlePageChange = (e, value) => {
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  useEffect(() => {
    handleGetShowTopics({ pageArg: searchParamPage });
  }, [searchParamPage, searchParamSortBy, searchParamSortingKey]);

  return (
    <div>
      <div className="flex items-start justify-between">
        <div className="flex gap-x-3 items-center">
          <h6 className="!text-[16px] font-semibold text-black">
            Topics: {topicsList?.list?.[0]?.show?.title}
          </h6>
          <div className="text-[14px] text-gray-500">
            Frequency-{topicsList?.metaData?.frequency || "0"}
          </div>
          <div className="text-[14px] text-gray-500">
            {topicsList?.metaData?.show_time || "00:00"}
          </div>
        </div>
        {hasAccessToCreateTopics && (
          <button
            onClick={() => setOpenAddTopic(true)}
            className="px-3 py-2 text-[14px] border bg-baseBlueColor rounded-lg text-white flex gap-x-2 items-center"
          >
            <FontAwesomeIcon icon={faPlus} /> Add Topics
          </button>
        )}
      </div>

      <div
        id="ThinGrayScrollBar"
        className="!relative !overflow-x-auto h-[calc(100vh-150px)] rounded-md !bg-white pb-2 border-t mt-2"
      >
        {isLoading ? (
          <TopicsListSkeleton columns={6} rows={20} />
        ) : (
          <div>
            {topicsList?.list?.length ? (
              <div>
                <TopicsListingTable
                  showThumbAndTitleIcon
                  data={topicsList?.list}
                  setMessage={setMessage}
                  setTopicsList={setTopicsList}
                  handleGetTopics={() =>
                    handleGetShowTopics({ pageArg: searchParamPage })
                  }
                />
                {topicsList?.nPages > 1 && (
                  <div className="w-fit mx-auto pb-2">
                    <Pagination
                      count={topicsList?.nPages}
                      page={searchParamPage}
                      onChange={(e, val) => handlePageChange(e, val)}
                      shape="rounded"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="h-[calc(100vh-170px)] flex justify-center items-center">
                <NoFeedbackSvj
                  className="w-[300px] mx-auto"
                  displayText="No topics to display, Start adding!!"
                  displayTextClass="text-center text-[15px] text-gray-500"
                />
              </div>
            )}
          </div>
        )}
      </div>

      {openAddTopic && (
        <AddTopicsModal
          openModal={openAddTopic}
          showSlug={showSlug}
          setMessage={setMessage}
          handleGetUpdatedTopics={() =>
            handleGetShowTopics({ pageArg: searchParamPage })
          }
          handleClose={() => setOpenAddTopic(false)}
        />
      )}

      {/* Alert Toaster */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default withRouter(ShowsTopicsComponent);
