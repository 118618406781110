import create from "zustand";

const useZustandStore = create((set, get) => ({
  openProgressBarPopUp: false,
  showAddVideosModal: false,
  toastMessage: { message: "", type: "" },
  openCreateSeriesModal: false,
  uploads: [], // The uploading series will be stored
  showSideNavigationBar: true,
  openSessionExpiredModal: false,
  globalLoading: false,
  videoStartTime: 0,
  // Functions adds the uploading videos in []
  addUpload: (upload) =>
    set((state) => ({ uploads: [...state.uploads, upload] })),

  // Functions update the uploading videos like, uploading percentage
  updateUploadProgress: (id, progress) => {
    set((state) => ({
      uploads: state.uploads.map((upload) =>
        upload.id === id ? { ...upload, progress } : upload
      ),
    }));
  },

  // Function that cancels the uploaded video and removes from []
  cancelUpload: (id) => {
    const state = get();
    const upload = state.uploads.find((upload) => upload.id === id);
    if (upload && upload.cancelToken) {
      upload.cancelToken.cancel("Upload cancelled by user");
    }
    set((state) => ({
      uploads: state.uploads.map((upload) =>
        upload.id === id ? { ...upload, cancelled: true } : upload
      ),
    }));
  },

  removeUpload: (id) => {
    set((state) => ({
      uploads: state.uploads.filter((upload) => upload.id !== id),
    }));
  },

  setOpenProgressBarPopUp: (val) => set(() => ({ openProgressBarPopUp: val })),
  setShowAddVideosModal: (val) => set({ showAddVideosModal: val }),
  setToastMessage: (val) => set({ toastMessage: val }),
  setOpenCreateSeriesModal: (val) => set({ openCreateSeriesModal: val }),
  setShowSideNavigationBar: (val) =>
    set(() => ({ showSideNavigationBar: val })),
  setOpenSessionExpiredModal: (val) =>
    set(() => ({ openSessionExpiredModal: val })),
  setGlobalLoading: (val) => set(() => ({ globalLoading: val })),
  setVideoStartTime: (val) => set(() => ({ videoStartTime: val })),
}));

export default useZustandStore;
