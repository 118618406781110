/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchConfigStartAsync } from "./redux/config/config.action";
import { logout, setCurrentUser } from "./redux/user/user.action";
import { useSelector } from "react-redux";
import TagManager from "react-gtm-module";
import AlertComponent from "./components/Alert-Messages/alert-component.component";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import useZustandStore from "./zustandStore/useZustandStore";
import AllRoutes from "./Routes/AllRoutes";
import ProgressWithPopUp from "./components/Common/ProgressWithPopUp";

function App() {
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "" });
  const { openProgressBarPopUp, toastMessage, setToastMessage } =
    useZustandStore();
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    (async () => {
      if (currentUser) {
        if (!currentUser?.roles || !currentUser?.roles?.length) {
          logout(
            setCurrentUser,
            () => {},
            () => {},
            ""
          );
        }
        fetchConfigStartAsync(axiosPrivate, setErrorMsg);
      }
    })();
  }, [currentUser]);

  // GTM Initialization
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-TQKM823J",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="App">
      <AlertComponent
        message={errorMsg?.error || toastMessage?.message}
        type={errorMsg?.type || toastMessage?.type}
        setAlertNotification={() => {
          if (errorMsg?.error) {
            setErrorMsg({ type: "failed", error: "" });
          } else {
            setToastMessage({ type: "failed", message: "" });
          }
        }}
      />

      {/* Async Upload Pop up while creating videos */}
      {openProgressBarPopUp && <ProgressWithPopUp />}

      {/* Routes */}
      <AllRoutes />
    </div>
  );
}

export default App;
