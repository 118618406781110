import React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const CustomSearch = ({
  searchQuery,
  placeHolder,
  wrapperClass,
  className,
  setSearchQuery,
  handleInputKey,
  handleClearInput,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamSearchQuery = searchParams.get("query") || "";
  return (
    <div
      className={`flex items-center !bg-white rounded-full justify-between border px-2 ${wrapperClass}`}
    >
      <FontAwesomeIcon
        className="!text-[14px] text-gray-400 !font-normal ml-2"
        icon={faSearch}
      />
      <input
        value={searchQuery}
        placeholder={placeHolder}
        className={`!w-[95%] focus:outline-none rounded-r-full placeholder:text-[13px] text-[13px] py-[8px] px-2 ${className}`}
        onKeyPress={handleInputKey}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className="w-[20px]">
        {searchParamSearchQuery && (
          <button onClick={handleClearInput}>
            <CloseIcon className="!text-[16px] !font-bold -mt-1" />
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomSearch;
