import React, { useEffect, useState } from "react";
import { apiGateway } from "../../utils/config";
import { CREATE_CREATOR } from "../../utils/constants";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Pagination, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import { selectConfigFilters } from "../../redux/config/config.selector";
import {
  confirmationState,
  creatorsTabs,
  detailsDefaultState,
  handleAddCreatorMissingFields,
  incentiveDetailsState,
} from "../../utils/constants/Creators";
import {
  handleAllyProps,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
  handleSearchOnEnter,
} from "../../utils/utils";
import CustomSearch from "../Common/CustomSearch";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ListingTable from "./ListingTable";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import CreatorsListingSkeleton from "../Skeletons/CreatorsListingSkeleton";
import AddCreator from "./AddCreator";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../Common/CustomModal";
import AddOrEditIncentiveModal from "./AddOrEditIncentiveModal";
import ActionConfirmation from "./ActionConfirmation";
import useUserRoles from "../../hooks/useUserRoles";
import useZustandStore from "../../zustandStore/useZustandStore";

const CreatorsListing = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openAddCreatorModal, setOpenAddCreatorModal] = useState(false);
  const [addCreatorIsLoading, setAddCreatorIsLoading] = useState(false);
  const [openIncentiveModal, setOpenIncentiveModal] = useState(false);
  const [allCreators, setAllCreators] = useState({ creators: [], nPages: 0 });
  const [confirmationDetails, setConfirmationDetails] =
    useState(confirmationState);
  const [incentiveDetails, setIncentiveDetails] = useState(
    incentiveDetailsState
  );
  const { internalTeamAccess } = useUserRoles();
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const location = useLocation();
  const setToastMessage = useZustandStore((val) => val.setToastMessage);
  const searchParams = new URLSearchParams(location.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const searchParamSearchQuery = searchParams.get("query") || "";
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;
  const searchParamTabStateValue =
    searchParams?.get("state")?.split("-")[0] || "all";
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const globalLanguage = useSelector(
    (val) => val?.globalLanguage?.globalLanguage
  );
  const languageMapping = useSelector(selectConfigFilters)?.language_mapping;
  const languageSetter = {
    defaultLangValue: globalLanguage,
    defaultLangTitle: languageMapping?.[globalLanguage],
  };
  // prefilling the default global language
  const [creatorDetails, setCreatorDetails] = useState(
    detailsDefaultState(languageSetter) // function returns the default state oject for creator details
  );
  const hasAccessToCreate = internalTeamAccess;

  // Function to get all creators
  const handleGetAllCreators = async ({ pageArg, query }) => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/creator/list/?status=${searchParamTabStateValue}&page=${
        pageArg || pageNumber
      }${query ? `${`&q=${query}`}` : ""}&language=${globalLanguage}`;
      const response = await axiosPrivate.get(url);
      const { data } = response;
      if (data) {
        setIsLoading(false);
        setAllCreators({ creators: data?.creators, nPages: data?.n_pages });
      }
    } catch (error) {
      setAllCreators({ creators: [], nPages: 0 });
      setIsLoading(false);
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Triggers when searched query and hit enter key
  const handleInputKey = (ele) => {
    handleSearchOnEnter({ ele, searchQuery, history, setToastMessage });
  };

  // Triggers on tab change
  const handleChangeTabs = (event, newValue) => {
    let state = creatorsTabs.filter((d) => d.index === newValue)?.[0]?.value;
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
  };

  // Function to handle add creator api call
  const handleAddCreatorApiCall = async () => {
    try {
      setAddCreatorIsLoading(true);
      const url = `${apiGateway}${CREATE_CREATOR}`;
      const payload = {
        name: creatorDetails?.name,
        phone_number: creatorDetails?.mobileNumber,
        language: creatorDetails?.language?.value,
      };
      const response = await axiosPrivate.post(url, payload);
      if (response?.data) {
        setAddCreatorIsLoading(false);
        setOpenAddCreatorModal(false);
        handleGetAllCreators({
          pageArg: searchParamPage,
          query: searchParamSearchQuery,
        });
        setToastMessage({
          message: `Invite link sent to ${creatorDetails?.name} via WhatsApp`,
          type: "success",
        });
        setCreatorDetails({ name: "", mobileNumber: "" });
      }
    } catch (error) {
      setAddCreatorIsLoading(false);
      setToastMessage({
        message: error?.response?.data?.error_message || error?.message,
        type: "error",
      });
    }
  };

  // Function to handle add creator
  const handleAddCreator = () => {
    const missingFields = handleAddCreatorMissingFields({ creatorDetails });
    if (missingFields.length > 0) {
      setToastMessage({
        message: `Please enter the required fields: ${missingFields.join(
          ", "
        )}`,
        type: "error",
      });
    } else {
      handleAddCreatorApiCall();
    }
  };

  // Handle Approve or Disapprove API function
  const handleApproveOrDisApprove = async ({ creatorId, isApprove }) => {
    try {
      const url = `${apiGateway}/api/v1/admin/creator/${creatorId}/quality/`;
      const payload = { quality: isApprove };
      const response = await axiosPrivate.post(url, payload);
      const { data } = response;
      if (data) {
        setToastMessage({
          message: `${isApprove ? "Creator Approved" : "Creator Disapproved"}`,
          type: "success",
        });
        handleGetAllCreators({
          pageArg: searchParamPage,
          query: searchParamSearchQuery,
        });
      }
    } catch (error) {
      setToastMessage({
        message: error?.response?.data?.error_message || error?.message,
        type: "error",
      });
    }
  };

  // Function to close creator modal
  const handleCloseCreatorModal = () => {
    setCreatorDetails(detailsDefaultState(languageSetter));
    setOpenAddCreatorModal(false);
  };

  // when refreshed after search, the searched query will be set
  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  // Get all creators on change the dependencies
  useEffect(() => {
    handleGetAllCreators({
      pageArg: searchParamPage,
      query: searchParamSearchQuery,
    });
  }, [
    searchParamPage,
    searchParamSearchQuery,
    searchParamTabState,
    globalLanguage,
  ]);

  return (
    <div>
      {/* Create Icon */}
      {hasAccessToCreate && (
        <div
          className="cta-button-create"
          onClick={() => {
            setOpenAddCreatorModal(true);
            setCreatorDetails(detailsDefaultState(languageSetter));
          }}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Add Creator</span>
        </div>
      )}

      {/* Custom Search */}
      <div className="lg:w-[50%]">
        <CustomSearch
          placeHolder="Search creator name or Id"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleInputKey={handleInputKey}
          handleClearInput={() => {
            setSearchQuery("");
            handleDeleteQueryFromUrl({ history, queryNames: ["query"] });
          }}
          handleClickedOnSearchIcon={() => {
            handleGetAllCreators({
              query: searchParamSearchQuery,
              pageArg: searchParamPage,
            });
          }}
        />
      </div>

      <h6 className="!text-[20px] mt-3 font-medium">Creators</h6>

      {/* Will be required in the later stage */}
      <div className="mt-2">
        <Box>
          <Tabs
            value={searchParamTabState}
            onChange={handleChangeTabs}
            aria-label="basic tabs example"
            sx={{ minHeight: "37px", height: "37px" }} // Reduce tab container height
          >
            {creatorsTabs?.map((data, i) => (
              <Tab
                label={data?.id}
                {...handleAllyProps({ index: i })}
                key={i}
                className="!text-[12px]"
                sx={{
                  minHeight: "37px",
                  height: "37px",
                  padding: "4px 8px",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </div>

      {/* Creators Listing with Loading Skeleton, Not found UI & Pagination */}
      <div
        className="!relative h-[calc(100vh-219px)] !overflow-x-auto rounded-md !bg-white pb-4 border-t"
        id="ThinGrayScrollBar"
      >
        {isLoading ? (
          <CreatorsListingSkeleton />
        ) : (
          <div>
            {allCreators?.creators?.length === 0 ? (
              <div className="flex justify-center items-center h-screen">
                <NoFeedbackSvj
                  displayText="No Creators to display or Something went wrong!!"
                  className="w-[240px] mx-auto"
                  displayTextClass="text-[14px] text-gray-500 text-center"
                />
              </div>
            ) : (
              <div>
                <ListingTable
                  data={allCreators?.creators}
                  incentiveDetails={incentiveDetails}
                  setIncentiveDetails={setIncentiveDetails}
                  setOpenIncentiveModal={setOpenIncentiveModal}
                  setConfirmationDetails={setConfirmationDetails}
                  handleApproveOrDisApprove={handleApproveOrDisApprove}
                />
              </div>
            )}
          </div>
        )}
        {allCreators?.nPages > 1 && (
          <Pagination
            count={allCreators?.nPages}
            page={searchParamPage}
            onChange={(e, val) => {
              setPageNumber(val);
              handleAppendQueryToUrl({
                history,
                queryName: "page",
                queryValue: val,
              });
            }}
            shape="rounded"
            className="!w-fit !mx-auto mt-4"
          />
        )}
      </div>

      {/* Create/ Add creator Modal */}
      <CustomModal
        show={openAddCreatorModal}
        onHide={handleCloseCreatorModal}
        title="Add Creator"
        isLoading={addCreatorIsLoading}
        handleConfirmationBtnText="Add Creator"
        handleConfirmation={handleAddCreator}
      >
        <AddCreator
          creatorDetails={creatorDetails}
          setCreatorDetails={setCreatorDetails}
        />
      </CustomModal>

      {/* Add or Update Incentive Modal */}
      {openIncentiveModal && (
        <AddOrEditIncentiveModal
          openModal={openIncentiveModal}
          incentiveDetails={incentiveDetails}
          setIncentiveDetails={setIncentiveDetails}
          handleClose={() => setOpenIncentiveModal(false)}
          handleGetAllCreators={() => {
            handleGetAllCreators({
              pageArg: searchParamPage,
              query: searchParamSearchQuery,
            });
          }}
        />
      )}

      {/* In order to reduce the code in a single file, made a separate component to handle confirmations on actions  */}
      <ActionConfirmation
        confirmationDetails={confirmationDetails}
        setConfirmationDetails={setConfirmationDetails}
        handleGetAllCreators={() => {
          handleGetAllCreators({
            pageArg: searchParamPage,
            query: searchParamSearchQuery,
          });
        }}
      />
    </div>
  );
};

export default CreatorsListing;
