import React, { useEffect, useState } from "react";
import { Box, Pagination, Tab, Tabs } from "@mui/material";
import { GET_SHOWS_LIST_V1, GET_SHOW_ACTION_V1 } from "../../utils/constants";
import { apiGateway } from "../../utils/config";
import { useHistory, useLocation } from "react-router-dom";
import { showListingTabs } from "../../utils/constants/Shows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import {
  handleAddCategoryToApiRoute,
  handleAddManagerToApiRoute,
  handleAllyProps,
  handleAppendQueryToUrl,
  handleCommonApplyFilters,
  handleDeleteQueryFromUrl,
  handlePageChange,
  handleSearchOnEnter,
} from "../../utils/utils";
import AddIcon from "@mui/icons-material/Add";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CreateShow from "../Create-Show/create-show.component";
import CustomSearch from "../Common/CustomSearch";
import ShowListTable from "./ShowListTable";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import FilterButton from "../Common/FilterButton";
import CustomModal from "../Common/CustomModal";
import useAllQueryParams from "../../hooks/useAllQueryParams";
import FilterDrawer from "./FilterDrawer";
import useUserRoles from "../../hooks/useUserRoles";
import FilterQueryChips from "../Common/FilterQueryChips";
import useZustandStore from "../../zustandStore/useZustandStore";

export default function ShowsList() {
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDialogCreate, setIsDialogCreate] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [dialogStatus, setDialogStatus] = useState({ open: false });
  const [selectedShow, setSelectShow] = useState({});
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    category: [],
    manager: [],
  });
  const {
    isEditor,
    internalTeamAccess,
    internalTeamAccessWithViewer,
    isDesigner,
    hasAccessToLanguage,
  } = useUserRoles();
  const axiosPrivate = useAxiosPrivate();
  const setToastMessage = useZustandStore((state) => state?.setToastMessage);
  const globalLoading = useZustandStore((state) => state?.globalLoading);
  const setGlobalLoading = useZustandStore((state) => state?.setGlobalLoading);
  const history = useHistory();
  const location = useLocation();
  // globalLanguage selected via navbar menu
  const globalLanguage = useSelector(
    (state) => state?.globalLanguage?.globalLanguage
  );
  const allQueryParams = useAllQueryParams({
    excludedParams: ["state", "page"],
  });
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;
  const searchParamSearchQuery = searchParams?.get("query") || "";
  const searchParamCategoryFilter = searchParams?.getAll("category") || [];
  const searchParamManagerFilter = searchParams?.getAll("manager") || [];
  const hasAccessToActions = internalTeamAccess;
  const hasAccessToCreate = internalTeamAccess;
  const hasAccessToCreatorTableField = internalTeamAccessWithViewer;

  // API function call to get Shows
  const handleGetAllShows = async ({
    currentActiveTab = null,
    query,
    pageArg,
  }) => {
    setGlobalLoading(true);
    try {
      let state = showListingTabs.filter(
        (d) => d.index === currentActiveTab
      )?.[0]?.value;

      const categoryParams = handleAddCategoryToApiRoute({
        searchParamCategoryFilter,
      });

      const managerParams = handleAddManagerToApiRoute({
        searchParamManagerFilter,
      });

      let url = `${apiGateway}${GET_SHOWS_LIST_V1}/?page=${
        pageArg || pageNumber
      }&state=${state}&q=${query}${
        hasAccessToLanguage ? `&language=${globalLanguage}` : ""
      }`;

      // Add category if they exist
      if (categoryParams) url += `&${categoryParams}`;
      if (managerParams) url += `&${managerParams}`;

      const response = await axiosPrivate.get(url);
      const { data, status } = response;
      if (
        status !== 200 ||
        (data?.error_message && Object.keys(data?.error_message).length > 0)
      ) {
        setToastMessage({
          type: "failed",
          message: data?.error_message ?? "Something went wrong",
        });
      } else {
        setData(data?.show_list);
        setPageCount(data?.n_pages);
        setGlobalLoading(false);
      }
    } catch (error) {
      setData([]);
      setToastMessage({
        type: "error",
        message: error?.response?.data?.error_message || error?.message,
      });
      setGlobalLoading(false);
    }
  };

  // Tab Change
  const handleChangeTabs = (event, newValue) => {
    let state = showListingTabs.filter((d) => d.index === newValue)?.[0]?.value;
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
  };

  const toggleStatus = (show_slug, status) => {
    let formData = new FormData();
    formData.append("show_state", status);
    let url = `${apiGateway}${GET_SHOW_ACTION_V1}/${show_slug}/update-state/`;
    setGlobalLoading(true);
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        if (status === false) {
          setToastMessage({
            type: "failed",
            message: data?.error_message ?? "Something went wrong",
          });
        } else {
          setGlobalLoading(false);
          handleGetAllShows({
            currentActiveTab: searchParamTabState,
            pageArg: 1,
            query: searchParamSearchQuery,
          });
          setToastMessage({
            type: "success",
            message: "Show Status Changed!!!",
          });
        }
      })
      .catch(({ response }) => {
        if (response?.status === 404) {
          setToastMessage({ type: "failed", message: "Url not found" });
        } else {
          setToastMessage({
            type: "error",
            message: response?.data?.error_message || response?.data?.message,
          });
        }
        setGlobalLoading(false);
      });
    setSelectShow({});
    setDialogStatus({ open: false });
  };

  const toggleCreate = (e, show_created) => {
    if (isDialogCreate && show_created) {
      handleGetAllShows({
        currentActiveTab: searchParamTabState,
        pageArg: 1,
        query: searchParamSearchQuery,
      });
    }
    setIsDialogCreate((prev) => !prev);
  };

  // On hit enter
  const handleInputKey = (ele) => {
    handleSearchOnEnter({
      ele,
      history,
      searchQuery,
      setToastMessage,
    });
  };

  // On click of clear CTA in the input
  const handleClearInput = () => {
    setSearchQuery("");
    handleDeleteQueryFromUrl({ history, queryNames: ["query"] });
  };

  // When clicked on Filter button
  const handleOnClickOfFilterButton = () => {
    setOpenFilterDrawer((prev) => !prev);
    setSelectedFilters({
      category: [...searchParamCategoryFilter],
      manager: [...searchParamManagerFilter],
    });
  };

  // Toggle Show to move to live / move to draft
  const handleToggleShowLiveOrDraft = ({ item }) => {
    setSelectShow(item);
    setDialogStatus({
      open: true,
      type: item?.status === "draft" ? "live" : "draft",
      msg:
        item?.status === "draft"
          ? "Make this show live"
          : "Move this show to draft",
    });
  };

  // Delete show Modal
  const handleDeleteShow = ({ item }) => {
    setSelectShow(item);
    setDialogStatus({
      open: true,
      type: "deleted",
      msg: "Delete this show",
    });
  };

  // sets search query in state on refresh
  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  // API call on every dependency change
  useEffect(() => {
    handleGetAllShows({
      currentActiveTab: searchParamTabState,
      query: searchQuery || searchParamSearchQuery,
      pageArg: searchParamPage,
    });
  }, [
    searchParamSearchQuery,
    searchParamTabState,
    searchParamPage,
    globalLanguage,
    JSON.stringify(searchParamCategoryFilter),
    JSON.stringify(searchParamManagerFilter),
  ]);

  return (
    <div>
      {hasAccessToCreate && (
        <div
          className="cta-button-create"
          onClick={(e) => toggleCreate(e, false)}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Create Show</span>
        </div>
      )}

      {/* Search & Filter */}
      <div className="flex gap-x-4">
        <div className="w-[70%] lg:!w-[50%]">
          <CustomSearch
            searchQuery={searchQuery}
            placeHolder="Search show name or Id"
            handleInputKey={handleInputKey}
            setSearchQuery={setSearchQuery}
            handleClearInput={handleClearInput}
          />
        </div>

        <FilterButton onClick={handleOnClickOfFilterButton} />

        {(internalTeamAccessWithViewer || isDesigner) && (
          <a href="/#/topics" className="flex hover:no-underline">
            <button className="text-[12px] border px-3 rounded-lg text-gray-500 font-bold">
              <FontAwesomeIcon icon={faTags} /> TOPICS
            </button>
          </a>
        )}
      </div>

      <h6 className="!text-[20px] mt-3 font-medium">Shows</h6>

      {/* Search / filter display chips */}
      <div>
        <FilterQueryChips
          allQueryParams={allQueryParams}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <div id="HideScroll" className="mt-2">
        <div>
          <div className="card-panel creator-table">
            <Box>
              <Tabs
                value={searchParamTabState}
                onChange={handleChangeTabs}
                aria-label="basic tabs example"
                sx={{
                  minHeight: 35,
                  height: 35,
                }}
              >
                {showListingTabs?.map((data, index) => (
                  <Tab
                    label={data?.id}
                    {...handleAllyProps({ index })}
                    key={index}
                    sx={{
                      minHeight: 35,
                      height: 35,
                    }}
                  />
                ))}
              </Tabs>
            </Box>

            {/* Show listing with Pagination & No data state */}
            <div
              id="ThinGrayScrollBar"
              className="!relative !overflow-x-auto h-[calc(100vh-225px)] rounded-md !bg-white pb-2 border-t"
            >
              {!globalLoading && data?.length === 0 ? (
                <div className="h-full flex justify-center items-center">
                  <NoFeedbackSvj
                    displayText="No shows to display"
                    displayTextClass="mt-2 text-gray-400 text-[14px] text-center"
                    className="w-[250px] mx-auto flex justify-center items-center"
                  />
                </div>
              ) : (
                <div>
                  <ShowListTable
                    data={data}
                    isEditor={isEditor}
                    hasAccessToActions={hasAccessToActions}
                    handleDeleteShow={handleDeleteShow}
                    handleToggleShowLiveOrDraft={handleToggleShowLiveOrDraft}
                    hasAccessToCreatorTableField={hasAccessToCreatorTableField}
                  />

                  {pageCount > 1 && (
                    <div className="w-fit mx-auto pb-2">
                      <Pagination
                        count={pageCount}
                        page={searchParamPage || pageNumber}
                        onChange={(_, value) =>
                          handlePageChange({ value, history, setPageNumber })
                        }
                        shape="rounded"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*  Filter Drawer */}
      {openFilterDrawer && (
        <FilterDrawer
          openFilterDrawer={openFilterDrawer}
          selectedFilters={selectedFilters}
          showContentManagerFilter={true}
          setOpenFilterDrawer={setOpenFilterDrawer}
          setSelectedFilters={setSelectedFilters}
          handleApplyFilters={() => {
            handleCommonApplyFilters({
              history,
              selectedFilters,
              setOpenFilterDrawer,
            });
          }}
        />
      )}

      {/* Confirmation Modal To Make show live / draft / delete */}
      <CustomModal
        show={dialogStatus?.open}
        title={selectedShow?.title}
        handleConfirmationBtnText="Yes"
        isLoading={globalLoading}
        handleConfirmation={() => {
          toggleStatus(selectedShow?.slug, dialogStatus?.type);
        }}
        onHide={() => {
          setDialogStatus({ open: false });
          setSelectShow({});
        }}
      >
        <div>Are your sure you want to proceed with changes?</div>
      </CustomModal>

      {/* CREATE FORM MODAL */}
      <CustomModal
        title="Create show"
        key="create-show-modal"
        className="lg:w-[33%]"
        show={isDialogCreate}
        isButtonsRequired={false}
        onHide={toggleCreate}
      >
        <CreateShow toggleCreate={toggleCreate} />
      </CustomModal>
    </div>
  );
}
