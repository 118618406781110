import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Chip,
    IconButton,
    OutlinedInput,
    Paper,
    Switch,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./track.styles.css";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Button, Form, Modal } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { apiGateway } from "../../utils/config";
import {  Add, CloseOutlined, ExpandMore } from "@mui/icons-material";
import { CATEGORY_CMS, CATEGORY_CMS_ALL, EDIT_TACK_WITH_CSV, EDIT_TRACK, EDIT_TRACK_ACTION, EDIT_TRACK_EXPERIMENT, GET_TRACK_BY_ID, HOME_TOP_BAR, TRACK_VALIDATIONS } from "../../utils/constants";
import LoadingSpinner from "../Common/LoadingSpinner";
import CustomModal from "../Common/CustomModal";
import { stepperData, variantGroupsDefaultState } from "../../utils/constants/Tracks";
import useUserRoles from "../../hooks/useUserRoles";
import RequiredStar from "../Common/RequiredStar";
import SelectAppPackage from "../Common/SelectAppPackage";
import useZustandStore from "../../zustandStore/useZustandStore";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
    >
        {value === index && (
            <div sx={{}}>
                <Typography>{children}</Typography>
            </div>
        )}
    </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

// the commented code might be required later
export default function TrackEdit({ history, match }) {
    //dispatch
    const axiosPrivate = useAxiosPrivate();
    const iconRef = useRef();
    const track_id = match.params?.track_id;
    //states
    const [errorMsg, setErrorMsg] = useState({ error: "", type: "" })
    const [showDialog, setShowDialog] = useState(null);
    const [replaceCategory, setReplaceCategory] = useState('');
    const [track_title, setTrackTitle] = useState(null)
    const [creativeUri, SetCreativeUri] = useState([''])
    const [isStepLoading, setIsLoading] = useState(false)
    const [isNewCategoryAdd, setIsNewCategoryAdd] = useState(false)
    const [categoryExperiment, setCategoryExperiment] = useState('')
    const [categoryName, setCategoryName] = useState('')
    const [categoryIcon, setCategoryIcon] = useState('')
    const [activeStep, setActiveStep] = useState(0)
    const [onboardingSeriesIds, setOnboardingSeriesIds] = useState([])
    const [onboardingTitle, setOnboardingTitle] = useState('')
    const [score, setScore] = useState(1)
    const [isOnboarding, setIsOnboarding] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [topNavbarOriginal, setTopNavbar] = useState([]);
    const [defaultTopNavbarOriginal, setDefaultTopNavbar] = useState([]);
    const [categoriesOriginal, setCategoriesOriginal] = useState([]);
    const [showsOriginal, setShowsOriginal] = useState([]);
    const [replaceCategoryNew, setReplaceCategoryNew] = useState({})
    const [saveDefaultCategories, setDefaultCategories] = useState([])
    const [isVariantSelected, setIsVariantSelected] = useState(false)
    const [variantModel, setVariantModel] = useState(false)
    const [selectedVariant, setSelectVariant] = useState(0)
    const [variantModelRevert, setVariantModelRevert] = useState(false)
    const [creativeUriOriginal, setCreativeUriOriginal] = useState([])
    const [isExperimentActive, setIsExperimentActive] = useState(false)
    const [status, setStatus] = useState(true)
    const [startDate, setStartDate] = useState(null)
    const [trackId, setTrackId] = useState(null)
    const [isEdit, setIsEdit] = useState(true);
    const [isToggleDialog, setIsToggleDialog] = useState(false);
    const [isStatusDialog, setIsStatusDialog] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [ isCsvLoading, setIsCsvLoading ] = useState(false);
    const [uploadCsvConfirmation,setUploadCsvConfirmation] = useState(false);
    const [csvFile,setCsvFile] = useState(null);
    const { internalTeamAccess } = useUserRoles();
    const [currentCategorySelected, setCurrentCategorySelected] = useState({
        "control": '',
        "experiment": ''
    })
    const [currentShowSelected, setCurrentShowSelected] = useState({ "control": '',
    "experiment": ''});
    const [variantGroups, setVariantGroups] = useState(variantGroupsDefaultState)
    const [stepper, setStepper] = useState(stepperData);  
    const [selectApp, setSelectApp] = useState("");
    const setGlobalLoading = useZustandStore((state) => state.setGlobalLoading);

    useEffect(() => {
        if(track_id){
            get_all_category()
            setTrackId(track_id)
            setIsEdit(true)
            handleGetAllShows();
        }
    }, [track_id])

    const get_track_by_id = (track_id, defaultTopbar, defaultCat) => {
        setIsLoading(true);
        setGlobalLoading(true);
        let url = `${apiGateway}${GET_TRACK_BY_ID}${track_id}/`;
        setIsLoading(true);
        axiosPrivate.get(url)
        .then(({data, status}) => {
            if(data?.status !== 'success'){
                setErrorMsg({ error: data?.error_message ?? "Something went wrong", type: 'failed'})
            }else{
                let track_details = data?.track_details
                setSelectApp(track_details?.app_name);
                setIsLoading(false);
                setGlobalLoading(false)
                setTrackTitle(track_details?.title)
                SetCreativeUri(track_details?.campaigns?.map(d => d?.code))
                setScore(track_details?.score)
                let onboarding_series_ids = track_details?.onboarding_series_ids ? track_details?.onboarding_series_ids?.join(",") : ''
                setOnboardingSeriesIds(onboarding_series_ids)
                setIsOnboarding(track_details?.onboarding_status);
                setIsSuccessful(track_details?.is_successful );
                setOnboardingTitle(track_details?.onboarding_title ? track_details?.onboarding_title : '')
                setCreativeUriOriginal([...track_details?.campaigns?.map(d => d.code)])
                setIsExperimentActive(track_details?.is_experiment)
                setStartDate(track_details?.created_on)
                setStatus(track_details?.is_active)
                setIsDefault(track_details?.is_default)
                let selected = {};
                let showsSelected = {};
                variantGroups?.forEach((it) => {
                    try{
                        let category_page_curation = track_details[it?.value]?.category_page_curation;
                        let shows_page_curation = track_details[it?.value]?.shows_page_curation
                        let home_tab_stories_series = track_details[it?.value]?.home_tab_stories_series;
                        let is_home_tab_stories_series = Object.keys(home_tab_stories_series || {})?.length > 0;
                        let is_home_trending_series = Object.keys(track_details[it.value]?.home_trending_series || {})?.length > 0;
                        let is_home_top10_series = Object.keys(track_details[it.value]?.home_top_10_series || {})?.length > 0;
                        let is_new_Tab = Object.keys(track_details[it.value]?.new_series || {})?.length > 0;
                        let is_category_ordering = track_details[it.value]?.category_ordering?.length > 0;
                        let is_home_Tab = track_details[it.value]?.home_tab_ordering?.length > 0;
                        let is_category_home_tab_series_map = Object.keys(track_details[it.value]?.home_tab_category_top_10_series || {})?.length > 0;
                        let is_category_all_series_ids = track_details[it.value]?.category_page_curation?.category_all_series?.length > 0;
                        // let is_shows_all_series_ids = track_details[it?.value]?.shows_page_curation?.shows_all_series?.length > 0;
                        let is_top_10_series_list = track_details[it.value]?.category_page_curation?.top_10_series?.length > 0;
                        let is_trending_series_list = track_details[it.value]?.category_page_curation?.trending_series?.length > 0;
                        let is_dfs_series_list = track_details[it.value]?.shows_page_curation?.dfs_series?.length > 0

                        //  || is_shows_all_series_ids
                        if((is_home_trending_series || is_home_top10_series || is_category_ordering || is_home_Tab || is_new_Tab || 
                            is_category_home_tab_series_map || is_category_all_series_ids || is_top_10_series_list || is_trending_series_list || is_dfs_series_list || is_home_tab_stories_series) 
                            && it?.value === 'experiment'){
                            setIsVariantSelected(true);
                        }
                        let highestDfs = Math.max(...Object.keys(track_details[it.value]?.home_trending_series || {}).map(d => parseInt(d)))                        
                        if(highestDfs != -Infinity){
                            const home_trending_series = Array(highestDfs+1).fill({})?.map((obj, index) => ({
                                id: index+1,
                                dfs: index,
                                series_ids: track_details[it.value]?.home_trending_series[index] ? track_details[it.value]?.home_trending_series[index]?.join(',') : ''
                                }
                            ))
                            it.homeTrendingSeriesIds = home_trending_series
                        }
                        let top10HighestDfs = Math.max(...Object.keys(track_details[it.value]?.home_top_10_series || {}).map(d => parseInt(d)))                        
                        if(top10HighestDfs != -Infinity){
                            const home_top_10_series = Array(top10HighestDfs+1).fill({})?.map((obj, index) => ({
                                id: index+1,
                                dfs: index,
                                series_ids: track_details[it.value]?.home_top_10_series[index] ? track_details[it.value]?.home_top_10_series[index]?.join(',') : ''
                                }
                            ))
                            it.homeTop10SeriesIds = home_top_10_series
                        }
                        let newTabHighestDfs = Math.max(...Object.keys(track_details[it.value]?.new_series || {}).map(d => parseInt(d)))                        
                        if(newTabHighestDfs != -Infinity){
                            const new_tab_series = Array(newTabHighestDfs+1).fill({})?.map((obj, index) => ({
                                id: index+1,
                                dfs: index,
                                series_ids: track_details[it.value]?.new_series[index] ? track_details[it.value]?.new_series[index]?.join(',') : ''
                                }
                            ))
                            it.newSeriesIds = new_tab_series
                        }
                        const category_ordering = track_details[it.value]?.category_ordering?.map(obj => obj?.category);
                        it.categories = category_ordering?.length > 0 ? category_ordering : defaultCat
                        it.categoryAllSeriesIds = []
                        let hometop_ordering = []
                        if(track_details[it.value]?.home_tab_ordering?.length > 0){
                            track_details[it?.value]?.home_tab_ordering?.forEach(obj => {
                                let is_stories_series = Object.keys(home_tab_stories_series?.[obj?.category?.id] || {})?.length > 0;
                                let stories_series = []
                            
                                if(is_stories_series){
                                    let highestDfs = Math.max(...Object.keys(home_tab_stories_series?.[obj?.category?.id] || {}).map(d => parseInt(d)))                        
                                    if(highestDfs != -Infinity){
                                        let home_stories_series = home_tab_stories_series[obj?.category?.id]
                                        stories_series = Array(highestDfs+1).fill({})?.map((obj, index) => ({
                                            id: index+1,
                                            dfs: index,
                                            series_ids: home_stories_series?.[index]?.length > 0 ? home_stories_series[index]?.join(',') : ''
                                            }
                                        ))
                                    }
                                }
                                
                                hometop_ordering.push({
                                    ...obj.category,
                                    row_series: is_stories_series ? stories_series : []
                                })
                            });
                            it.defaultTopNavbar = track_details[it.value]?.home_tab_ordering?.map(obj => obj?.category);
                        }else{
                            hometop_ordering = [...defaultTopbar]
                            it.defaultTopNavbar = defaultTopbar
                        }
                        it.topNavbar = hometop_ordering
                        let category_page_curation_list = []
                        for (const [key, values] of Object.entries(category_page_curation || {})) {
                            let data = {}
                            data['custom_rows'] = []
                            data['is_removed'] = false
                            data['category'] = parseInt(key)
                            data['categoryAllSeries'] = values?.category_all_series?.series_ids?.length > 0 ? values?.category_all_series?.series_ids?.join(",") : ''
                            let top10HighestDfs = Math.max(...Object.keys(values?.top_10_series?.dfs_series || {}).map(d => parseInt(d))) 
                            let trendingHighestDfs = Math.max(...Object.keys(values?.trending_series?.dfs_series || {}).map(d => parseInt(d))) 
                            if(top10HighestDfs != -Infinity){
                                const top10 = Array(top10HighestDfs+1).fill({}).map((obj, index) => (
                                    {
                                    id: index+1,
                                    dfs: index,
                                    series_ids: values?.top_10_series?.dfs_series && values?.top_10_series?.dfs_series[index]?.length > 0 ? values?.top_10_series?.dfs_series[index]?.join(',') : ''
                                    }
                                ))
                                data['custom_rows'].push({
                                    title: '',
                                    is_title: false,
                                    row_series: top10,
                                    is_dfs: true,
                                    heading:"Category top 10 series Ids",
                                    id: 2,
                                    is_removed: false,
                                    type: 'top10',
                                    rank: values?.top_10_series?.sequence_no == -1 ? 40 : values?.top_10_series?.sequence_no
                                })
                            }else{
                                data['custom_rows'].push({
                                    title: '',
                                    is_title: false,
                                    row_series: [{id: 1, dfs: 0, series_ids: []}],
                                    is_dfs: true,
                                    heading:"Category top 10 series Ids",
                                    id: 2,
                                    is_removed: false,
                                    type: 'top10',
                                    rank: trendingHighestDfs ? 25 : values?.trending_series?.sequence_no 
                                })
                            }
                            if(trendingHighestDfs != -Infinity){
                                const trending = Array(trendingHighestDfs+1).fill({}).map((obj, index) => (
                                    {
                                    id: index+1,
                                    dfs: index,
                                    series_ids: values?.trending_series?.dfs_series && values?.trending_series?.dfs_series[index]?.length > 0 ? values?.trending_series?.dfs_series[index]?.join(',') : ''
                                    }
                                ))
                                data['custom_rows'].push({
                                    title: '',
                                    is_title: false,
                                    row_series: trending,
                                    is_dfs: true,
                                    heading: "Category trending series Ids* (DFS 0 ~ 7)",
                                    id: 1,
                                    is_removed: false,
                                    type: 'trending',
                                    rank: values?.trending_series?.sequence_no == -1 ? 20 : values?.trending_series?.sequence_no
                                })
                            }
                            else{
                                data['custom_rows'].push({
                                    title: '',
                                    is_title: false,
                                    row_series: [{id: 1, dfs: 0, series_ids: []}],
                                    is_dfs: true,
                                    heading: "Category trending series Ids* (DFS 0 ~ 7)",
                                    id: 1,
                                    is_removed: false,
                                    type: 'trending',
                                    rank: top10HighestDfs ? 20 : values?.top_10_series?.sequence_no 
                                })
                            }

                            if(values?.custom_rows_series?.length > 0){
                                values?.custom_rows_series.forEach((d, ind) => {
                                    let customHighestDfs = Math.max(...Object.keys(d?.dfs_series || {}).map(d => parseInt(d)))
                                    if(customHighestDfs != -Infinity){
                                        const dfs_series = Array(customHighestDfs+1).fill({}).map((obj, index) => (
                                            {
                                            id: index+1,
                                            dfs: index,
                                            series_ids: d?.dfs_series && d?.dfs_series[index]?.length > 0 ? d?.dfs_series[index]?.join(',') : ''
                                            }
                                        ))
                                        data['custom_rows'].push({
                                            title: d.title,
                                            is_title: true,
                                            entity_id: d.entity_id,
                                            row_series: dfs_series,
                                            is_dfs: true,
                                            heading: "",
                                            id: ind+3,
                                            is_removed: false,
                                            type: 'custom',
                                            rank:  d?.sequence_no
                                        })
                                    }
                                })
                            }
                            data['custom_rows'].sort((a,b) => parseInt(a.rank) - parseInt(b.rank))
                            category_page_curation_list.push(data);
                        }
                        if(it.categories?.length > 0){
                            let idIndexMap = {};
                            it.categories.forEach((item, index) => {
                                idIndexMap[item.id] = index;
                            });
                            // Sort array a based on the order specified in array b
                            category_page_curation_list = category_page_curation_list.sort((x, y) => idIndexMap[x.category] - idIndexMap[y.category]);
                            selected[it.value] = parseInt(category_page_curation_list?.length > 0 ? category_page_curation_list[0].category : 0)
                        }
                        it.categoryAllCuration = [...category_page_curation_list]
                        it.originalCuration = [...category_page_curation_list]

                        //
                        let shows_page_curation_list = []
                        for (const [key, values] of Object.entries(shows_page_curation || {})) {
                            let data = {}
                            data['custom_rows'] = []
                            data['is_removed'] = false
                            data['show'] = parseInt(key)
                            data['showsAllSeries'] = values?.shows_all_series?.series_ids?.length > 0 ? values?.shows_all_series?.series_ids?.join(",") : ''
                            let top10HighestDfs = Math.max(...Object.keys(values?.top_10_series?.dfs_series || {}).map(d => parseInt(d))) 
                            let trendingHighestDfs = Math.max(...Object.keys(values?.dfs_series || {}).map(d => parseInt(d))) 
                            // if(top10HighestDfs != -Infinity){
                            //     const top10 = Array(top10HighestDfs+1).fill({}).map((obj, index) => (
                            //         {
                            //         id: index+1,
                            //         dfs: index,
                            //         series_ids: values?.top_10_series?.dfs_series && values?.top_10_series?.dfs_series[index]?.length > 0 ? values?.top_10_series?.dfs_series[index]?.join(',') : ''
                            //         }
                            //     ))
                            //     data['custom_rows'].push({
                            //         title: '',
                            //         is_title: false,
                            //         row_series: top10,
                            //         is_dfs: true,
                            //         heading:"Shows top 10 series Ids",
                            //         id: 2,
                            //         is_removed: false,
                            //         type: 'top10',
                            //         rank: values?.top_10_series?.sequence_no == -1 ? 40 : values?.top_10_series?.sequence_no
                            //     })
                            // }else{
                            //     data['custom_rows'].push({
                            //         title: '',
                            //         is_title: false,
                            //         row_series: [{id: 1, dfs: 0, series_ids: []}],
                            //         is_dfs: true,
                            //         heading:"Shows top 10 series Ids",
                            //         id: 2,
                            //         is_removed: false,
                            //         type: 'top10',
                            //         rank: trendingHighestDfs ? 25 : values?.trending_series?.sequence_no 
                            //     })
                            // }
                            if(trendingHighestDfs != -Infinity){
                                const trending = Array(trendingHighestDfs+1).fill({}).map((obj, index) => (
                                    {
                                    id: index+1,
                                    dfs: index,
                                    series_ids: values?.dfs_series && values?.dfs_series[index]?.length > 0 ? values?.dfs_series[index]?.join(',') : ''
                                    }
                                ))
                                data['custom_rows'].push({
                                    title: '',
                                    is_title: false,
                                    row_series: trending,
                                    is_dfs: true,
                                    heading: "Shows trending series Ids* (DFS 0 ~ 7)",
                                    id: 1,
                                    is_removed: false,
                                    type: 'trending',
                                    rank: values?.sequence_no == -1 ? 20 : values?.sequence_no
                                })
                            }
                            else{
                                data['custom_rows'].push({
                                    title: '',
                                    is_title: false,
                                    row_series: [{id: 1, dfs: 0, series_ids: []}],
                                    is_dfs: true,
                                    heading: "Shows trending series Ids* (DFS 0 ~ 7)",
                                    id: 1,
                                    is_removed: false,
                                    type: 'trending',
                                    rank: top10HighestDfs ? 20 : values?.top_10_series?.sequence_no 
                                })
                            }

                            if(values?.custom_rows_series?.length > 0){
                                values?.custom_rows_series.forEach((d, ind) => {
                                    let customHighestDfs = Math.max(...Object.keys(d?.dfs_series || {}).map(d => parseInt(d)))
                                    if(customHighestDfs != -Infinity){
                                        const dfs_series = Array(customHighestDfs+1).fill({}).map((obj, index) => (
                                            {
                                            id: index+1,
                                            dfs: index,
                                            series_ids: d?.dfs_series && d?.dfs_series[index]?.length > 0 ? d?.dfs_series[index]?.join(',') : ''
                                            }
                                        ))
                                        data['custom_rows'].push({
                                            title: d.title,
                                            is_title: true,
                                            entity_id: d.entity_id,
                                            row_series: dfs_series,
                                            is_dfs: true,
                                            heading: "",
                                            id: ind+3,
                                            is_removed: false,
                                            type: 'custom',
                                            rank:  d?.sequence_no
                                        })
                                    }
                                })
                            }
                            data['custom_rows'].sort((a,b) => parseInt(a.rank) - parseInt(b.rank))
                            shows_page_curation_list.push(data);
                        }
                        if(it.shows?.length > 0){
                            let idIndexMap = {};
                            it.shows.forEach((item, index) => {
                                idIndexMap[item.id] = index;
                            });
                            // Sort array a based on the order specified in array b
                            shows_page_curation_list = shows_page_curation_list.sort((x, y) => idIndexMap[x.show] - idIndexMap[y.show]);
                            showsSelected[it.value] = parseInt(shows_page_curation_list?.length > 0 ? shows_page_curation_list[0].show : 0)
                        }
                        it.showsAllCuration = [...shows_page_curation_list]
                        it.originalCuration = [...shows_page_curation_list]
                    }
                    catch (error) {
                        setErrorMsg({ error: "Unable to map track data in UI", type: 'failed'})
                    }
                })
                setCurrentCategorySelected(selected)
                setCurrentShowSelected(showsSelected)
                setVariantGroups(pre => [...variantGroups])
                
                stepper.forEach((step) => {
                    step.isValid.control = true
                    step.isValid.experiment = true
                })
                setStepper(prev => [...stepper])
            }
        }).catch(({response}) => {
            setErrorMsg({ error: response?.data ? response?.data?.error_message : "Something went wrong", type: 'failed'})
            setIsLoading(false);
            setGlobalLoading(false);
        })
    }

    const get_all_category = () => {
        let url = `${apiGateway}${CATEGORY_CMS_ALL}?page=1&page_size=500&default_only=true`;
        axiosPrivate.get(url)
        .then(({data, status}) => {
            if(data?.error_message && Object.keys(data?.error_message).length > 0){
                setErrorMsg({error: data?.error_message ?? "Unable to fetch category api", type: "failed"})
            }else{
                setCategoriesOriginal(prev => [...data?.categories]);
                get_top_bar(data?.categories);
            }
        }).catch(({response}) => {
            setErrorMsg({error: response?.data ? response?.data?.error_message : "Unable to fetch category api", type: "failed"})
        })
    }

    const handleGetAllShows = async () => {
        try {
            const url = `${apiGateway}/api/v1/cms/shows/?page=1&page_size=500&state=live`;
            const response = await axiosPrivate.get(url);
            const data = response?.data;
            if(data){
                variantGroups.forEach(it => {
                    it.shows = data?.show_list
                });
                setCurrentShowSelected({
                    "control": ""
                });
                setVariantGroups(prev => [...variantGroups]);
                setShowsOriginal(data?.show_list)
            }
        } catch ({response}) {
            setErrorMsg({error: response?.data ? response?.data?.error_message : "Unable to fetch shows api", type: "failed"})
        }
    };

    const get_top_bar = (defaultCat) => {
        let urlTop = `${apiGateway}${HOME_TOP_BAR}`;
        axiosPrivate.get(urlTop)
        .then(({data, status}) => {
            if(data?.error_message && Object.keys(data?.error_message).length > 0){
                setErrorMsg({error: data?.error_message ?? "Unable to fetch top bar api", type: "failed"})
            }else{
                let topNavbar = []
                data?.tabs.slice(1, 5)?.map((d) => {
                    let obj = {
                        slug: d.slug,
                        id: d.id,
                        title: d.title,
                        series_ids : ''
                    }
                    topNavbar.push(obj)
                })
                setDefaultTopNavbar([...topNavbar]);
                get_track_by_id(track_id, topNavbar, defaultCat)
            }
        }).catch(({response}) => {
            setErrorMsg({error: response?.data ? response?.data?.error_message : "Unable to fetch category api", type: "failed"})
        })
    }

    const handleTrack = (e, currentStep, variant, type='track') => {
        setStepper((prev) => [...prev, 
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
        if(type == 'onboarding'){
            setOnboardingTitle(e.target.value)
        }else{
            setTrackTitle(e.target.value);
        }
    }

    const convertValue = (ids) => {
        return ids?.length > 0 ? ids?.split(",")?.map(id => parseInt(id)) : []
    }
    const dfsWiseValue = (array, type='none') => {
        let available_series_ids = {
            0: []
        }
        if(array?.length > 0){
            array?.forEach((it) => {
                if(type == 'custom'){
                    if(it?.series_ids?.length > 0){
                        available_series_ids[it.dfs] = it?.series_ids?.length > 0  ? it?.series_ids?.split(',')?.map(id => parseInt(id)) : []
                    }
                }else{
                    available_series_ids[it.dfs] = it?.series_ids?.length > 0  ? it?.series_ids?.split(',')?.map(id => parseInt(id)) : []
                }
            })
        }
        
        return available_series_ids
    }
    
    const handleChange = (event, variant, currentStep, type, info) => {
        const currentValue = event.target.value;
        let replacedValue = currentValue
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        setStepper((prev) => [...prev, 
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));

        // Replace Enter and Space with Comma
        if(currentValue?.length > 1)
            replacedValue = currentValue.replace(/[\n\s,]+/g, ',');

        // Update the state with the modified value
        if(type === 'home_trending'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it.homeTrendingSeriesIds[info.dfs].series_ids = replacedValue
                }
            })
        }
        else if(type === 'home_top10'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it.homeTop10SeriesIds[info.dfs].series_ids = replacedValue
                }
            })
        }
        else if(type === 'top_10_category'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    let copyTopNavbar = JSON.parse(JSON.stringify(it['topNavbar']))
                    copyTopNavbar.forEach(item => {
                        if(item.slug === info.slug){
                            item.series_ids = replacedValue
                        }
                    })
                    it['topNavbar']=copyTopNavbar
                }
            })
        }
        else if(type === 'new'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it.newSeriesIds[info.dfs].series_ids = replacedValue ?? []
                }
            })
        }
        setVariantGroups((prev => [...copyVariantGroups]))
    };

    const handleOnboarding = (event, currentStep, variant) => {
        const currentValue = event.target.value;
        let replacedValue = currentValue
        
        setStepper((prev) => [...prev, 
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));

        // Replace Enter and Space with Comma
        if(currentValue?.length > 1)
            replacedValue = currentValue.replace(/[\n\s,]+/g, ',');
        setOnboardingSeriesIds(replacedValue)
    }

    const handleDrag = (params, currentStep, variant) => {
        if(params && params.source){
        
            setStepper((prev) => [...prev, 
                prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
            const srcI = params.source.index;
            const destI = params.destination.index;
            variantGroups.forEach(it => {
                if(it.value === variant.value){
                    it['categories'] = JSON.parse(JSON.stringify(it['categories']))
                    it['categories']?.splice(destI, 0, ...it['categories'].splice(srcI, 1))
                    if(it.categories?.length > 0){
                        let idIndexMap = {};
                        it.categories.forEach((item, index) => {
                            idIndexMap[item.id] = index;
                        });
                        // Sort array a based on the order specified in array b
                        it.categoryAllCuration = it.categoryAllCuration.sort((x, y) => idIndexMap[x.category] - idIndexMap[y.category]);
                    }
                }
            })

            setVariantGroups(prev => [...variantGroups])
        }
    };

    const handleDragTopbar = (params, currentStep, variant) => {
        if(params && params.source){
            setStepper((prev) => [...prev, 
                prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
            const srcI = params.source.index;
            const destI = params.destination.index;
            variantGroups.forEach(it => {
                if(it.value === variant.value){
                    it['topNavbar'] = JSON.parse(JSON.stringify(it['topNavbar']))
                    it['defaultTopNavbar'] = JSON.parse(JSON.stringify(it['defaultTopNavbar']))
                    it['topNavbar']?.splice(destI, 0, ...it['topNavbar'].splice(srcI, 1))
                    it['defaultTopNavbar']?.splice(destI, 0, ...it['defaultTopNavbar'].splice(srcI, 1))
                }
            })
            saveOrderingTopNav(variant)   
        }
    }
    
    const checkAllStepValidated = (variant) => {
        let value = variant === 0 ? 'control' : 'experiment'
        let final = true
        let stepNotValid = stepper.filter((data) => data.isValid[value] === false)
        if(stepNotValid?.length > 0){
            final = false
            stepNotValid.forEach((it) => {
                return setErrorMsg({ error: `Validate step ${it.title}`, type:'failed'})
            })
        }
        return final
    }

    const saveData = (variant) => {
        let isAllStepValidated = checkAllStepValidated(variant)
        if(isAllStepValidated){
            setIsLoading(true);
            let control_curation = {}
            const formData = new FormData();
            formData.append('track_title', track_title)
            formData.append('lang', 'hi')
            formData.append('score', score)
            formData.append('onboarding_status', isOnboarding)
            formData.append("is_successful ", isSuccessful)
            let onboarding_series_ids = (onboardingSeriesIds?.length > 0 && isOnboarding) ? onboardingSeriesIds?.split(",") : []
            formData.append('onboarding_series_list', JSON.stringify([...onboarding_series_ids]))
            let campaigns = creativeUri.filter(it => it !== '')
            formData.append('campaign_codes', JSON.stringify(campaigns))
            formData.append('enable_track_experiment', isVariantSelected)
            formData.append('onboarding_title', onboardingTitle)

            let finalData = isVariantSelected ? variantGroups : [...variantGroups.slice(0, 1)]
            finalData?.forEach((it) => {
                let categories_list = []
                let home_tab_list = []
                let home_trending_series_ids = {}
                let stories_series_map = {}
                let home_top10_series_ids = {}
                let new_series_ids = {}
                let shows_list = [];

                const category_ordering = it?.categories?.length > 0 ? it.categories : categoriesOriginal;
                category_ordering.forEach((item, i) => categories_list.push(item.id))
                
                const shows_ordering = it?.shows?.length > 0 ? it?.shows : showsOriginal;
                shows_ordering.forEach((item, i) => shows_list.push(item.id))
                
                it?.topNavbar?.forEach((item, i) => home_tab_list.push(item.id))

                it.newSeriesIds.forEach((item) => {
                    new_series_ids[item.dfs] = item?.series_ids?.length > 0 ? item.series_ids?.split(',') : []
                })
                it.homeTrendingSeriesIds.forEach((item) => {
                    home_trending_series_ids[item.dfs] = item?.series_ids?.length > 0 ? item.series_ids?.split(',') : []
                })
                it.homeTop10SeriesIds.forEach((item) => {
                    home_top10_series_ids[item.dfs] = item?.series_ids?.length > 0 ? item.series_ids?.split(',') : []
                })
                
                it?.topNavbar.forEach(it => {
                    let is_series = Object.keys(dfsWiseValue(it.row_series) || {})?.length > 0
                    if(is_series){
                        stories_series_map[it.id] = dfsWiseValue(it.row_series)
                    }
                })
                let categories_page_curation = {}
                it?.categoryAllCuration?.forEach((item) => {
                    categories_page_curation[item?.category] = {}
                    let trending_rows = item?.custom_rows?.filter(d => d.type === 'trending')
                    let trending_series = trending_rows?.length > 0 ? trending_rows[0]?.row_series : {}
                    let top10_rows = item?.custom_rows?.filter(d => d.type === 'top10')
                    let top10_series = top10_rows?.length > 0 ? top10_rows[0]?.row_series : {}
                    let custom_rows = [];
                    item?.custom_rows?.forEach((cr, i) => {
                        let sequence_no = i * 10 + 25
                        if(cr?.is_title){
                            let obj = {}
                            obj['title'] = cr.title ? cr?.title : ""
                            if(cr?.is_removed != true){
                                obj['dfs_series_map'] = dfsWiseValue(cr?.row_series, 'custom')
                            }else{
                                obj['is_removed'] = true
                            }
                            obj['sequence_no'] = sequence_no
                            obj['entity_id'] = cr?.entity_id ? cr?.entity_id : -1
                            custom_rows.push(obj);
                        }else{
                            if(cr.type == 'trending'){
                                categories_page_curation[item?.category]['trending_series_map'] = {
                                    "dfs_series_map" : dfsWiseValue(trending_series, 'custom'),
                                    "sequence_no": sequence_no
                                }
                            }
                            if(cr.type == 'top10'){
                                categories_page_curation[item?.category]['top_10_series_map'] = {
                                    "dfs_series_map" : dfsWiseValue(top10_series, 'custom'),
                                    "sequence_no": sequence_no,
                                }
                            }
                        }
                    })
                    categories_page_curation[item?.category]['category_all_series_map'] = {
                        "series_ids" : convertValue(item?.categoryAllSeries),
                        "sequence_no": 100000,
                    }
                    categories_page_curation[item?.category]['custom_rows'] = custom_rows
                })

                let shows_page_curation = {}
                it?.showsAllCuration?.forEach((item) => {
                    shows_page_curation[item?.show] = {}
                    let trending_rows = item?.custom_rows?.filter(d => d.type === 'trending')
                    let trending_series = trending_rows?.length > 0 ? trending_rows[0]?.row_series : {}
                    // let top10_rows = item?.custom_rows?.filter(d => d.type === 'top10')
                    // let top10_series = top10_rows?.length > 0 ? top10_rows[0]?.row_series : {}
                    let custom_rows = [];
                    item?.custom_rows?.forEach((cr, i) => {
                        let sequence_no = i * 10 + 25
                        if(cr?.is_title){
                            let obj = {}
                            obj['title'] = cr.title ? cr?.title : ""
                            if(cr?.is_removed != true){
                                obj['dfs_series_map'] = dfsWiseValue(cr?.row_series, 'custom')
                            }else{
                                obj['is_removed'] = true
                            }
                            obj['sequence_no'] = sequence_no
                            obj['entity_id'] = cr?.entity_id ? cr?.entity_id : -1
                            custom_rows.push(obj);
                        }else{
                            if(cr.type == 'trending'){
                                // shows_page_curation[item?.show]['trending_series_map'] = {
                                //     "dfs_series_map" : dfsWiseValue(trending_series, 'custom'),
                                //     "sequence_no": sequence_no
                                // }
                                shows_page_curation[item?.show] = dfsWiseValue(trending_series, 'custom')
                            }
                            // if(cr.type == 'top10'){
                            //     shows_page_curation[item?.show]['top_10_series_map'] = {
                            //         "dfs_series_map" : dfsWiseValue(top10_series, 'custom'),
                            //         "sequence_no": sequence_no,
                            //     }
                            // }
                        }
                    })
                    // shows_page_curation[item?.show]['shows_all_series_map'] = {
                    //     "series_ids" : convertValue(item?.showsAllSeries),
                    //     "sequence_no": 100000,
                    // }
                    // shows_page_curation[item?.show]['custom_rows'] = custom_rows
                })

                control_curation[it.value] = {}
                // control_curation[it.value]['home_tab_stories_series_map'] = stories_series_map
                control_curation[it.value]['categories_page_curation'] = categories_page_curation
                control_curation[it.value]['shows_page_curation'] = shows_page_curation
                control_curation[it.value]['home_trending_series_map'] = home_trending_series_ids
                control_curation[it.value]['home_top_10_series_map'] = home_top10_series_ids
                control_curation[it.value]['category_ordering'] = categories_list
                control_curation[it.value]['home_tab_ordering'] = home_tab_list.splice(0,5)
                control_curation[it.value]['new_series_map'] = new_series_ids
            })
            formData.append('track_curation_data', JSON.stringify(control_curation));
            formData.append("app_name", selectApp);
            let url = `${apiGateway}${EDIT_TRACK}${trackId}/update/`;
            axiosPrivate.post(url, formData)
            .then(({data, status}) => {
                if(data?.status !== 'success'){
                    if(data?.failure_ids?.length > 0){
                        setErrorMsg({error: `${data?.failure_ids} is not live`, type: 'failed'});
                        setIsLoading(false);
                    }
                }else{
                    setErrorMsg({error: "Track created successfully", type: 'success'});
                    window.location.href = '#/tracks';
                }
            }).catch(({response}) => {
                if(response?.status === 404 || response?.status === 500){
                    setErrorMsg({error: "Unable to reach server", type: 'failed'})
                }else if(response?.status === 401){
                    setErrorMsg({error: "Unauthorized to access", type: 'failed'})
                }else{
                    setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: 'failed'})
                }
                setIsLoading(false);
            })
        }
    }

    const validateData = (typeForm, variant) => {
        let isValid = true
        if (typeForm === 'track'){
            if(track_title === '' || track_title == null || track_title === undefined){
                setErrorMsg({ error: "Please enter track title", type: "failed" })
                isValid = false
            }
            if(creativeUri[0] == '' || creativeUri == null || creativeUri === undefined){
                setErrorMsg({ error: "Please enter campaign uri", type: "failed" })
                isValid = false
            }
            if(isOnboarding === true){
                let series_ids = onboardingSeriesIds?.split(",")
                if(series_ids?.length !== 3){
                    setErrorMsg({ error: "Must be 3 series in onboarding series ids", type: "failed" })
                    isValid = false
                }
            }
        }
        if(typeForm === 'home'){
            let available_series_ids = []
            variant?.homeTrendingSeriesIds.forEach((it) => {
                if(it.series_ids?.length > 0)
                    available_series_ids.push(it.series_ids)
            })
            if(variant?.homeTrendingSeriesIds?.length < 1 || available_series_ids?.length < 1 ){
                setErrorMsg({ error: "Please enter valid Home trending series ids", type: "failed" })
                isValid = false
            }
        }

        if(typeForm === 'tabs'){
            // add logic for basic validation
        }
        return isValid
    }

    const handleNextStep = (currentStep, type, variant) => {
        setStepper((prev) => [...prev, 
            prev[currentStep.step].status = true,
            prev[currentStep.step].showButton = true,
            prev[currentStep.step-1].showButton = false].splice(0,5));
        setActiveStep(currentStep.step)
        if(type === 'home'){
            saveOrderingTopNav(variant)
        }
    }
    
    const handlePrevStep = (currentStep, typeForm) => {
        setStepper((prev) => [...prev, 
            prev[currentStep.step-1].status = false,
            prev[currentStep.step-1].showButton = false,
            prev[currentStep.step-2].showButton = true].splice(0,5))
        setActiveStep(currentStep.step-2)
    }

    const saveOrderingTopNav =(variant) => {
        let topNavbarNew = []
        variant?.topNavbar.forEach((itm) => {
            if(itm.slug === replaceCategory){
                itm.slug = replaceCategoryNew.slug
                itm.home_icon = replaceCategoryNew.home_icon
                itm.title = replaceCategoryNew.title
                itm.type = 'category'
                itm.id = replaceCategoryNew.id
            }
            topNavbarNew.push(itm)
        })
        variantGroups.forEach(it => {
            if(it.value === variant.value)
                it["topNavbar"] = topNavbarNew
        })
        setVariantGroups(prev => [...variantGroups])
    }

    const handleSaveNewCategory = () => {
        if(categoryExperiment && categoryExperiment?.length > 0){
            if(categoryExperiment === ''){
                setErrorMsg({ error: "Please select at least one category or add new", type: "failed" })
                return true
            }
            let category = saveDefaultCategories.filter(i => i.slug === categoryExperiment)[0]
            let isCategoryExist = categoriesOriginal?.filter(i => i.slug === categoryExperiment)[0]
            if(isCategoryExist && isCategoryExist?.slug){
                setErrorMsg({ error: "Category already added", type: "failed" })
                return true
            }
            category['is_added'] = true
            variantGroups.forEach(it => {
                if(it.id === selectedVariant){
                    it['newCategory'] = category
                    it['categories'] = JSON.parse(JSON.stringify([category, ...it['categories']]))
                }
            })
            setVariantGroups(prev => [...variantGroups])
            setIsNewCategoryAdd(false)
        }else{
            if(categoryName === '' || categoryName == null || categoryName === undefined){
                setErrorMsg({ error: "Category name is required", type: "failed" })
                return true
            }
            if(categoryIcon === '' || categoryIcon == null || categoryIcon === undefined){
                setErrorMsg({ error: "Category icon is required", type: "failed" })
                return true
            }
            setShowLoader(true);
            let url = `${apiGateway}${CATEGORY_CMS}`;
            let formData = new FormData();
            formData.append('title', categoryName?.toUpperCase())
            formData.append('category_icon_file', categoryIcon)
            axiosPrivate.post(url, formData)
            .then(({data, status}) => {
                if(status == false){
                    setErrorMsg({ error:data?.error_message ?? "Failed to create category", type: 'failed'})
                }else{
                    let category = data?.category
                    category['is_added'] = true
                    variantGroups.forEach(it => {
                        if(it.id === selectedVariant){
                            it['newCategory'] = category
                            it['categories'] = JSON.parse(JSON.stringify([category, ...it['categories']]))
                        }
                    })
                    setVariantGroups(prev => [...variantGroups])
                }
                setShowLoader(false);
                setIsNewCategoryAdd(false)
            }).catch(({response}) => {
                if(response?.status === 404 || response?.status === 500){
                    setErrorMsg({error: "Unable to reach server", type: 'failed'})
                }else{
                    setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: 'failed'})
                }
                setIsNewCategoryAdd(false)
                setShowLoader(false);
            })
        }
    }

    const clearDataForDialog = () => {
        if(showDialog === 'all_series'){
            setCategoryName('')
            setCategoryIcon('')
        }else if(showDialog === 'top_10'){
            setReplaceCategoryNew({})
            setReplaceCategory('')
        }
        setShowDialog(null)
    }

    const handleCreativeUri = (e) => {
        if(!creativeUri.includes('')){
            SetCreativeUri(prev => [...prev, ''])
        }
    }

    const removeCreativeUri = (e, i, currentStep, code, variant) => {
        setStepper((prev) => [...prev, 
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
        SetCreativeUri(prev => [...prev.filter(it => it !== code)])
    }

    const handleChangeTabs = (event, newValue) => {
        setActiveStep(newValue)
    }

    const handleVariantChanges = (event, newValue) => {
        setSelectVariant(newValue);
        let selected = {};
        variantGroups?.forEach((item) => {
            selected[item.value] = parseInt(item.categoryAllCuration?.length > 0 ? item.categoryAllCuration[0].category : 0)
        })
        setCurrentCategorySelected(selected)

    }

    const handleValidation = (currentStep, type, variant) => {
        let isValidData = validateData(type, variant)
        if(isValidData && currentStep.isValid[variant.value] === false){
            setIsLoading(true)
            let formData = new FormData()
            formData.append('type', type)
            formData.append('track_id', track_id)

            if(type === 'track'){
                formData.append('track_title', track_title)
                let campaigns = creativeUri.filter(it => it !== '')
                formData.append('campaign_codes', JSON.stringify(campaigns))
                formData.append('onboarding_status', isOnboarding)
                formData.append("is_successful ", isSuccessful);
                formData.append('score', score)
                if(isOnboarding === true){
                    let onboarding_series_ids = onboardingSeriesIds?.length > 0 ? onboardingSeriesIds?.split(",") : []
                    formData.append('onboarding_series_list', JSON.stringify([...onboarding_series_ids]))
                }
            }
            if(type === 'home'){
                let home_tab_list = []
                let home_trending_series_ids = {}
                let home_top10_series_ids = {}
                variant?.topNavbar.forEach((item, i) => home_tab_list.push(item.id))
                variant?.homeTrendingSeriesIds.forEach((it) => {
                    if(it.series_ids?.length > 0){
                        home_trending_series_ids[it.dfs] = it.series_ids?.split(',').map(id => parseInt(id))
                    }
                })
                formData.append('home_trending_series_map', JSON.stringify(home_trending_series_ids))
                variant?.homeTop10SeriesIds.forEach((it) => {
                    if(it.series_ids?.length > 0){
                        home_top10_series_ids[it.dfs] = it.series_ids?.split(',').map(id => parseInt(id))
                    }
                })
                formData.append('home_top_10_series_map', JSON.stringify(home_top10_series_ids))
                formData.append('home_tab_ordering', JSON.stringify(home_tab_list))
            }
            if(type === 'category'){
                let categories_page_curation = {}
                variant?.categoryAllCuration?.forEach((item) => {
                    let is_custom_rows = item?.custom_rows?.length > 0
                    let trending_rows = item?.custom_rows?.filter(d => d.type === 'trending')
                    let trending_series = trending_rows?.length > 0 ? trending_rows[0]?.row_series : {}
                    let is_trending_series = Object.keys(dfsWiseValue(trending_series) || {})?.length > 0

                    let top10_rows = item?.custom_rows?.filter(d => d.type === 'top10')
                    let top10_series = top10_rows?.length > 0 ? top10_rows[0]?.row_series : {}
                    let is_top10 = Object.keys(dfsWiseValue(top10_series) || {})?.length > 0
                    
                    let is_categoryAllSeries = convertValue(item?.categoryAllSeries ?? [])?.length > 0
                    if(is_trending_series || is_top10 || is_categoryAllSeries || is_custom_rows){
                        categories_page_curation[item?.category] = {}
                    }
                    if(is_custom_rows){
                        let custom_rows = [];
                        item.custom_rows.forEach((cr) => {
                            if(cr?.is_title && cr?.is_removed==false){
                                let obj = {}
                                obj['title'] = cr.title ? cr?.title : ""
                                obj['dfs_series_map'] = dfsWiseValue(cr?.row_series)
                                custom_rows.push(obj);
                            }else{
                                if(is_trending_series){
                                    categories_page_curation[item?.category]['trending_series_map'] = dfsWiseValue(trending_series)
                                }
                                if(is_top10){
                                    categories_page_curation[item?.category]['top_10_series_map'] = dfsWiseValue(top10_series)
                                }
                                if(is_categoryAllSeries){
                                    categories_page_curation[item?.category]['category_all_series_ids'] = convertValue(item?.categoryAllSeries)
                                }
                            }
                        })
                        if(custom_rows?.length > 0)
                            categories_page_curation[item?.category]['custom_rows'] = custom_rows
                    }
                })
                formData.append('categories_page_curation', JSON.stringify(categories_page_curation))
            }

            if(type === 'show'){
                let shows_page_curation = {}
                variant?.showsAllCuration?.forEach((item) => {
                    let is_custom_rows = item?.custom_rows?.length > 0
                    let trending_rows = item?.custom_rows?.filter(d => d.type === 'trending')
                    let trending_series = trending_rows?.length > 0 ? trending_rows[0]?.row_series : {}
                    let is_trending_series = Object.keys(dfsWiseValue(trending_series) || {})?.length > 0

                    // let top10_rows = item?.custom_rows?.filter(d => d.type === 'top10')
                    // let top10_series = top10_rows?.length > 0 ? top10_rows[0]?.row_series : {}
                    // let is_top10 = Object.keys(dfsWiseValue(top10_series) || {})?.length > 0
                    
                    // let is_showsAllSeries = convertValue(item?.showsAllSeries ?? [])?.length > 0
                    if(is_trending_series || is_custom_rows){ // is_top10 || is_showsAllSeries
                        shows_page_curation[item?.show] = {}
                    }
                    if(is_custom_rows){
                        let custom_rows = [];
                        item.custom_rows.forEach((cr) => {
                            if(cr?.is_title && cr?.is_removed==false){
                                let obj = {}
                                obj['title'] = cr.title ? cr?.title : ""
                                obj['dfs_series_map'] = dfsWiseValue(cr?.row_series)
                                custom_rows.push(obj);
                            }else{
                                if(is_trending_series){
                                    // [trending_series_map]
                                    shows_page_curation[item?.show] = dfsWiseValue(trending_series)
                                }
                                // if(is_top10){
                                //     shows_page_curation[item?.show]['top_10_series_map'] = dfsWiseValue(top10_series)
                                // }
                                // if(is_showsAllSeries){
                                //     shows_page_curation[item?.show]['shows_all_series_ids'] = convertValue(item?.showsAllSeries)
                                // }
                            }
                        })
                        if(custom_rows?.length > 0)
                            shows_page_curation[item?.show]['custom_rows'] = custom_rows
                    }
                })
                formData.append('shows_page_curation', JSON.stringify(shows_page_curation))
            }

            if(type === 'new_series'){
                let new_series_ids = {}
                variant?.newSeriesIds.forEach((it) => {
                    if(it.series_ids?.length > 0){
                        new_series_ids[it.dfs] = it.series_ids?.split(',').map(id => parseInt(id))
                    }
                })                
                formData.append('new_series_map', JSON.stringify(new_series_ids))
            }

            // if(type === 'stories'){
            //     let series_id_object = {}
            //     variant?.topNavbar.forEach(it => {
            //         let is_series = Object.keys(dfsWiseValue(it.row_series) || {})?.length > 0
            //         if(is_series){
            //             series_id_object[it.id] = dfsWiseValue(it.row_series)
            //         }
            //     })
            //     formData.append('home_tab_stories_series_map', JSON.stringify(series_id_object))
            // }
            let url = `${apiGateway}${TRACK_VALIDATIONS}`;
            axiosPrivate.post(url, formData)
            .then(({data, status}) => {
                if(status === 200){
                    setErrorMsg({error: "Validated successfully", type: 'success'})
                    setStepper((prev) => [...prev, 
                        prev[currentStep.step-1].isValid[variant.value] = true].splice(0,5));
                }
                setIsLoading(false);
            }).catch(({response}) => {
                if(response?.status === 404 || response?.status === 500){
                    setErrorMsg({error: "Unable to reach server", type: 'failed'})
                }else{
                    let data = response?.data
                    if(data?.failure_ids?.length > 0){
                        setErrorMsg({error: `${data?.failure_ids} is not live- ${response?.data?.error_message}`, type: 'failed'});
                    }else{
                        setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: 'failed'})
                    }
                }
                setIsLoading(false);
            })
        }
    }

    const removeCategory = (cat) => {
        variantGroups.forEach(it => {
            if(it.id === selectedVariant){
                it['newCategory'] = {}
                it['categories'] = JSON.parse(JSON.stringify([...it['categories'].filter((it) => it.slug !== cat.slug)]))
            }
        })
        setVariantGroups(prev => [...variantGroups])
        setCategoryExperiment('')
        setCategoryName('')
        setCategoryIcon('')
    }

    const copyDataToExperiment = () => {
        let copyVariantGroups = [...variantGroups]
        let controlData = copyVariantGroups.filter(d => d.value == 'control')
        setCurrentCategorySelected({
            'experiment' : currentCategorySelected['control'],
            'control': currentCategorySelected['control']
        })
        setCurrentShowSelected({
            'experiment' : currentShowSelected['control'],
            'control': currentShowSelected['control']
        })
        copyVariantGroups.forEach((it) => {
            if(it.value === 'experiment'){
                it['categories'] = controlData[0].categories ? controlData[0].categories : categoriesOriginal
                it['shows'] = controlData[0]?.shows ? controlData[0].shows : showsOriginal 
                it['categoryAllSeriesIds'] = controlData[0].categoryAllSeriesIds
                it['defaultTopNavbar'] = controlData[0].defaultTopNavbar
                it['homeTop10SeriesIds'] = controlData[0].homeTop10SeriesIds
                it['homeTrendingSeriesIds'] = controlData[0].homeTrendingSeriesIds
                it['newSeriesIds'] = controlData[0].newSeriesIds
                it['topNavbar'] = controlData[0].topNavbar
                it['categoryAllCuration'] = controlData[0].categoryAllCuration
                it['showsAllCuration'] = controlData[0].showsAllCuration
            }
        });
        setVariantModel(false);
    }

    const clearExperimentData = () => {
        [...variantGroups].forEach((it) => {
            if(it.value === 'experiment'){
                it['categories'] = categoriesOriginal
                it['categoryAllSeriesIds'] = []
                it['defaultTopNavbar'] = defaultTopNavbarOriginal
                it['homeTop10SeriesIds'] = [{id: 1, dfs:0, series_ids: ''}]
                it['homeTrendingSeriesIds'] = [{id: 1, dfs:0, series_ids: ''}]
                it['newSeriesIds'] = [{id: 1, dfs:0, series_ids: ''}]
                it['topNavbar'] = topNavbarOriginal
                it['categoryAllCuration'] =  []
            }
        });
        setCurrentCategorySelected({
            'experiment' : '',
            'control': currentCategorySelected['control']
        })
        setVariantGroups(prev => [...variantGroups])
        setVariantModelRevert(false);
    }

    const makeDefault = () => {
        let url = `${apiGateway}${EDIT_TRACK_EXPERIMENT}${track_id}/make-default/`;
        setIsLoading(true);
        axiosPrivate.post(url)
        .then(({data, status}) => {
            if(!status){
                setErrorMsg({error: data?.error_message ?? "Something went wrong", type: "failed"})
            }else{
                setIsLoading(false);
                setErrorMsg({error: "Experiment is set to default", type: "success"})
                get_track_by_id(track_id, defaultTopNavbarOriginal, categoriesOriginal)
                window.location.reload();
            }
        }).catch(({response}) => {
            setIsLoading(false);
            setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: "failed"})
        })
        setIsToggleDialog(false);
    }

    const closeExperiment = () => {
        let url = `${apiGateway}${EDIT_TRACK_EXPERIMENT}${track_id}/close/`;
        setIsLoading(true);
        axiosPrivate.post(url)
        .then(({data, status}) => {
            if(!status){
                setErrorMsg({error: data?.error_message ?? "Something went wrong", type: "failed"})
            }else{
                setIsLoading(false);
                setErrorMsg({error: "Experiment is closed", type: "success"})
                get_track_by_id(track_id, defaultTopNavbarOriginal, defaultTopNavbarOriginal);
                window.location.reload();
            }
        }).catch(({response}) => {
            setIsLoading(false);
            setErrorMsg({error: response?.data ? response?.data?.error_message : "Something went wrong", type: "failed"})
        })
        setIsToggleDialog(false);
    }

    const toggleStatus = (track_id, is_active) => {
        let formData = new FormData()
        formData.append('is_active', !is_active)
        let url = `${apiGateway}${EDIT_TRACK_ACTION}${track_id}/toggle-status/`;
        setIsLoading(true);
        axiosPrivate.post(url, formData)
        .then(({data, status}) => {
            if(status == false){
                setErrorMsg({type: "failed", error: data?.error_message ?? "Something went wrong"})
            }else{
                setIsLoading(false);
                get_track_by_id(track_id, defaultTopNavbarOriginal, categoriesOriginal);
                setErrorMsg({type: "success", error: "Track is status changed"})
            }
        }).catch(({response}) => {
            setIsLoading(false);
            setErrorMsg({type: "failed", error: response?.data ? response?.data?.error_message : "Something went wrong"})
        })
        setIsStatusDialog(false);
    }

    const handleChangeAllSeries = (event, variant, currentStep, type, slug, index, row_data, data_cu_row) => {
        const currentValue = event.target.value;
        let replacedValue = currentValue
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));

        setStepper((prev) => [...prev, 
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));

        // Replace Enter and Space with Comma
        if(currentValue?.length > 1 && type !== 'custom_series_title')
            replacedValue = currentValue.replace(/[\n\s,]+/g, ',');

        if(type === 'all_series_ids'){
            let availableCategory = variant?.categoryAllCuration.filter(it => it.category === slug)
            if(availableCategory?.length > 0){
                copyVariantGroups.forEach(it => {
                    if(it.value === variant.value){
                        it?.categoryAllCuration?.forEach(d => {
                            if(d.category === slug){
                                d["categoryAllSeries"] = replacedValue
                            }
                        })
                    }
                })
            }
        }

        // if(type === 'all_series_ids_for_shows'){
        //     let availableShows = variant?.showsAllCuration.filter(it => it.show === slug)
        //     if(availableShows?.length > 0){
        //         copyVariantGroups.forEach(it => {
        //             if(it.value === variant.value){
        //                 it?.showsAllCuration?.forEach(d => {
        //                     if(d.show === slug){
        //                         d["showsAllSeries"] = replacedValue
        //                     }
        //                 })
        //             }
        //         })
        //     }
        // }


        if(type === 'custom_series_title'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it?.categoryAllCuration?.forEach(d => {
                        if(d.category === slug){
                            d["custom_rows"].forEach((row) => {
                                if(row.id == row_data.id)
                                row["title"] = currentValue
                            })
                        }
                    })
                }
            })
        }

        if(type === 'custom_series_title_for_shows'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it?.showsAllCuration?.forEach(d => {
                        if(d.show === slug){
                            d["custom_rows"].forEach((row) => {
                                if(row.id == row_data.id)
                                row["title"] = currentValue
                            })
                        }
                    })
                }
            })
        }

        if(type === 'custom_series'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it?.categoryAllCuration?.forEach(d => {
                        if(d.category === slug){
                            d["custom_rows"].forEach((row) => {
                                if(row.id == row_data.id)
                                row["row_series"].forEach((sr) => {
                                    if(sr.dfs === data_cu_row.dfs){
                                        sr.series_ids = replacedValue
                                    }
                                })
                            })
                        }
                    })
                }
            })
        }

        if(type === 'custom_series_for_shows'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it?.showsAllCuration?.forEach(d => {
                        if(d.show === slug){
                            d["custom_rows"].forEach((row) => {
                                if(row.id == row_data.id)
                                row["row_series"].forEach((sr) => {
                                    if(sr.dfs === data_cu_row.dfs){
                                        sr.series_ids = replacedValue
                                    }
                                })
                            })
                        }
                    })
                }
            })
        }

        if(type === 'stories'){
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it?.topNavbar?.forEach(d => {
                        if(d.slug === slug){
                            d["row_series"].forEach((sr) => {
                                if(sr.dfs === row_data.dfs){
                                    sr.series_ids = replacedValue
                                }
                            })
                        }
                    })
                }
            })
        }
        setVariantGroups((prev => [...copyVariantGroups]))
    }

    const handleCategoryCuration = (val, variant, categories) => {
        if(val !== ""){
            setCurrentCategorySelected({
                "control": variant.value === 'control' ? val : currentCategorySelected[variant.value],
                "experiment": variant.value === 'experiment' ? val : currentCategorySelected[variant.value]
            });
            let copyVariantGroups = [...variantGroups];
            copyVariantGroups.forEach((it) => {
                if(it.value === variant.value){
                    let obj = {}
                    let isAlready = it?.categoryAllCuration.filter((item) => parseInt(item.category) === parseInt(val) )
                    if(!isAlready?.length > 0){
                        obj['category'] = val
                        obj['id'] = parseInt(val)
                        obj['is_removed'] = false
                        obj['custom_rows'] = [{
                            title: '',
                            is_title: false,
                            row_series:  [{id: 1, dfs: 0, series_ids: ''}],
                            is_dfs: true,
                            heading: "Category trending series Ids* (DFS 0 ~ 7)",
                            id: 1,
                            type: 'trending'
                        },{
                            title: '',
                            is_title: false,
                            row_series:  [{id: 1, dfs: 0, series_ids: ''}],
                            is_dfs: true,
                            heading:"Category top 10 series Ids",
                            id: 2,
                            type: 'top10'
                        }]
                        obj['categoryAllSeries'] = []
                        it.categoryAllCuration.push(obj)
                    }
                    if(categories?.length > 0){
                        let idIndexMap = {};
                        categories.forEach((item, index) => {
                            idIndexMap[item.id] = index;
                        });
                        // Sort array a based on the order specified in array b
                        it.categoryAllCuration = it.categoryAllCuration.sort((x, y) => idIndexMap[x.category] - idIndexMap[y.category]);
                    }
                }
            })
            setVariantGroups(prev => [...copyVariantGroups])
        }
    }

    const handleShowsCuration = (val, variant, shows) => {
        if(val !== ""){
            setCurrentShowSelected({
                "control": variant.value === 'control' ? val : currentShowSelected[variant.value],
                "experiment": variant.value === 'experiment' ? val : currentShowSelected[variant.value]
            });
            let copyVariantGroups = [...variantGroups];
            copyVariantGroups.forEach((it) => {
                if(it.value === variant.value){
                    let obj = {}
                    let isAlready = it?.showsAllCuration.filter((item) => parseInt(item.show) === parseInt(val) )
                    if(!isAlready?.length > 0){
                        obj['show'] = val
                        obj['id'] = parseInt(val)
                        obj['is_removed'] = false
                        obj['custom_rows'] = [{
                            title: '',
                            is_title: false,
                            row_series:  [{id: 1, dfs: 0, series_ids: ''}],
                            is_dfs: true,
                            heading: "Show trending series Ids* (DFS 0 ~ 7)",
                            id: 1,
                            type: 'trending'
                        },
                        // {
                        //     title: '',
                        //     is_title: false,
                        //     row_series:  [{id: 1, dfs: 0, series_ids: ''}],
                        //     is_dfs: true,
                        //     heading:"Show top 10 series Ids",
                        //     id: 2,
                        //     type: 'top10'
                        // }
                    ]
                        obj['showsAllSeries'] = []
                        it.showsAllCuration.push(obj)
                    }
                    if(shows?.length > 0){
                        let idIndexMap = {};
                        shows.forEach((item, index) => {
                            idIndexMap[item.id] = index;
                        });
                        // Sort array a based on the order specified in array b
                        it.showsAllCuration = it.showsAllCuration.sort((x, y) => idIndexMap[x.show] - idIndexMap[y.show]);
                    }
                }
            })
            setVariantGroups(prev => [...copyVariantGroups])
        }
    }

    const handleDfsAdd = (variant, category, type, custom_row) => {
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        if(type !== '' && category){
            if(type === 'stories'){
                let topNavbar = JSON.parse(JSON.stringify(variant?.topNavbar))
                topNavbar.forEach((topbar) => {
                    if(topbar.slug === category){
                        let last_stories = topbar.row_series[topbar.row_series?.length-1]
                        topbar.row_series = [...topbar.row_series, {
                            id: isNaN(last_stories?.id) ? 0 : parseInt(last_stories.id) + 1,
                            dfs: isNaN(last_stories?.dfs) ? 0 : parseInt(last_stories.dfs) + 1,
                            series_ids: [],
                        }];
                    }
                })
                copyVariantGroups.forEach(it => {
                    if(it.value === variant.value){
                        it.topNavbar = topNavbar
                    }
                })
            }else{
                let categoryCurationCategory = JSON.parse(JSON.stringify(variant?.categoryAllCuration));
                categoryCurationCategory.forEach((it) => {
                    if(it.category === category){
                        if(type === 'top10'){
                            let last_top10 = it.top10[it?.top10?.length-1]
                            it.top10 = [...it.top10, {
                                id: isNaN(last_top10?.id) ? 0 : parseInt(last_top10.id) + 1,
                                dfs: isNaN(last_top10?.dfs) ? 0 : parseInt(last_top10.dfs) + 1,
                                series_ids: [],
                            }];
                        }else if(type === 'custom_series'){
                            it.custom_rows?.forEach((cr) => {
                                if(cr.id === custom_row.id){
                                    let last_custom = cr.row_series[cr.row_series?.length-1]
                                    cr.row_series = [...cr.row_series, {
                                        id: isNaN(last_custom?.id) ? 0 : parseInt(last_custom.id) + 1,
                                        dfs: isNaN(last_custom?.dfs) ? 0 : parseInt(last_custom.dfs) + 1,
                                        series_ids: [],
                                    }];
                                }
                            });
                        } else{
                            let last_trending = it.trending_series[it?.trending_series?.length-1]
                            it.trending_series = [...it.trending_series, {
                                id: isNaN(last_trending?.id) ? 0 : parseInt(last_trending.id) + 1,
                                dfs: isNaN(last_trending?.dfs) ? 0 : parseInt(last_trending.dfs) + 1,
                                series_ids: [],
                            }];
                        }
                    }
                })
                copyVariantGroups.forEach(it => {
                    if(it.value === variant.value){
                        it.categoryAllCuration = categoryCurationCategory
                    }
                })
            }
            setVariantGroups(prev => [...copyVariantGroups])
        }else{
            if(type == 'home_top10'){
                let homeTop10SeriesIds = variant?.homeTop10SeriesIds
                let last_top10 = homeTop10SeriesIds[homeTop10SeriesIds?.length-1]
                variantGroups.forEach(it => {
                    if(it.value === variant.value){
                        it.homeTop10SeriesIds = [...it.homeTop10SeriesIds, {
                            id: last_top10.id + 1,
                            dfs: last_top10.dfs + 1,
                            series_ids: [],
                        }]
                    }
                })
            }else if(type == 'new_tab'){
                let newTabSeriesIds = variant?.newSeriesIds
                let last_new_tab = newTabSeriesIds[newTabSeriesIds?.length-1]
                variantGroups.forEach(it => {
                    if(it.value === variant.value){
                        it.newSeriesIds = [...it.newSeriesIds, {
                            id: last_new_tab.id + 1,
                            dfs: last_new_tab.dfs + 1,
                            series_ids: [],
                        }]
                    }
                })
            }else{
                let homeTrendingSeriesIds = variant?.homeTrendingSeriesIds
                let last_trending = homeTrendingSeriesIds[homeTrendingSeriesIds?.length-1]
                variantGroups.forEach(it => {
                    if(it.value === variant.value){
                        it.homeTrendingSeriesIds = [...it.homeTrendingSeriesIds, {
                            id: last_trending.id + 1,
                            dfs: last_trending.dfs + 1,
                            series_ids: [],
                        }]
                    }
                })
            }
            setVariantGroups((prev => [...variantGroups]))
        }
    }


    const handleAddDSFForShows = (variant, show, type, custom_row) => {
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        let showCurationShows = JSON.parse(JSON.stringify(variant?.showsAllCuration));
        showCurationShows.forEach((it) => {
            if(it.show === show){
                if(type === 'top10'){
                    let last_top10 = it.top10[it?.top10?.length-1]
                    it.top10 = [...it.top10, {
                        id: isNaN(last_top10?.id) ? 0 : parseInt(last_top10.id) + 1,
                        dfs: isNaN(last_top10?.dfs) ? 0 : parseInt(last_top10.dfs) + 1,
                        series_ids: [],
                    }];
                }else if(type === 'custom_series'){
                    it.custom_rows?.forEach((cr) => {
                        if(cr.id === custom_row.id){
                            let last_custom = cr.row_series[cr.row_series?.length-1]
                            cr.row_series = [...cr.row_series, {
                                id: isNaN(last_custom?.id) ? 0 : parseInt(last_custom.id) + 1,
                                dfs: isNaN(last_custom?.dfs) ? 0 : parseInt(last_custom.dfs) + 1,
                                series_ids: [],
                            }];
                        }
                    });
                } else{
                    let last_trending = it.trending_series[it?.trending_series?.length-1]
                    it.trending_series = [...it.trending_series, {
                        id: isNaN(last_trending?.id) ? 0 : parseInt(last_trending.id) + 1,
                        dfs: isNaN(last_trending?.dfs) ? 0 : parseInt(last_trending.dfs) + 1,
                        series_ids: [],
                    }];
                }
            }
        })
        copyVariantGroups.forEach(it => {
            if(it.value === variant.value){
                it.showsAllCuration = showCurationShows
            }
        })
        setVariantGroups(prev => [...copyVariantGroups])
    }

    const removeCategoryCuration = (val, currentStep, variant, categories) => {
        setStepper((prev) => [...prev,
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        copyVariantGroups.forEach((it) => {
            if(it.value === variant.value){
                let current = it.categoryAllCuration
                current?.forEach((item) => {
                    if(parseInt(item.category) === parseInt(val)){
                        item?.custom_rows?.forEach((d) => {
                            d.row_series = []
                        })
                        item.is_removed = true
                        item.categoryAllSeries = []
                    }
                })
                if(categories?.length > 0){
                    let idIndexMap = {};
                    categories.forEach((item, index) => {
                        idIndexMap[item.id] = index;
                    });
                    let firstIndex = 0
                    it.categoryAllCuration.forEach((d) => {
                        if(d.is_removed == false && firstIndex == 0){
                            firstIndex = d.category
                        }
                    })
                    setCurrentCategorySelected({
                        "control": variant.value === 'control' ? it.categoryAllCuration?.length > 0 ? firstIndex : "" : currentCategorySelected['control'],
                        "experiment": variant.value === 'experiment' ? it.categoryAllCuration?.length > 0 ? firstIndex : "" : currentCategorySelected['experiment']
                    });
                    // Sort array a based on the order specified in array b
                    current = current.sort((x, y) => idIndexMap[x.category] - idIndexMap[y.category]);
                }
                it.categoryAllCuration = [...current]
            }
        })
        setVariantGroups(prev => [...copyVariantGroups])
    }

    const removeShowsCuration = (val, currentStep, variant, shows) => {
        setStepper((prev) => [...prev,
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        copyVariantGroups.forEach((it) => {
            if(it.value === variant.value){
                let current = it.showsAllCuration
                current?.forEach((item) => {
                    if(parseInt(item.show) === parseInt(val)){
                        item?.custom_rows?.forEach((d) => {
                            d.row_series = []
                        })
                        item.is_removed = true
                        item.showsAllSeries = []
                    }
                })
                if(shows?.length > 0){
                    let idIndexMap = {};
                    shows.forEach((item, index) => {
                        idIndexMap[item.id] = index;
                    });
                    let firstIndex = 0
                    it.showsAllCuration.forEach((d) => {
                        if(d.is_removed == false && firstIndex == 0){
                            firstIndex = d.show
                        }
                    })
                    setCurrentShowSelected({
                        "control": variant.value === 'control' ? it.showsAllCuration?.length > 0 ? firstIndex : "" : currentShowSelected['control'],
                        "experiment": variant.value === 'experiment' ? it.showsAllCuration?.length > 0 ? firstIndex : "" : currentShowSelected['experiment']
                    });
                    // Sort array a based on the order specified in array b
                    current = current.sort((x, y) => idIndexMap[x.show] - idIndexMap[y.show]);
                }
                it.showsAllCuration = [...current]
            }
        })
        setVariantGroups(prev => [...copyVariantGroups])
    }

    const addCustomRow = (item, variant) => {
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        copyVariantGroups.forEach((it) => {
            if(it.value === variant.value){
                if(it?.categoryAllCuration){
                    it.categoryAllCuration.forEach((curation) => {
                        if(parseInt(curation.category) === parseInt(item.category)){
                            let custom_rows = curation.custom_rows?.length > 0 ? curation.custom_rows : []
                            custom_rows.push({
                                id: custom_rows?.length + 1,
                                title: '',
                                is_title: true,
                                is_dfs: true,
                                row_series: [{id: 1, dfs: 0, series_ids: ''}]
                            })
                            curation.custom_rows = custom_rows
                        }
                    })
                }
            }
        })
        setVariantGroups(prev => [...copyVariantGroups])
    }

    const handleAddCustomRowInShows = (item, variant) => {
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        copyVariantGroups.forEach((it) => {
            if(it.value === variant.value){
                if(it?.showsAllCuration){
                    it.showsAllCuration.forEach((curation) => {
                        if(parseInt(curation.show) === parseInt(item.show)){
                            let custom_rows = curation.custom_rows?.length > 0 ? curation.custom_rows : []
                            custom_rows.push({
                                id: custom_rows?.length + 1,
                                title: '',
                                is_title: true,
                                is_dfs: true,
                                row_series: [{id: 1, dfs: 0, series_ids: ''}]
                            })
                            curation.custom_rows = custom_rows
                        }
                    })
                }
            }
        })
        setVariantGroups(prev => [...copyVariantGroups])
    }

    const handleDragCategoryRow = (params, currentStep, variant, item) => {
        if(params && params?.source && params?.destination){
            setStepper((prev) => [...prev, 
                prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
            const srcI = params.source.index;
            const destI = params.destination.index;
            let copyVariantGroups = [...variantGroups];
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it.categoryAllCuration.forEach((curation) => {
                        if(parseInt(curation.category) === parseInt(item.category)){
                            curation['custom_rows'] = JSON.parse(JSON.stringify(curation['custom_rows']))
                            curation['custom_rows']?.splice(destI, 0, ...curation['custom_rows'].splice(srcI, 1))
                        }
                    })
                }
            })
            setVariantGroups(prev => [...copyVariantGroups])
        }
    }


    const handleDragShowsRow = (params, currentStep, variant, item) => {
        if(params && params?.source && params?.destination){
            setStepper((prev) => [...prev, 
                prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
            const srcI = params.source.index;
            const destI = params.destination.index;
            let copyVariantGroups = [...variantGroups];
            copyVariantGroups.forEach(it => {
                if(it.value === variant.value){
                    it.showsAllCuration.forEach((curation) => {
                        if(parseInt(curation.show) === parseInt(item.show)){
                            curation['custom_rows'] = JSON.parse(JSON.stringify(curation['custom_rows']))
                            curation['custom_rows']?.splice(destI, 0, ...curation['custom_rows'].splice(srcI, 1))
                        }
                    })
                }
            })
            setVariantGroups(prev => [...copyVariantGroups])
        }
    }

    const createExperiment = () => {
        setVariantModel(false);
        setSelectVariant(0);
        setCurrentCategorySelected({
            'experiment' : '',
            'control': currentCategorySelected['control']
        });
        setCurrentShowSelected({
            'experiment' : '',
            'control': currentShowSelected['control']
        });
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        copyVariantGroups.forEach((it) => {
            if(it.value === 'experiment'){
                it['categories'] = categoriesOriginal
                it['defaultTopNavbar'] = defaultTopNavbarOriginal
                it['topNavbar'] = topNavbarOriginal
                it["shows"] = showsOriginal
            }
        });
        setVariantGroups(prev => [...copyVariantGroups])
    }

    const removeCustomRow = (currentStep, custom_row, variant, item) => {
        setStepper((prev) => [...prev, 
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        copyVariantGroups.forEach((it) => {
            if(it.value === variant.value){
                if(it?.categoryAllCuration){
                    it.categoryAllCuration.forEach((curation) => {
                        if(parseInt(curation.category) === parseInt(item.category)){
                            curation.custom_rows.forEach(d => {
                                if(d.id == custom_row.id){
                                    d.entity_id= custom_row.entity_id
                                    d.row_series= [{id: 1, dfs: 0, series_ids: ''}]
                                    d.is_removed= true
                                }
                            })
                        }
                    })
                }
            }
        })
        setVariantGroups(prev => [...copyVariantGroups])
    }

    const removeCustomShowsRow = (currentStep, custom_row, variant, item) => {
        setStepper((prev) => [...prev, 
            prev[currentStep.step-1].isValid[variant.value] = false].splice(0,5));
        let copyVariantGroups = JSON.parse(JSON.stringify(variantGroups));
        copyVariantGroups.forEach((it) => {
            if(it.value === variant.value){
                if(it?.showsAllCuration){
                    it.showsAllCuration.forEach((curation) => {
                        if(parseInt(curation.show) === parseInt(item.show)){
                            curation.custom_rows.forEach(d => {
                                if(d.id == custom_row.id){
                                    d.entity_id= custom_row.entity_id
                                    d.row_series= [{id: 1, dfs: 0, series_ids: ''}]
                                    d.is_removed= true
                                }
                            })
                        }
                    })
                }
            }
        })
        setVariantGroups(prev => [...copyVariantGroups])
    }

    const renderComponents = (activeIndex, step, variant) => {
        let activeSteps = activeIndex
        let component = <></>;
        let homeTrendingSeriesIds = variant?.homeTrendingSeriesIds
        let homeTop10SeriesIds = variant?.homeTop10SeriesIds
        let categories = variant?.categories?.length > 0 ? variant?.categories : categoriesOriginal 
        let defaultTopNavbar = variant?.defaultTopNavbar
        let topNavbar = variant?.topNavbar
        let newSeriesIds = variant?.newSeriesIds
        
        let categoryAllCurations = variant?.categoryAllCuration
        let shows = variant?.shows
        let showsAllCurations = variant?.showsAllCuration;
        
        if(activeSteps === false)
            return component

        switch (activeIndex) {
            case 1:
                component = <div className="card-panel creator-table track-create-box">
                <Card
                    className="shadow-table-form mt-0 p-4"
                    style={{ border: "0.4px solid rgba(0,0,0,0.23)", width: "100%" }}
                >
                    <div>
                        <h6>Select App <RequiredStar /> </h6>
                        <div className="mt-2 w-[20%]">
                            <SelectAppPackage disabled={isEdit} selectApp={selectApp} setSelectApp={setSelectApp} />
                        </div>
                    </div>

                    <Form.Label className="mt-3">Track Title <span className="required-label">*</span></Form.Label>
                    <Form.Control
                        labelId="demo-label-track-title"
                        id="demo-track-title"
                        size="small"
                        disabled={isStepLoading || isEdit}
                        placeholder="Enter Track title"
                        value={track_title}
                        onChange={(e) => handleTrack(e, step, variant)}
                        input={<OutlinedInput label="" />}
                    >
                    </Form.Control>
                    <br/>
                    <Form.Label>Creative Uri <span className="required-label">*</span></Form.Label>
                    <div className="d-flex dfs-series-ids flex-bottom-code mb-1">
                        <div style={{flex: 6}} >
                            {creativeUri && creativeUri?.map((code,i) => <div className="d-flex dfs-series-ids flex-bottom-code">
                                <Form.Control
                                labelId={`demo-label-campaign-uri${i}`}
                                id={`demo-campaign-uri${i}`}
                                size="small"
                                key={i}
                                className="mb-1"
                                disabled={isStepLoading || isEdit}
                                placeholder="Enter creative uri"
                                value={code}
                                onChange={(e) => {
                                    creativeUri[i] = e.target.value
                                    SetCreativeUri(prev => [...creativeUri])
                                    setStepper((prev) => [...prev, 
                                        prev[step.step-1].isValid[variant.value] = false].splice(0,5));
                                    
                                }}
                                input={<OutlinedInput label="" />}
                            >
                            </Form.Control>
                            {creativeUri?.length - 1 !== i && <IconButton className="button-custom ml-2" onClick={(e) => removeCreativeUri(e, i, step, code, variant)}  disabled={isStepLoading || isEdit}><CloseOutlined /></IconButton>}
                            </div>)}
                        </div>
                        {!isEdit && <Button style={{flex: 1}} disabled={!step.showButton} onClick={handleCreativeUri} className="button-custom ml-2 mb-1">Add</Button>}
                    </div>
                    <br/>
                    <Form.Label>Show in onboarding: </Form.Label>
                    <span>
                        <Switch checked={isOnboarding} onChange={(e) => {
                            setIsOnboarding(!isOnboarding);
                            setStepper((prev) => [...prev, 
                                prev[step.step-1].isValid[variant.value] = false].splice(0,5));
                        }} disabled={isStepLoading || isEdit}/>
                    </span>

                    <Form.Label>Successful</Form.Label>
                    <span>
                        <Switch checked={isSuccessful} onChange={(e) => {
                            setIsSuccessful(!isSuccessful);
                            setStepper((prev) => [...prev, 
                                prev[step.step-1].isValid[variant.value] = false].splice(0,5));
                        }} disabled={isStepLoading || isEdit}/>
                    </span>
                    
                    {isOnboarding && <div>
                        <Form.Label>Track Onboarding series</Form.Label>
                        <Form.Control
                            size="small"
                            disabled={isStepLoading || isEdit}
                            placeholder="Enter onboarding series ids"
                            value={onboardingSeriesIds}
                            onChange={(e) => {
                                handleOnboarding(e, step, variant )
                            }}
                            input={<OutlinedInput label="" />}
                        >
                        </Form.Control>
                    </div>
                    }
                    <br/>
                    {isOnboarding && <div>
                        <Form.Label>Track Onboarding title</Form.Label>
                        <Form.Control
                            size="small"
                            disabled={isStepLoading || isEdit}
                            placeholder="Enter onboarding title"
                            value={onboardingTitle}
                            onChange={(e) => handleTrack(e, step, variant, "onboarding")}
                            input={<OutlinedInput label="" />}
                        >
                        </Form.Control>
                    </div>
                    }
                    <br/>
                    <Form.Label>Track Score: </Form.Label>
                    <div>
                        <Form.Control
                            size="small"
                            disabled={isStepLoading || isEdit}
                            placeholder="Enter Score"
                            type="number"
                            value={score}
                            onChange={(e) => {
                                if(parseInt(e.target.value) <= 0 || parseInt(e.target.value) == NaN){
                                }else{
                                    setScore(e.target.value);
                                    setStepper((prev) => [...prev, 
                                        prev[step.step-1].isValid[variant.value] = false].splice(0,5));
                                }
                            }}
                            input={<OutlinedInput label="" />}
                        >
                        </Form.Control>
                    </div>
                </Card>
                <div className="layout-scroll-button-bottom">
                    {!isEdit && <Button className="button-custom" disabled={isStepLoading} onClick={() => handleValidation(step, "track", variant)}>
                        {step.isValid[variant.value] ? "Validated" : isStepLoading ? "Loading..." : "Validate"}
                    </Button>}
                    <Button className="button-custom" disabled={isStepLoading} onClick={() => handleNextStep(step, "track")}>Next</Button>
                </div>
            </div>
            break;
            
            case 2:
                component = <div className="card-panel creator-table track-create-box">
                <Card
                    className="shadow-table-form mt-0 p-4"
                    style={{ border: "0.4px solid rgba(0,0,0,0.23)", width: "100%" }}
                >
                    <Form.Label>Home trending series Ids <span className="required-label">*</span></Form.Label>
                    <div className="d-flex dfs-series-ids flex-bottom-code mb-3">
                        <div style={{flex: 6}}>
                            {homeTrendingSeriesIds?.map((trending) => 
                            <div className="d-flex dfs-series-ids mb-1">
                                <span style={{color:"blue"}}>DFS {trending.dfs}</span>
                                <span>:</span>
                                <Form.Control
                                    required
                                    labelId={`demo-label-home-trending-${trending.dfs}`}
                                    id={`demo-home-trending-${trending.dfs}`}
                                    size="small"
                                    disabled={isStepLoading || isEdit}
                                    placeholder="Enter series ids"
                                    value={trending?.series_ids}
                                    style={{ width: "90%"}}
                                    onChange={(e) => handleChange(e, variant, step, 'home_trending', trending)}
                                    input={<OutlinedInput label="" />}
                                >
                            </Form.Control>
                            </div>
                            )}
                        </div>
                        <div style={{flex: 1, paddingBottom: 5, paddingLeft: 10}}>
                            {!isEdit && <Button className="button-custom"
                                disabled={isStepLoading} onClick={()=> handleDfsAdd(variant)}>
                                <Add /> DFS
                            </Button>}
                        </div>
                    </div>
                    <Form.Label>Home top 10 series Ids</Form.Label>
                    <div className="d-flex dfs-series-ids flex-bottom-code mb-3">
                        <div style={{flex: 6}}>
                            {homeTop10SeriesIds?.map((top10) => 
                            <div className="d-flex dfs-series-ids mb-1">
                                <span style={{color:"blue"}}>DFS {top10.dfs}</span>
                                <span>:</span>
                                <Form.Control
                                    labelId={`demo-label-home-top10-${top10.dfs}`}
                                    id={`demo-home-top10-${top10.dfs}`}
                                    size="small"
                                    disabled={isStepLoading || isEdit}
                                    placeholder="Enter series ids"
                                    value={top10?.series_ids}
                                    style={{ width: "90%"}}
                                    onChange={(e) => handleChange(e, variant, step, 'home_top10', top10)}
                                    input={<OutlinedInput label="" />}
                                >
                                </Form.Control>
                            </div>
                            )}
                        </div>
                        <div style={{flex: 1, paddingBottom: 5, paddingLeft: 10}}>
                            {!isEdit && <Button className="button-custom"
                                disabled={isStepLoading} onClick={()=> handleDfsAdd(variant, null, 'home_top10')}>
                                <Add /> DFS
                            </Button>}
                        </div>
                    </div>
                    {!isDefault && isEdit && <p className="required-label-info">*Non programmed DFS will come from default</p>}
                    <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mt-3 mb-2"
                    >
                        <div className="d-flex flex-space-between">
                            <Form.Label>Category ordering</Form.Label>
                            {/* <Button disabled={isAddCategoryDisabled(variant)} className="button-custom" onClick={() => setIsNewCategoryAdd(true)}>Create new category</Button> */}
                        </div>
                        <DragDropContext onDragEnd={(params) => handleDrag(params, step, variant)}>
                            <Droppable droppableId="droppable-1">
                                {(provided, _) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {categories && categories?.map((category, index) => {
                                            return (
                                                <Draggable
                                                    key={category.id}
                                                    draggableId={`draggable${category.id}`}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className=""
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                boxShadow: snapshot.isDragging
                                                                    ? "0 0 0.4rem #666"
                                                                    : "none",
                                                            }}
                                                        >
                                                            <div className="videos-drag-list-details videos-drag-list-details-campaign" style={{ fontSize: 12, height: isEdit && 35, padding: isEdit && 5}}>
                                                                {!isEdit && <div
                                                                    {...provided.dragHandleProps}
                                                                    className="drag-icon" style={{ fontSize: 12}}
                                                                >
                                                                    <DragIndicatorIcon />
                                                                </div>}
                                                                <div className="d-flex drag-flex-center">
                                                                <div className="video-number"> #{index + 1} {category.title}   -----  {category.slug}</div>
                                                                <div >
                                                                    {category.is_added === true && <IconButton className="button-custom ml-2"  onClick={() => removeCategory(category)}>
                                                                        <CloseOutlined />
                                                                        </IconButton>
                                                                    }
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Form.Group>
                    <Form.Group
                        controlId="exampleForm.ControlInput1"
                    >
                        <div>
                            <Form.Label>Home top-nav ordering</Form.Label>
                        </div>
                        <DragDropContext onDragEnd={(params) => handleDragTopbar(params, step, variant)}>
                            <Droppable droppableId="droppable-2">
                                {(provided, _) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {defaultTopNavbar && defaultTopNavbar?.map((category, index) => {
                                            return (
                                                <Draggable
                                                    key={category.slug}
                                                    draggableId={`draggable-${category.slug}`}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className=""
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                boxShadow: snapshot.isDragging
                                                                    ? "0 0 0.4rem #666"
                                                                    : "none",
                                                            }}
                                                        >
                                                            <div className="videos-drag-list-details videos-drag-list-details-campaign" style={{ fontSize: 12, height: isEdit && 35, padding: isEdit && 5}}>
                                                                {!isEdit && <div
                                                                    {...provided.dragHandleProps}
                                                                    className="drag-icon" style={{ fontSize: 12}}
                                                                >
                                                                    <DragIndicatorIcon />
                                                                </div>}
                                                                <div className="d-flex drag-flex-center">
                                                                    <div className="video-number"> #{index + 1} {category?.title}   ------- {">"}  {topNavbar[index]?.title}</div>
                                                                    <div>
                                                                        {!isEdit && <select className="select-option-custom mr-4 pr-2"
                                                                            value={topNavbar[index]?.slug}
                                                                            disabled={isStepLoading} 
                                                                            onChange={(e) => {
                                                                                setStepper((prev) => [...prev, 
                                                                                    prev[step.step-1].isValid[variant.value] = false].splice(0,5));
                                                                                let selected = categories?.filter((d) =>  d?.slug === e.target.value)
                                                                                variantGroups.forEach((it) => {
                                                                                    if(it.value === variant.value){
                                                                                        let copyTopNavbar = JSON.parse(JSON.stringify(topNavbar))
                                                                                        copyTopNavbar?.forEach((topNav, i) => {
                                                                                            if(index === i && selected?.length > 0){
                                                                                                topNav['slug'] = selected[0]?.slug
                                                                                                topNav['id'] = selected[0]?.id
                                                                                                topNav['title'] = selected[0]?.title
                                                                                                topNav['series_ids'] = ''
                                                                                            }
                                                                                        })
                                                                                        it['topNavbar'] = copyTopNavbar
                                                                                    }
                                                                                })
                                                                                setVariantGroups((prev) => [...variantGroups])
                                                                            }} 
                                                                            style={{ flex: 1, marginRight: 10, width: 200}}>
                                                                            <option value={""}>None</option>
                                                                            {categories?.map(({title, slug}) => (
                                                                                <option
                                                                                    key={title}
                                                                                    value={slug}
                                                                                >
                                                                                    {title}
                                                                                </option>
                                                                            ))}
                                                                        </select>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Form.Group>
                </Card>
                <div className="layout-scroll-button-bottom">
                    <Button className="button-custom-cancel" disabled={isStepLoading} onClick={() => handlePrevStep(step, 'home')}>Back</Button>
                    {!isEdit && <Button className="button-custom" disabled={isStepLoading} onClick={() => handleValidation(step, "home", variant)}>{step.isValid[variant.value] ? "Validated" : isStepLoading ? "Loading..." : "Validate"}</Button>}
                    <Button className="button-custom"  disabled={isStepLoading} onClick={() => handleNextStep(step, "home", variant)}>Next</Button>
                </div>
            </div>
            break;
            
            case 3:
                component = <div className="card-panel creator-table">
                <Card
                    className="shadow-table-form mt-0 p-4"
                    style={{ border: "0.4px solid rgba(0,0,0,0.23)", width: "100%" }}
                >
                    {!isEdit &&  <div className="d-flex" style={{ alignItems: "center", marginBottom: 40}}>
                        <Form.Label className="!mt-2">Category</Form.Label><br/>
                        <div className="ml-3">
                            <select className="select-option-custom mr-4 pr-2 border focus:outline-none"
                                value={currentCategorySelected[variant.value]}
                                disabled={isStepLoading} 
                                onChange={(e) => handleCategoryCuration(e.target.value, variant, categories)}
                                style={{ flex: 1, marginRight: 10, width: 200}}
                            >
                                <option value={""}>
                                    None
                                </option>
                                {categories?.map(({title, id}) => (
                                    <option
                                        key={title}
                                        value={id}
                                    >
                                        {title}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    {categoryAllCurations && categoryAllCurations?.filter(d => d?.is_removed == false)?.map((item, i) => 
                        <Chip
                            className="chip-for-added-category"
                            label={categories?.filter(d => d?.id == item?.category)[0]?.title}
                            deleteIcon={isEdit && <></>}
                            style={{ border: parseInt(currentCategorySelected[variant.value]) == parseInt(item?.category) && '2px solid #8277d4'}}
                            disabled={isStepLoading}
                            onClick={() => handleCategoryCuration(item.category, variant, categories)}
                            onDelete={() => !isEdit && removeCategoryCuration(item.category, step, variant, categories)}
                        />
                    )}
                    {currentCategorySelected[variant.value] !== 0 && categoryAllCurations?.filter(d => d?.is_removed == false)?.map((item, i) => {
                        return (parseInt(item?.category) === parseInt(currentCategorySelected[variant.value]) &&
                        <div className="mt-4" key={i}>
                            <DragDropContext onDragEnd={(params) => handleDragCategoryRow(params, step, variant, item)}>
                                <Droppable droppableId="droppable-1">
                                    {(provided, _) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {item?.custom_rows && 
                                            item?.custom_rows?.filter(d => d?.is_removed != true)?.map((custom_row, index) => 
                                            <Draggable
                                                key={index}
                                                index={index}
                                                draggableId={`draggable${index}`}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className=""
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            boxShadow: snapshot.isDragging
                                                                ? "0 0 0.4rem #666"
                                                                : "none",
                                                        }}
                                                    >
                                                        <div className="videos-drag-list-details videos-drag-list-details-campaign">
                                                            <div
                                                                {...provided.dragHandleProps}
                                                                className="drag-icon" style={{ fontSize: 12, width: !isEdit ? 40 : 10}}
                                                            >
                                                                {!isEdit && <DragIndicatorIcon />}
                                                            </div>
                                                            <Accordion defaultExpanded={isEdit} className="drag-list-track-custom">
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel4-content"
                                                                    id="panel4-header"
                                                                >
                                                                <Form.Label className="mt-2">{custom_row.is_title ? `Custom row title - ${custom_row.title}` : custom_row.heading }</Form.Label>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                {custom_row?.is_title && <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter custom row title"
                                                                    required
                                                                    className="mb-3"
                                                                    disabled={isStepLoading || isEdit}
                                                                    value={custom_row?.title}
                                                                    onChange={(e) => handleChangeAllSeries(e, variant, step, 'custom_series_title', item.category, index, custom_row)}
                                                                    input={<OutlinedInput label="" />}
                                                                />}
                                                                {custom_row.is_title && <Form.Label>Series Ids</Form.Label>}
                                                                <div className="d-flex dfs-series-ids flex-bottom-code mb-3">
                                                                    <div style={{flex: 6}}>
                                                                        {custom_row?.row_series?.map((custom_series) => 
                                                                            <div className="d-flex dfs-series-ids mb-1">
                                                                                <span style={{color:"blue"}}>DFS {custom_series.dfs}</span>
                                                                                <span>:</span>
                                                                                <Form.Control
                                                                                    required
                                                                                    labelId={`demo-label-cat-custom-${custom_series.dfs}`}
                                                                                    id={`demo-cat-custom-${custom_series.dfs}`}
                                                                                    size="small"
                                                                                    disabled={isStepLoading || isEdit}
                                                                                    placeholder="Enter series ids"
                                                                                    value={custom_series?.series_ids}
                                                                                    onChange={(e) => handleChangeAllSeries(e, variant, step, 'custom_series', item.category, index, custom_row, custom_series)}
                                                                                    style={{ width: "90%"}}
                                                                                    input={<OutlinedInput label="" />}
                                                                                >
                                                                             </Form.Control>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {!isEdit && <div style={{flex: 1, paddingBottom: 5, paddingLeft: 10}}>
                                                                        <Button className="button-custom"
                                                                            disabled={isStepLoading || isEdit} onClick={()=> handleDfsAdd(variant, item?.category, "custom_series", custom_row)}>
                                                                            <Add /> DFS
                                                                        </Button>
                                                                    </div>}
                                                                </div>
                                                                {(custom_row.is_title && !isEdit) && <Button className="button-custom mb-2" onClick={() => removeCustomRow(step, custom_row, variant, item)}>Remove Row</Button>}
                                                                </AccordionDetails>
                                                                {!isDefault && isEdit && <p className="required-label-info">*Non programmed DFS will come from default</p>}
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <Accordion defaultExpanded={isEdit} className="drag-custom-accordion">
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel100-content"
                                    id="panel100"
                                >
                                <Form.Label>Category all series Ids</Form.Label>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="d-flex dfs-series-ids flex-bottom-code mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter series ids for selected category"
                                            required
                                            disabled={isStepLoading || isEdit}
                                            labelId="demo-label-category-series-all"
                                            id="demo-category-series-all"
                                            value={item?.categoryAllSeries}
                                            onChange={(e) => handleChangeAllSeries(e, variant, step, 'all_series_ids', item.category)}
                                            input={<OutlinedInput label="" />}
                                        />
                                    </div>
                                </AccordionDetails>
                                {!item?.categoryAllSeries?.length > 0 && isEdit && !isDefault && <p className="required-label-info">*This section will come from default</p>}
                            </Accordion>
                            {!isEdit && <Button className="button-custom mt-3" onClick={() => addCustomRow(item, variant)}><Add /> Custom Row in {categories?.filter(d => d.id == item?.category)[0]?.title}</Button>}
                        </div>
                    )})}
                </Card>
                <div className="layout-scroll-button-bottom">
                    <Button className="button-custom-cancel" disabled={isStepLoading} onClick={() => handlePrevStep(step, 'category')}>Back</Button>
                    {!isEdit && <Button className="button-custom" disabled={isStepLoading} onClick={() => handleValidation(step, "category", variant)}>
                        {step.isValid[variant.value] ? "Validated" : isStepLoading ? "Loading..." : "Validate"}
                    </Button>}
                    <Button className="button-custom" disabled={isStepLoading} onClick={() => handleNextStep(step, "category")}>Next</Button>
                </div>
            </div>
            break;

            case 4:
                component = <div className="card-panel creator-table">
                <Card
                    className="shadow-table-form mt-0 p-4"
                    style={{ border: "0.4px solid rgba(0,0,0,0.23)", width: "100%" }}
                >
                    {!isEdit &&  <div className="d-flex" style={{ alignItems: "center", marginBottom: 40}}>
                        <Form.Label className="!mt-2">Show</Form.Label><br/>
                        <div className="ml-3">
                            <select className="select-option-custom mr-4 pr-2 border"
                                value={currentShowSelected[variant.value]}
                                disabled={isStepLoading} 
                                onChange={(e) => handleShowsCuration(e.target.value, variant, shows)}
                                style={{ flex: 1, marginRight: 10, width: 200}}
                            >
                                <option value={""}>
                                    None
                                </option>
                                {shows?.map(({title, id}) => (
                                    <option
                                        key={title}
                                        value={id}
                                    >
                                        {title}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    {showsAllCurations && showsAllCurations?.filter(d => d?.is_removed == false)?.map((item, i) => 
                        <Chip
                            className="chip-for-added-category"
                            label={shows?.filter(d => d?.id == item?.show)[0]?.title}
                            deleteIcon={isEdit && <></>}
                            style={{ border: parseInt(currentShowSelected[variant.value]) == parseInt(item?.show) && '2px solid #8277d4'}}
                            disabled={isStepLoading}
                            onClick={() => handleShowsCuration(item.show, variant, shows)}
                            onDelete={() => !isEdit && removeShowsCuration(item.show, step, variant, shows)}
                        />
                    )}
                    {currentShowSelected[variant.value] !== 0 && showsAllCurations?.filter(d => d?.is_removed == false)?.map((item, i) => {
                        return (parseInt(item?.show) === parseInt(currentShowSelected[variant.value]) &&
                        <div className="mt-4" key={i}>
                            <DragDropContext onDragEnd={(params) => handleDragShowsRow(params, step, variant, item)}>
                                <Droppable droppableId="droppable-1">
                                    {(provided, _) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {item?.custom_rows && 
                                            item?.custom_rows?.filter(d => d?.is_removed != true)?.map((custom_row, index) => 
                                            <Draggable
                                                key={index}
                                                index={index}
                                                draggableId={`draggable${index}`}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className=""
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            boxShadow: snapshot.isDragging
                                                                ? "0 0 0.4rem #666"
                                                                : "none",
                                                        }}
                                                    >
                                                        <div className="videos-drag-list-details videos-drag-list-details-campaign">
                                                            <div
                                                                {...provided.dragHandleProps}
                                                                className="drag-icon" style={{ fontSize: 12, width: !isEdit ? 40 : 10}}
                                                            >
                                                                {!isEdit && <DragIndicatorIcon />}
                                                            </div>
                                                            <Accordion defaultExpanded={isEdit} className="drag-list-track-custom">
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel4-content"
                                                                    id="panel4-header"
                                                                >
                                                                <Form.Label className="mt-2">{custom_row.is_title ? `Custom row title - ${custom_row.title}` : custom_row.heading }</Form.Label>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                {custom_row?.is_title && <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter custom row title"
                                                                    required
                                                                    className="mb-3"
                                                                    disabled={isStepLoading || isEdit}
                                                                    value={custom_row?.title}
                                                                    onChange={(e) => handleChangeAllSeries(e, variant, step, 'custom_series_title_for_shows', item.show, index, custom_row)}
                                                                    input={<OutlinedInput label="" />}
                                                                />}
                                                                {custom_row.is_title && <Form.Label>Series Ids</Form.Label>}
                                                                <div className="d-flex dfs-series-ids flex-bottom-code mb-3">
                                                                    <div style={{flex: 6}}>
                                                                        {custom_row?.row_series?.map((custom_series) => 
                                                                            <div className="d-flex dfs-series-ids mb-1">
                                                                                <span style={{color:"blue"}}>DFS {custom_series.dfs}</span>
                                                                                <span>:</span>
                                                                                <Form.Control
                                                                                    required
                                                                                    labelId={`demo-label-cat-custom-${custom_series.dfs}`}
                                                                                    id={`demo-cat-custom-${custom_series.dfs}`}
                                                                                    size="small"
                                                                                    disabled={isStepLoading || isEdit}
                                                                                    placeholder="Enter series ids"
                                                                                    value={custom_series?.series_ids}
                                                                                    onChange={(e) => handleChangeAllSeries(e, variant, step, 'custom_series_for_shows', item.show, index, custom_row, custom_series)}
                                                                                    style={{ width: "90%"}}
                                                                                    input={<OutlinedInput label="" />}
                                                                                >
                                                                             </Form.Control>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {!isEdit && <div style={{flex: 1, paddingBottom: 5, paddingLeft: 10}}>
                                                                        <Button className="button-custom"
                                                                            disabled={isStepLoading || isEdit} onClick={()=> handleAddDSFForShows(variant, item?.show, "custom_series", custom_row)}>
                                                                            <Add /> DFS
                                                                        </Button>
                                                                    </div>}
                                                                </div>
                                                                {(custom_row.is_title && !isEdit) && <Button className="button-custom mb-2" onClick={() => removeCustomShowsRow(step, custom_row, variant, item)}>Remove Row</Button>}
                                                                </AccordionDetails>
                                                                {!isDefault && isEdit && <p className="required-label-info">*Non programmed DFS will come from default</p>}
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {/* <Accordion defaultExpanded={isEdit} className="drag-custom-accordion">
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel100-content"
                                    id="panel100"
                                >
                                <Form.Label>Show all series Ids</Form.Label>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="d-flex dfs-series-ids flex-bottom-code mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter series ids for selected show"
                                            required
                                            disabled={isStepLoading || isEdit}
                                            labelId="demo-label-category-series-all"
                                            id="demo-category-series-all"
                                            value={item?.categoryAllSeries}
                                            onChange={(e) => handleChangeAllSeries(e, variant, step, 'all_series_ids_for_shows', item.show)}
                                            input={<OutlinedInput label="" />}
                                        />
                                    </div>
                                </AccordionDetails>
                                {!item?.categoryAllSeries?.length > 0 && isEdit && !isDefault && <p className="required-label-info">*This section will come from default</p>}
                            </Accordion> */}
                            {/* {!isEdit && <Button className="button-custom mt-3" onClick={() => handleAddCustomRowInShows(item, variant)}><Add /> Custom Row in {shows?.filter(d => d.id == item?.show)[0]?.title}</Button>} */}
                        </div>
                    )})}
                </Card>
                <div className="layout-scroll-button-bottom">
                    <Button className="button-custom-cancel" disabled={isStepLoading} onClick={() => handlePrevStep(step, 'show')}>Back</Button>
                    {!isEdit && <Button className="button-custom" disabled={isStepLoading} onClick={() => handleValidation(step, "show", variant)}>
                        {step.isValid[variant.value] ? "Validated" : isStepLoading ? "Loading..." : "Validate"}
                    </Button>}
                    <Button className="button-custom" disabled={isStepLoading} onClick={() => handleNextStep(step, "show")}>Next</Button>
                </div>
            </div>
            break;
            
            // case 5:
            //     component = <div className="card-panel creator-table">
            //     <Card
            //         className="shadow-table-form mt-0 p-4"
            //         style={{ border: "0.4px solid rgba(0,0,0,0.23)", width: "100%" }}
            //     >
            //         <div className="d-flex justify-center-align-center">
            //             <div style={{flex: 1}}>
            //             <Form.Label><b>Top-nav story for category</b></Form.Label><br/>
            //             </div>
            //         </div>
            //         {topNavbar?.map((item, id) => 
            //             <Accordion key={id} className="drag-custom-accordion">
            //                 <AccordionSummary
            //                     expandIcon={<ExpandMore />}
            //                     aria-controls="panel100-content"
            //                     id="panel100"
            //                 >
            //                     <Form.Label>{item.title}</Form.Label>
            //                 </AccordionSummary>
            //                 <AccordionDetails>
            //                     <div className="mt-2">
            //                         <Form.Label>Category stories series Id</Form.Label>
            //                         <div className="d-flex dfs-series-ids flex-bottom-code mb-3">
            //                             <div style={{flex: 6}}>
            //                                 {item?.row_series?.map((row_series, index) => 
            //                                     <div className="d-flex dfs-series-ids mb-1">
            //                                         <span style={{color:"blue"}}>DFS {row_series.dfs}</span>
            //                                         <span>:</span>
            //                                         <Form.Control
            //                                             required
            //                                             labelId={`demo-label-cat-custom-${row_series.dfs}`}
            //                                             id={`demo-cat-custom-${row_series.dfs}`}
            //                                             size="small"
            //                                             disabled={isStepLoading || isEdit}
            //                                             placeholder="Enter series ids"
            //                                             value={row_series?.series_ids}
            //                                             onChange={(e) =>  handleChangeAllSeries(e, variant, step, 'stories', item.slug, index, row_series)}
            //                                             style={{ width: "90%"}}
            //                                             input={<OutlinedInput label="" />}
            //                                         >
            //                                     </Form.Control>
            //                                     </div>
            //                                 )}
            //                             </div>
            //                             {!(isStepLoading || isEdit) && <div style={{flex: 1, paddingBottom: 5, paddingLeft: 10}}>
            //                                 <Button className="button-custom"
            //                                     disabled={isStepLoading} onClick={()=> handleDfsAdd(variant, item?.slug, "stories")}>
            //                                     <Add /> DFS
            //                                 </Button>
            //                             </div>}
            //                         </div>
            //                     </div>
            //                 </AccordionDetails>
            //             </Accordion>
            //         )}
            //     </Card>
            //     <div className="layout-scroll-button-bottom">
            //         <Button className="button-custom-cancel" disabled={isStepLoading}  onClick={() => handlePrevStep(step, 'stories')}>Back</Button>
            //         <Button className="button-custom" disabled={isStepLoading} onClick={() => handleValidation(step, "stories", variant)}>{step.isValid[variant.value] ? "Validated" : isStepLoading ? "Loading..." : "Validate"}</Button>
            //         <Button className="button-custom" disabled={isStepLoading} onClick={() => handleNextStep(step, "stories")}>Next</Button>
            //     </div>
            // </div>
            // break;

            case 5:
                component = <div className="card-panel creator-table">
                <Card
                    className="shadow-table-form mt-0 p-4"
                    style={{ border: "0.4px solid rgba(0,0,0,0.23)", width: "100%" }}
                >
                    <Form.Label>New tab series Ids</Form.Label>
                    <div className="d-flex dfs-series-ids flex-bottom-code mb-3">
                        <div style={{flex: 6}}>
                            {newSeriesIds?.map((new_series) => 
                            <div className="d-flex dfs-series-ids mb-1">
                                <span style={{color:"blue"}}>DFS {new_series.dfs}</span>
                                <span>:</span>
                                <Form.Control
                                    required
                                    labelId={`demo-label-new-${new_series.dfs}`}
                                    id={`demo-new-${new_series.dfs}`}
                                    size="small"
                                    disabled={isStepLoading || isEdit}
                                    placeholder="Enter series ids"
                                    value={new_series?.series_ids}
                                    style={{ width: "90%"}}
                                    onChange={(e) => handleChange(e, variant, step, 'new', new_series)}
                                    input={<OutlinedInput label="" />}
                                >
                            </Form.Control>
                            </div>
                            )}
                        </div>
                        <div style={{flex: 1, paddingBottom: 5, paddingLeft: 10}}>
                            {!isEdit && <Button className="button-custom"
                                disabled={isStepLoading} onClick={()=> handleDfsAdd(variant, null, 'new_tab')}>
                                <Add /> DFS
                            </Button>}
                        </div>
                    </div>
                    {isEdit && !isDefault && <p className="required-label-info">*This section will come from default</p>}
                </Card>
                <div className="layout-scroll-button-bottom">
                    <Button className="button-custom-cancel" disabled={isStepLoading} onClick={() => handlePrevStep(step, 'new_series')}>Back</Button>
                    {!isEdit && <Button className="button-custom" disabled={isStepLoading} onClick={() => handleValidation(step, "new_series", variant)}>
                        {step.isValid[variant.value] ? "Validated" : isStepLoading ? "Loading..." : "Validate"}
                    </Button>}
                    {!isEdit &&
                        <Button className="button-custom" disabled={isStepLoading} onClick={() => saveData(selectedVariant)}>Submit</Button>
                    }
                </div>
            </div>
            break;
            
            default:
                break;
        }
        return component
    }

    const handleUploadCSVChange = async (e) => {
        const target = e.target;
        const file = target?.files[0];
        const formData = new FormData();
        formData.append("csv_file", file);
        if (file && file?.size > 5 * 1024 * 1024) { // 5MB in bytes
            setErrorMsg({ error: "File size exceeds 5MB limit", type: "failed" });
            setIsCsvLoading(false);
            target.value = null; // Reset the value of the file input
            return;
        } else {
            setCsvFile(formData);
            setUploadCsvConfirmation(true)
        }
    };

    const handleSubmitCsvFile = async () => {
        try {
          setIsCsvLoading(true);
          const url = `${apiGateway}${EDIT_TACK_WITH_CSV}${track_id}/file-update/`;
          const response = await axiosPrivate.post(url, csvFile);
          if (response?.data) {
            setIsCsvLoading(false);
            setErrorMsg({ error: response?.data?.message, type: "success" });
            setIsEdit(true);
            get_track_by_id(
              track_id,
              defaultTopNavbarOriginal,
              defaultTopNavbarOriginal
            );
            setCsvFile(null);
            setUploadCsvConfirmation(false);
          }
        } catch (error) {
          setIsCsvLoading(false);
          setCsvFile(null);
          setUploadCsvConfirmation(false);
          setErrorMsg({
            error: error?.response?.data?.error_message || error?.message,
            type: "failed",
          });
        }
    };

    return (
      <div>
        {isCsvLoading ? 
        <div className="initial-loading-container">
            <LoadingSpinner />
        </div> : 
        <div className="experiments-list mt-3">
        <div>
          <AlertComponent
            message={errorMsg?.error}
            type={errorMsg?.type}
            setAlertNotification={() =>
              setErrorMsg({ error: "", type: errorMsg?.type })
            }
          />
        </div>
        <div className="d-flex" style={{ width: "100%" }}>
          <div style={{ flex: 1 }}></div>
          <div
            style={{ flex: 7, justifyContent: "space-between" }}
            className="d-flex"
          >
            {isVariantSelected ? (
              <Tabs value={selectedVariant} onChange={handleVariantChanges}>
                {variantGroups?.map((label, index) => (
                  <Tab
                    {...a11yProps(index)}
                    key={label.id}
                    label={label.title}
                  />
                ))}
              </Tabs>
            ) : (
              <Tabs value={selectedVariant} onChange={handleVariantChanges}>
                <Tab key={4} {...a11yProps(4)} label={"Control"} />
              </Tabs>
            )}
            {!isEdit ? (
              <div className="add_exp_upload_csv_container">
                {!isExperimentActive && (
                  <Button
                    style={{ fontSize: 12, height: 30 }}
                    onClick={() => {
                      setIsVariantSelected(!isVariantSelected);
                      if (!isVariantSelected) {
                        setVariantModel(true);
                      } else {
                        setVariantModelRevert(true);
                      }
                      setSelectVariant(0);
                    }}
                    className="button-custom ml-3 mt-2"
                  >
                    {isVariantSelected ? "Remove Experiment" : "Add Experiment"}
                  </Button>
                )}

                {/* <div style={{ position: "relative", cursor: "pointer", display:"flex", flexDirection:"column", justifyContent:"center" }}>
                  <Button
                    style={{ fontSize: 12, height: 30 }}
                    className="button-custom ml-3 mt-2"
                  >
                    Upload CSV
                  </Button>
                  <a href="https://drive.google.com/file/d/1DxlRgS2OV8KYEc-lyHjvNtQeqM31XrAg/view?usp=sharing" target="_blank" style={{textAlign:"center", marginTop:"10px", fontSize:"13px"}} rel="noreferrer">Sample CSV</a>
                  <input type="file" className="input-file-csv" onChange={handleUploadCSVChange} 
                        key={csvFile ? csvFile.get("csv_file").name : "file-input"} />
                </div> */}
              </div>
            ) : (
                <div>
                <Button
                  style={{ fontSize: 12, height: 30 }}
                  href={`#/tracks-log/${track_id}`}
                  className="button-custom ml-3 mt-2"
                >
                  View Logs
                </Button>

                {internalTeamAccess && <Button
                  style={{ fontSize: 12, height: 30 }}
                  onClick={() => {
                    setIsEdit(false);
                  }}
                  className="button-custom ml-3 mt-2"
                >
                  Edit track
                </Button>}
              </div>
            )}
          </div>
        </div>
        {variantGroups?.map((variant, index) => (
          <TabPanel value={selectedVariant} index={index}>
            <div className="stepper-form-progress">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={activeStep}
                onChange={handleChangeTabs}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderTop: 1,
                  borderColor: "divider",
                  flex: 1,
                  paddingTop: 6,
                }}
              >
                {stepper?.map((label, index) => (
                  <Tab
                    {...a11yProps(index)}
                    key={label.id}
                    label={label.title}
                  />
                ))}
              </Tabs>
              <div className="full-layout-scroll-tabs">
                {stepper?.map((label, index) => (
                  <TabPanel value={activeStep} index={index}>
                    {renderComponents(index + 1, label, variant)}
                  </TabPanel>
                ))}
                {isEdit && (
                  <div>
                    {internalTeamAccess && <div className="full-layout-scroll-tabs-action-buttons">
                    <Button
                      className="button-custom"
                      disabled={isStepLoading}
                      onClick={() => setIsStatusDialog(true)}
                    >
                      {status ? "Make InActive" : "Make Active"}
                    </Button>
                    {isExperimentActive && (
                      <Button
                        className="button-custom"
                        disabled={isStepLoading}
                        onClick={() => setIsToggleDialog(true)}
                      >
                        Toggle Experiment
                      </Button>
                    )}
                  </div>}
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        ))}
        <Modal show={isNewCategoryAdd} centered>
          <Modal.Header
            closeButton
            onHide={() => {
              setShowLoader(false);
              setIsNewCategoryAdd(false);
            }}
          >
            <div className="modal-video-title">Add new category</div>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Select from experiment category</Form.Label>
            <br />
            <select
              className="select-option-custom"
              disabled={isStepLoading}
              onChange={(e) => {
                setCategoryExperiment(e.target.value);
                setCategoryIcon("");
                setCategoryName("");
              }}
              value={categoryExperiment}
              style={{ flex: 1, marginRight: 10 }}
            >
              <option>None</option>
              {saveDefaultCategories?.map(({ title, slug }) => (
                <option key={title} value={slug}>
                  {title}
                </option>
              ))}
            </select>
            <div className="d-flex text-align-center-track w-100">OR</div>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Category title</Form.Label>
              <Form.Control
                value={categoryName}
                type="text"
                placeholder="Enter category name"
                required
                labelId="demo-label-category-name"
                id="demo-category-name"
                onChange={(e) => {
                  setCategoryName(e.target.value);
                  setCategoryExperiment("");
                }}
                name="title"
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                Category icon <span className="required-label">*</span>
              </Form.Label>
              <Form.Control
                labelId="demo-label-category-image"
                id="demo-category-image"
                type="file"
                accept=".png, .jpeg, .jpg"
                style={{ height: 40 }}
                ref={iconRef}
                onChange={(e) => {
                  setCategoryIcon(e.target.files[0]);
                  setCategoryExperiment("");
                }}
              />
            </Form.Group>
            <Button
              disabled={showLoader}
              className="button-custom mt-1"
              onClick={() => handleSaveNewCategory()}
            >
              {showLoader ? "Loading..." : "Add category"}
            </Button>
          </Modal.Body>
        </Modal>
        <Modal show={showDialog?.length > 0} centered>
          <Modal.Header>Confirm dialog</Modal.Header>
          <Modal.Body>
            <p>
              <b>Saved data will be cleared </b>
              <br />
              Want to proceed with changes ?
            </p>
            <div>
              <Button
                className="button-custom"
                onClick={() => clearDataForDialog()}
              >
                Yes
              </Button>
              <Button
                className="button-custom-cancel"
                onClick={() => setShowDialog(null)}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={variantModel} centered>
          <Modal.Header
            closeButton
            onHide={() => {
              setIsVariantSelected(!isVariantSelected);
              setVariantModel(false);
              setSelectVariant(0);
            }}
          >
            Confirm dialog
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Do you want to Copy all data from control track</b>
              <br />
            </p>
            <div>
              <Button
                className="button-custom"
                onClick={() => copyDataToExperiment()}
              >
                Yes
              </Button>
              <Button
                className="button-custom-cancel"
                onClick={() => {
                  createExperiment();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={variantModelRevert} centered>
          <Modal.Header
            closeButton
            onHide={() => {
              setIsVariantSelected(true);
              setVariantModelRevert(false);
              setSelectVariant(0);
            }}
          >
            Confirm dialog
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Experiment Data will be cleared</b>
              <br />
              Want to proceed with changes ?
            </p>
            <div>
              <Button
                className="button-custom"
                onClick={() => clearExperimentData()}
              >
               Yes
              </Button>
              <Button
                className="button-custom-cancel"
                onClick={() => {
                  setVariantModelRevert(false);
                  setIsVariantSelected(true);
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={isStatusDialog} centered>
          <Modal.Header
            closeButton
            onHide={() => {
              setIsStatusDialog(false);
            }}
          >
            Track - {track_title}
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Update track status to {status ? "InActive" : "Active"}</b>
              <br />
              Want to proceed with changes?
            </p>
            <div>
              <Button
                className="button-custom"
                disabled={isStepLoading}
                onClick={() => {
                  toggleStatus(track_id, status);
                }}
              >
                {isStepLoading ? "Loading..." : status ? "InActive" : "Active"}
              </Button>
              <Button
                className="button-custom-cancel"
                onClick={() => {
                  setIsStatusDialog(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={isToggleDialog} centered>
          <Modal.Header
            closeButton
            onHide={() => {
              setIsToggleDialog(false);
            }}
          >
            Track - {track_title}
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Track experiment status update</b>
              <br />
              Want to proceed with changes?
            </p>
            <div>
              <Button
                className="button-custom"
                disabled={isStepLoading}
                onClick={() => {
                  closeExperiment();
                }}
              >
                {isStepLoading ? "Loading..." : "Stop A/B"}
              </Button>
              <Button
                className="button-custom"
                disabled={isStepLoading}
                onClick={() => {
                  makeDefault();
                }}
              >
                {isStepLoading ? "Loading..." : "Promote Experiment"}
              </Button>
              <Button
                className="button-custom-cancel"
                onClick={() => {
                  setIsToggleDialog(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <CustomModal
            show={uploadCsvConfirmation}
            onHide={() => {
                setUploadCsvConfirmation(false);
                setCsvFile(null)
            }}
            title="Upload CSV File"
            handleConfirmation={handleSubmitCsvFile}
            handleConfirmationBtnText="Yes"
        >
            {csvFile?.get("csv_file")?.name}
            <div>
                Are you sure you want to upload the file?
            </div>
        </CustomModal>
      </div>}
      </div>
    );
}