import events from "../events";

const handleCMSOpen = ({ userId, userName, screen }) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    const eventParams = {
      user_id: String(userId),
      user_name: userName,
      screen,
      date_time: new Date(),
    };
    window.dataLayer.push({ event: events.CMS_OPEN, ...eventParams });
  }
};

const handleClickedOnGraphEvent = ({ userId, userName }) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    const eventParams = {
      user_id: String(userId),
      user_name: userName,
      date_time: new Date(),
    };
    window.dataLayer.push({ event: events.CLICKED_ON_GRAPH, ...eventParams });
  }
};

const handleAlertTriggeredEvent = ({ alertMessage, userData }) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    const eventParams = {
      user_id: String(userData?.id),
      user_name: userData?.name,
      date_time: new Date(),
      error_message: alertMessage,
      screen: window.location.href,
    };
    window.dataLayer.push({ event: events.ALERT_TRIGGERED, ...eventParams });
  }
};

const handleDesignerAnalyticsEvent = ({ userData, videoTitle }) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    const eventParams = {
      user_id: String(userData?.id),
      user_name: userData?.name,
      date_time: new Date(),
      video_title: videoTitle,
    };
    window.dataLayer.push({
      event: events.DESIGNER_ANALYTICS,
      ...eventParams,
    });
  }
};

const handleVideoApprovedOrRejectedEvent = ({
  userId,
  userName,
  videoTitle,
  status,
}) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    const eventParams = {
      user_id: String(userId),
      user_name: userName,
      video_title: videoTitle,
      date_time: new Date(),
      status,
    };
    window.dataLayer.push({
      event: events.VIDEO_APPROVED_OR_REJECTED,
      ...eventParams,
    });
  }
};
export {
  handleCMSOpen,
  handleClickedOnGraphEvent,
  handleAlertTriggeredEvent,
  handleDesignerAnalyticsEvent,
  handleVideoApprovedOrRejectedEvent,
};
