import React from "react";

const ShowsIcon = ({ className }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M15.3333 10.7804L20.3922 8.25149C20.5616 8.16687 20.7498 8.12692 20.9389 8.13543C21.128 8.14395 21.3118 8.20066 21.4729 8.30017C21.634 8.39968 21.7669 8.53869 21.8592 8.70402C21.9514 8.86934 21.9999 9.0555 22 9.24483V16.7604C21.9999 16.9497 21.9514 17.1359 21.8592 17.3012C21.7669 17.4665 21.634 17.6055 21.4729 17.705C21.3118 17.8046 21.128 17.8613 20.9389 17.8698C20.7498 17.8783 20.5616 17.8383 20.3922 17.7537L15.3333 15.2248V10.7804ZM2 8.55816C2 7.96879 2.23413 7.40356 2.65087 6.98681C3.06762 6.57006 3.63285 6.33594 4.22222 6.33594H13.1111C13.7005 6.33594 14.2657 6.57006 14.6825 6.98681C15.0992 7.40356 15.3333 7.96879 15.3333 8.55816V17.447C15.3333 18.0364 15.0992 18.6016 14.6825 19.0184C14.2657 19.4351 13.7005 19.6693 13.1111 19.6693H4.22222C3.63285 19.6693 3.06762 19.4351 2.65087 19.0184C2.23413 18.6016 2 18.0364 2 17.447V8.55816Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ShowsIcon;
