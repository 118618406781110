import React from "react";

const LoadingSpinner = ({ className }) => {
  return (
    <div
      className={`spinner-border text-primary ${className || ""}`}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;
