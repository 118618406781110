const titleState = {
  title: "",
  id: "",
  thumbnail: "",
  titleIcon: "",
};

const showDetailsState = {
  id: "",
  slug: "",
  categoryId: "",
  categorySlug: "",
  creator: "",
};

const videoDataState = {
  videoFile: null,
  videoDuration: null,
  videoThumbnail: null,
};

export { titleState, showDetailsState, videoDataState };
