import React from "react";
import { useLocation } from "react-router-dom"; // If using React Router
import Navbar from "./Navbar";
import SideNavigation from "../SideNavigation/side-navigation.component";
import useZustandStore from "../../zustandStore/useZustandStore";
import SessionExpiredModal from "./SessionExpiredModal";
import StagingAlert from "./StagingAlert";

const DashboardLayout = ({ children, currentUser }) => {
  const { showSideNavigationBar, openSessionExpiredModal } = useZustandStore();
  const isUserLoggedIn = currentUser?.phone;
  const isStaging = window.location.href?.includes("staging.seekhoapp.com");
  const location = useLocation(); // Get current route
  const isOnPerformanceMarketingDashboard = location.pathname.includes(
    "performance-marketing-deep-div"
  );
  const isOnInvoicePage = location.pathname.includes("generate-invoice");
  const hideSideNavIf = isOnPerformanceMarketingDashboard || isOnInvoicePage;

  return (
    <div>
      {/* Session Expired Modal */}
      {openSessionExpiredModal && <SessionExpiredModal />}
      {isUserLoggedIn && <Navbar currentUser={currentUser} />}
      <div className={` flex `}>
        {/* Temp logic, will be removed when Performance marketing dashboard gets removed */}
        {isUserLoggedIn && !hideSideNavIf ? (
          <div
            className={`hidden !bg-white h-screen pt-1 lg:block transition-all duration-300 ease-in-out transform ${
              showSideNavigationBar
                ? "w-[14%] opacity-100 translate-x-0"
                : "-translate-x-[2%]"
            }`}
          >
            <SideNavigation currentUser={currentUser} />
          </div>
        ) : null}

        <div
          className={` pb-20 max-h-screen !overflow-y-auto lg:!w-full ${
            isUserLoggedIn ? "lg:px-4 w-[95%] mx-auto " : "w-full"
          }`}
          id="ThinGrayScrollBar"
        >
          <div>
            {isStaging && <StagingAlert />}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
