import React from "react";
import { Close } from "@mui/icons-material";
import InputTitleWithRequired from "../Common/InputTitleWithRequired";
import SelectAppPackage from "../Common/SelectAppPackage";
import CustomButton from "../Common/CustomButton";

const CreateWhatsAppGroupModal = ({
  selectApp,
  phoneNumber,
  listOfNumbers,
  setSelectApp,
  setPhoneNumber,
  handleAddPhoneNumber,
  handleRemovePhoneNumber,
}) => {
  return (
    <div>
      <div className="mt-2">
        <InputTitleWithRequired title="Select App" className="mb-1" />
        <SelectAppPackage selectApp={selectApp} setSelectApp={setSelectApp} />
      </div>
      <div className="mt-4 mb-1">
        <InputTitleWithRequired title="Phone Number" className="mb-1" />
        <div className="flex items-center gap-2">
          <div className="phonecode !border border-gray-300 ">
            <div className="text-[14px]">+91</div>
            <input
              autoFocus
              type="tel"
              name="mobile"
              minLength="10"
              maxLength="10"
              value={phoneNumber}
              placeholder="Invite group members"
              className="mobile-input rounded-sm placeholder:!text-[13px] text-[14px]"
              onChange={(e) => setPhoneNumber(e.target.value)}
              onKeyUpCapture={(e) =>
                (e.target.value = e.target.value.replace(/[^\d]/, ""))
              }
            />
          </div>
          <CustomButton
            children="Add"
            onClick={handleAddPhoneNumber}
            className="!h-[40px] px-3"
          />
        </div>

        {/* List of added numbers */}
        <div className="flex flex-wrap gap-2 mt-3">
          {listOfNumbers?.map((number, index) => (
            <div
              key={index}
              className="flex gap-x-1 items-center bg-baseFillColor px-2 py-1 rounded-full  border-baseBlueColor"
            >
              <div className="text-sm">{number}</div>
              <Close
                className="!text-[17px] cursor-pointer border bg-baseBlueColor p-[2px] rounded-full text-white"
                onClick={() => handleRemovePhoneNumber(index)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateWhatsAppGroupModal;
