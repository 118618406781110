import React from "react";
import CustomModal from "../Common/CustomModal";
import { Warning } from "@mui/icons-material";

const UploadWarningModal = ({ openModal, handleClose }) => {
  return (
    <CustomModal
      title={
        <div className="flex items-center gap-x-2">
          <h6>Warning</h6>
          <Warning className="text-red-500" />
        </div>
      }
      show={openModal}
      isButtonsRequired={false}
      onHide={handleClose}
    >
      <div className="py-2">Delete the previous video to upload a new one.</div>
    </CustomModal>
  );
};

export default UploadWarningModal;
