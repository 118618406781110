import React from "react";

const DashboardIcon = ({ className }) => {
  return (
    <div>
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M7 21V11H3C2.46957 11 1.96086 11.2341 1.58579 11.6509C1.21071 12.0676 1 12.6329 1 13.2222V18.7778C1 19.3671 1.21071 19.9324 1.58579 20.3491C1.96086 20.7659 2.46957 21 3 21H7ZM7 21H13M7 21V7.66667C7 7.0773 7.21071 6.51207 7.58579 6.09532C7.96086 5.67857 8.46957 5.44444 9 5.44444H13V21M13 21H17C17.5304 21 18.0391 20.7659 18.4142 20.3491C18.7893 19.9324 19 19.3671 19 18.7778V3.22222C19 2.63285 18.7893 2.06762 18.4142 1.65087C18.0391 1.23413 17.5304 1 17 1H15C14.4696 1 13.9609 1.23413 13.5858 1.65087C13.2107 2.06762 13 2.63285 13 3.22222V21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default DashboardIcon;
