const handleCreatorsTableHeading = ({ hasAccessToActions }) => {
  const creatorsTableHeading = [
    {
      title: "Name",
      display: true,
    },
    {
      title: "Show",
      display: true,
    },

    {
      title: "Onboarded On",
      display: true,
    },

    {
      title: "Onboarded by",
      display: true,
    },

    {
      title: "Content Manager",
      display: true,
    },
    {
      title: "Total Videos",
      display: true,
    },
    {
      title: "Status",
      display: true,
    },
    {
      title: "Actions",
      display: hasAccessToActions,
    },
  ];
  return creatorsTableHeading;
};

// Function to handle missing fields
const handleAddCreatorMissingFields = ({ creatorDetails }) => {
  const missingFields = [];

  if (!creatorDetails?.name) missingFields.push("Name");
  if (!creatorDetails?.mobileNumber) {
    missingFields.push("Mobile Number");
  } else if (creatorDetails?.mobileNumber?.length !== 10) {
    missingFields.push("Mobile Number is not valid");
  }
  if (!creatorDetails?.language?.value) missingFields.push("Language");
  return missingFields;
};

const detailsDefaultState = ({
  defaultLangValue = "",
  defaultLangTitle = "",
}) => {
  return {
    name: "",
    mobileNumber: "",
    language: { value: defaultLangValue, title: defaultLangTitle },
  };
};

const creatorsTabs = [
  {
    id: "ALL",
    key: "ALL",
    value: "all",
    index: 0,
    show: true,
  },
  {
    id: "INVITED",
    key: "INVITED",
    value: "invited",
    index: 1,
    show: true,
  },
  {
    id: "UNDER REVIEW",
    keys: "UNDER REVIEW",
    value: "under_review",
    index: 2,
    show: true,
  },
  {
    id: "ACTIVE",
    key: "ACTIVE",
    value: "active",
    index: 3,
    show: true,
  },
  {
    id: "INACTIVE",
    key: "INACTIVE",
    value: "inactive",
    index: 4,
    show: true,
  },
];

const payoutTypeList = [
  {
    title: "Select Payout Type",
    value: "",
    disable: true,
  },
  {
    title: "Fixed",
    value: "fixed",
  },
  {
    title: "Incentive",
    value: "incentive",
  },
];

const tdsList = [
  {
    title: "Select TDS Rate",
    value: "",
    disabled: true,
  },
  {
    title: "10% (194 J)",
    value: "10% (194 J)",
  },
  {
    title: "2% (194 J)",
    value: "2% (194 J)",
  },
  {
    title: "1% (194 C)",
    value: "1% (194 C)",
  },
  {
    title: "2% (194 C)",
    value: "2% (194 C)",
  },
];

const creatorTypeList = [
  {
    title: "Select Creator Type",
    value: "",
    disable: true,
  },
  {
    title: "Individual",
    value: "individual",
  },
  {
    title: "Agency",
    value: "agency",
  },
];

const incentiveDetailsState = {
  creatorName: "",
  creatorId: "",
  payoutType: "",
  amount: null,
  creatorType: "",
  contentManagerId: "",
  tdsRate: "",
  agreementDate: "",
};

const incentiveMissingFieldsState = {
  creatorId: false,
  payoutType: false,
  amount: false,
  creatorType: false,
  contentManagerId: false,
  tdsRate: false,
  agreementDate: false,
};

const handleCreatorStatus = ({ status }) => {
  switch (true) {
    case status === "active":
      return `text-green-500 bg-green-100`;

    case status === "inactive":
      return `text-black bg-gray-100`;

    case status === "under_review":
      return `text-orange-700 bg-yellow-50`;

    case status === "invited":
      return `text-blue-500 bg-blue-100`;

    default:
      return `text-black bg-white`;
  }
};

const confirmationState = {
  open: false,
  isLoading: false,
  creatorName: "",
  creatorId: "",
  type: "",
};

export {
  detailsDefaultState,
  creatorsTabs,
  tdsList,
  incentiveDetailsState,
  payoutTypeList,
  incentiveMissingFieldsState,
  creatorTypeList,
  confirmationState,
  handleCreatorsTableHeading,
  handleAddCreatorMissingFields,
  handleCreatorStatus,
};
