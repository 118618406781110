import React from "react";
import { useSelector } from "react-redux";
import { selectConfigFilters } from "../../redux/config/config.selector";
import { MenuItem, Select } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
} from "../../utils/utils";

const SelectAppPackage = ({ selectApp, setSelectApp, disabled = false }) => {
  const configFilters = useSelector(selectConfigFilters);
  const appNames = configFilters?.app_names;
  const history = useHistory();

  const handleAppChange = (e) => {
    const { value } = e.target;
    setSelectApp(value);
  };

  return (
    <div>
      <Select
        displayEmpty
        className="w-full !text-[14px] h-9"
        value={selectApp}
        onChange={handleAppChange}
        disabled={disabled}
      >
        <MenuItem
          className="!text-[14px] !font-semibold "
          value=""
          disabled={!selectApp}
          onClick={() => {
            handleDeleteQueryFromUrl({ history, queryNames: ["app"] });
          }}
        >
          {selectApp ? "Clear" : "Select App"}
        </MenuItem>
        {appNames?.map((item) => (
          <MenuItem
            key={item?.title}
            value={item?.value}
            onClick={() => {
              ["page", "app"].forEach((ele, index) => {
                handleAppendQueryToUrl({
                  history,
                  queryName: ele,
                  queryValue: index === 0 ? 1 : item?.value,
                });
              });
            }}
          >
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectAppPackage;
