import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { errorState } from "../../utils/constants";
import {
  handleClearSearchInput,
  handleCommonApplyFilters,
  handleSearchOnEnter,
} from "../../utils/utils";
import TabsSection from "./TabsSection";
import WaitingAndDesignApproval from "./WaitingAndDesignApproval";
import AlertComponent from "../Alert-Messages/alert-component.component";
import AllShowTopics from "./AllShowTopics";
import CustomSearch from "../Common/CustomSearch";
import FilterButton from "../Common/FilterButton";
import FilterDrawer from "../ShowsList/FilterDrawer";
import useAllQueryParams from "../../hooks/useAllQueryParams";
import FilterQueryChips from "../Common/FilterQueryChips";
import useZustandStore from "../../zustandStore/useZustandStore";
import useUserRoles from "../../hooks/useUserRoles";

const Topics = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [message, setMessage] = useState(errorState);
  const [selectedFilters, setSelectedFilters] = useState({
    category: [],
    manager: [],
  });
  const { isDesigner } = useUserRoles();
  const allQueryParams = useAllQueryParams({
    excludedParams: ["page", "state", "order", "s"],
  });
  const location = useLocation();
  const history = useHistory();
  const setToastMessage = useZustandStore((val) => val.setToastMessage);
  const searchParams = new URLSearchParams(location?.search);
  const searchParamSortingKey = searchParams?.get("s") || "";
  const searchParamSortBy = searchParams?.get("order") || "";
  const searchParamCategoryFilter = searchParams?.getAll("category") || [];
  const searchParamManagerFilter = searchParams?.getAll("manager") || [];
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;

  // On hit enter
  const handleInputKey = (ele) => {
    handleSearchOnEnter({ ele, searchQuery, history, setToastMessage });
  };

  // When clicked on Filter button
  const handleOnClickOfFilterButton = () => {
    setOpenFilterDrawer((prev) => !prev);
    setSelectedFilters({
      category: [...searchParamCategoryFilter],
      manager: [...searchParamManagerFilter],
    });
  };

  const handleRenderTabContent = () => {
    switch (searchParamTabState) {
      case 1:
      case 2:
        return (
          <WaitingAndDesignApproval
            searchParamSortBy={searchParamSortBy}
            searchParamSortingKey={searchParamSortingKey}
            searchParamCategoryFilter={searchParamCategoryFilter}
            searchParamManagerFilter={searchParamManagerFilter}
            setMessage={setMessage}
          />
        );
      default:
        return isDesigner ? (
          <WaitingAndDesignApproval
            searchParamSortBy={searchParamSortBy}
            searchParamSortingKey={searchParamSortingKey}
            searchParamCategoryFilter={searchParamCategoryFilter}
            searchParamManagerFilter={searchParamManagerFilter}
            setMessage={setMessage}
          />
        ) : (
          <div>
            <AllShowTopics
              searchParamSortBy={searchParamSortBy}
              searchParamSortingKey={searchParamSortingKey}
              searchParamCategoryFilter={searchParamCategoryFilter}
              searchParamManagerFilter={searchParamManagerFilter}
              setMessage={setMessage}
            />
          </div>
        );
    }
  };

  return (
    <div>
      <div className="flex gap-x-4">
        <CustomSearch
          searchQuery={searchQuery}
          placeHolder="Search show name or id"
          wrapperClass="w-[40%]"
          setSearchQuery={setSearchQuery}
          handleInputKey={handleInputKey}
          handleClearInput={() =>
            handleClearSearchInput({ history, setSearchQuery })
          }
        />
        <FilterButton onClick={handleOnClickOfFilterButton} />
      </div>

      <div className="flex gap-x-2 items-center mt-2">
        <h6 className="!text-[20px] font-medium">Topics</h6>
        <FilterQueryChips
          allQueryParams={allQueryParams}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <div className="mt-2">
        <TabsSection searchParamTabState={searchParamTabState} />
      </div>

      <div>{handleRenderTabContent()}</div>

      {/* Filter Drawer */}
      {openFilterDrawer && (
        <FilterDrawer
          openFilterDrawer={openFilterDrawer}
          selectedFilters={selectedFilters}
          showContentManagerFilter={true}
          setOpenFilterDrawer={setOpenFilterDrawer}
          setSelectedFilters={setSelectedFilters}
          handleApplyFilters={() => {
            handleCommonApplyFilters({
              history,
              selectedFilters,
              setOpenFilterDrawer,
            });
          }}
        />
      )}

      {/* Alert Toaster */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default Topics;
