import React from "react";
import RequiredStar from "../RequiredStar";
import { CircularProgress } from "@mui/material";

const AllVideoChecks = ({
  isLoading,
  checksState,
  setChecksState,
  handleApproveChecks,
}) => {
  const checksFields = [
    {
      id: 1,
      title: "Video Check",
      isRequired: true,
      value: checksState?.videoCheck,
      onChange: (e) =>
        setChecksState({ ...checksState, videoCheck: e.target.checked }),
    },
    {
      id: 2,
      title: "Audio Check",
      isRequired: true,
      value: checksState?.audioCheck,
      onChange: (e) =>
        setChecksState({ ...checksState, audioCheck: e.target.checked }),
    },
    {
      id: 3,
      title: "Subtitle Check",
      isRequired: true,
      value: checksState?.subtitleCheck,
      onChange: (e) =>
        setChecksState({ ...checksState, subtitleCheck: e.target.checked }),
    },
    {
      id: 4,
      title: "Seekho T-shirt Check",
      isRequired: false,
      value: checksState?.seekhoTShirtCheck,
      onChange: (e) =>
        setChecksState({ ...checksState, seekhoTShirtCheck: e.target.checked }),
    },
  ];

  return (
    <div>
      {checksFields?.map((item) => {
        return (
          <div key={item?.id} className="flex gap-x-2 first:!mt-0 mt-3">
            <input
              type="checkbox"
              value={item?.value}
              checked={item?.value}
              onChange={item?.onChange}
            />
            <h6 className="text-[14px]">
              {item?.title} {item?.isRequired && <RequiredStar />}
            </h6>
          </div>
        );
      })}
      <button
        className="px-2 py-1.5 text-[12px] rounded-md bg-baseBlueColor text-white mt-3 w-[120px]"
        onClick={handleApproveChecks}
      >
        {isLoading ? (
          <CircularProgress className="text-white mt-1" size={10} />
        ) : (
          "Approve Checks"
        )}
      </button>
    </div>
  );
};

export default AllVideoChecks;
